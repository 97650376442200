import React, { useState, useRef, useEffect } from 'react';
import Lightbox from 'react-image-lightbox';
import openAttachment, { getAttachmentUrl } from '../../openAttachment';
import uploadAttachments from '../../uploadAttachments';
import { Dropdown, Option } from '../Dropdown';
import { ItemFieldWrapper, FIELD_ICONS } from './ItemFields';

import 'react-image-lightbox/style.css';

const IMAGE_REGEX = /\.(gif|jpe?g|tiff?|png|bmp)$/i;

function truncateFileName(name) {
  if (name.length <= 10) {
    return name;
  }
  const extension = name.substring(name.lastIndexOf('.') + 1, name.length);
  let newName = name.replace('.' + extension, '');
  newName = newName.substring(0, 8) + (name.length > 8 ? '…' : '');
  return newName + extension;
}

export const ItemFieldAttachments = ({
  value,
  formData,
  onClose,
  onChange,
}) => {
  const [dropdownOpen, setDropdownOpen] = useState(false);
  const [progress, setProgress] = useState({ loaded: 1, total: 1 });
  const ref = useRef(null);
  const hasAttachments = value && !!value.length;
  return (
    <ItemFieldWrapper
      className="ItemFieldAttachments"
      icon={
        progress.loaded === progress.total
          ? FIELD_ICONS.attachments
          : 'loader-circle'
      }
      onClose={onClose}
    >
      {/* If there are no attachments, render the label/input as the form field */}
      {!hasAttachments && (
        <label className="attachment-field">
          Attachments
          <input
            type="file"
            onChange={async evt => {
              const newAttachments = await uploadAttachments(
                evt.target.files,
                setProgress,
              );
              onChange(newAttachments);
            }}
            multiple
            value=""
          />
        </label>
      )}

      {/* If there are already attachments, render a dropdown trigger as the form field */}
      {hasAttachments && (
        <div ref={ref}>
          <div
            className="attachment-field"
            onClick={() => setDropdownOpen(!dropdownOpen)}
          >
            {`${value.length} Attachment${value.length > 1 ? 's' : ''}`}
          </div>
          <Dropdown
            isOpen={dropdownOpen}
            top={48}
            close={() => setDropdownOpen(false)}
            wrapperRef={ref}
          >
            <Option
              key={'add'}
              icon={<box-icon name="plus" />}
              onClick={() => {
                /* handled by input onChange below */
              }}
            >
              <label>
                Add attachment
                <input
                  type="file"
                  onChange={async evt => {
                    setDropdownOpen(false);
                    const newAttachments = await uploadAttachments(
                      evt.target.files,
                      setProgress,
                    );
                    onChange([...value, ...newAttachments]);
                  }}
                  multiple
                  value=""
                />
              </label>
            </Option>

            {/* Iterate through attachments and make an option for each one */}
            {value.map(({ name, id }) => (
              <Option
                key={id}
                icon={<box-icon type="solid" name="file" />}
                onClick={() => {
                  setDropdownOpen(false);
                  openAttachment(formData, id);
                }}
              >
                <span className="filename">{name}</span>
                <box-icon
                  name="x"
                  onClick={async evt => {
                    evt.stopPropagation();
                    if (
                      window.confirm(
                        `Are you sure you want to delete "${name}"?`,
                      )
                    ) {
                      onChange(value.filter(att => att.id !== id));
                      setDropdownOpen(false);
                    }
                  }}
                />
              </Option>
            ))}
          </Dropdown>
        </div>
      )}
    </ItemFieldWrapper>
  );
};

export const ItemFieldAttachmentPills = ({ value, onChange, formData }) => {
  const [previewUrls, setPreviewUrls] = useState({});
  const [lightboxUrl, setLightboxUrl] = useState();

  useEffect(() => {
    if (value) {
      value.forEach(({ name, id }) => {
        if (IMAGE_REGEX.test(name)) {
          getAttachmentUrl(formData, id).then(url =>
            setPreviewUrls(prevPreviewUrls => ({
              ...prevPreviewUrls,
              [id]: url,
            })),
          );
        }
      });
    }
  }, [value, formData]);

  if (!value || !value.length) return null;

  return (
    <div className="ItemFieldAttachmentPills">
      {value.map(({ name, id }) => {
        return (
          <ItemFieldWrapper
            key={id}
            onClose={() => {
              if (
                window.confirm(`Are you sure you want to delete "${name}"?`)
              ) {
                onChange(value.filter(att => att.id !== id));
              }
            }}
          >
            <div
              className="attachment-pill"
              onClick={() => {
                if (previewUrls[id]) {
                  setLightboxUrl(previewUrls[id]);
                } else {
                  openAttachment(formData, id);
                }
              }}
            >
              {previewUrls[id] ? (
                <img src={previewUrls[id]} alt="attachment preview" />
              ) : (
                <box-icon name="file" />
              )}
              {truncateFileName(name)}
            </div>
          </ItemFieldWrapper>
        );
      })}
      {lightboxUrl && (
        <Lightbox
          mainSrc={lightboxUrl}
          onCloseRequest={() => setLightboxUrl(undefined)}
        />
      )}
    </div>
  );
};
