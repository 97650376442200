import { tldExists } from 'tldjs';

export default function getLinks(text) {
  const regex = /((http|ftp|https|HTTP|FTP|HTTPS):\/\/)?([\w_+-]+(?:(?:[.@][\w_-]+)+))([\w.,@?^=%&:/~+#-]*[\w@?^=%&/~+#-])?/g;
  const matches = (text && text.match(regex)) || [];
  const links = [...new Set(matches)].filter(link => tldExists(link));

  // If no protocol is provided assume http.
  // If a site is secure it should redirect to https
  const fullLinks = links.map(link =>
    !link.includes('://') && !link.includes('@') ? `http://${link}` : link,
  );

  // Try for phone numbers too
  const phoneRegex = /(\+\d{1,2}\s)?\(?\d{3}\)?[\s.-]\d{3}[\s.-]\d{4}/g;
  const phoneMatches = (text && text.match(phoneRegex)) || [];
  const phoneLinks = [
    ...new Set(phoneMatches.map(tel => `tel:${tel.replace(/ /, '-')}`)),
  ];

  return [...fullLinks, ...phoneLinks];
}
