import { FIELD_TYPE_PRESETS } from '../components/ItemForm/ItemFields';

export default function (form, type) {
  const errors = [];

  if (!form.title) {
    errors.push('Please enter a title');
  }
  if (form.time > form.end_time) {
    errors.push('End time must be after start time');
  }

  // Must have date if time but not if we have children
  if (
    form.time &&
    !form.date &&
    (!form.linked_items || !form.linked_items.length)
  ) {
    errors.push('Date is required for a time');
  }

  const mandatory = Object.keys(FIELD_TYPE_PRESETS[type].visible);

  mandatory.forEach(field => {
    if (!form[field]) {
      errors.push(`Please enter a ${field}`);
    }
  });

  return {
    result: !errors.length,
    errors,
  };
}
