import React from 'react';
import { CardBody } from '../components/Card';
import { H3 } from '../components/Type';
import './Dash2EmptyState.scss';

export const Dash2EmptyState = () => (
  <CardBody border="both">
    <div className="Dash2EmptyState">
      <H3>
        <span role="img" aria-label="dancer">
          💃🏽
        </span>{' '}
        Nothing scheduled!
      </H3>
    </div>
  </CardBody>
);
