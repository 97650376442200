import React, { useState } from 'react';
import { connect } from 'react-redux';
import { callApi } from '../../utils/callApi';
import {
  validateEmail,
  formatPhone,
  validatePhone,
} from '../../shared_modules/contactUtils';
import { addUser } from '../../redux/auth';
import { Card, CardBody } from '../Home/components/Card';
import { Button } from '../Home/components/Button';
import { ItemFieldWrapper } from '../Home/components/ItemForm/ItemFields';
import { Level } from '../Home/components/Level';

import './AddCollaboratorForm.scss';

const AddCollaboratorForm = props => {
  const [form, setForm] = useState({});
  const [errors, setErrors] = useState([]);

  async function add() {
    const body = { ...form };
    const err = [];
    if (body.name) {
      if (body.phone) {
        body.phone = formatPhone(body.phone);
      }

      if (body.email && !validateEmail(body.email)) {
        err.push('Invalid email address.');
      }

      if (body.phone && !validatePhone(body.phone)) {
        err.push('Invalid phone number.');
      }

      setErrors(err);

      if (err.length) return;

      try {
        const newUser = await callApi('PUT', 'users', undefined, body);
        props.addUser(newUser);
        setForm({});
        props.afterSubmit();
      } catch (error) {
        if (error.response && error.response.status === 409) {
          setErrors(['Email address already in use.']);
        } else {
          console.log(error);
        }
      }
    }
  }

  return (
    <Card className="AddCollaboratorForm">
      {!props.hideTitle && (
        <CardBody>
          <h2>Add Partner or Caregiver</h2>
        </CardBody>
      )}

      <CardBody border={!props.hideTitle && 'top'}>
        We’ll send your collaborators an email to invite them to join your
        family’s Milo account.
      </CardBody>

      <CardBody border="top">
        <ItemFieldWrapper>
          <input
            value={form.name || ''}
            onChange={evt => setForm({ ...form, name: evt.target.value })}
            type="text"
            placeholder="First name (required)"
          />
        </ItemFieldWrapper>

        <ItemFieldWrapper>
          <input
            value={form.email || ''}
            onChange={evt => setForm({ ...form, email: evt.target.value })}
            type="text"
            placeholder="Email (required)"
          />
        </ItemFieldWrapper>

        <ItemFieldWrapper>
          <input
            value={form.phone || ''}
            onChange={evt => setForm({ ...form, phone: evt.target.value })}
            type="text"
            placeholder="Phone"
          />
        </ItemFieldWrapper>
      </CardBody>

      {!!errors.length && (
        <CardBody border="top">
          {errors.map(error => (
            <div key={error} className="error">
              {error}
            </div>
          ))}
        </CardBody>
      )}
      <CardBody border="top">
        <Level>
          <div />
          <Button onClick={add} disabled={!(form.name && form.email)}>
            <box-icon name="user-plus" style={{ marginRight: 4 }} />
            Add
          </Button>
        </Level>
      </CardBody>

      <CardBody border="top">
        What they’ll get:
        <ul style={{ marginBottom: 0 }}>
          <li>Daily rundown email</li>
          <li>Calendar invites for assigned events</li>
          <li>Full account access</li>
          <li>Reminder text messages</li>
        </ul>
      </CardBody>
    </Card>
  );
};

export default connect(undefined, { addUser })(AddCollaboratorForm);
