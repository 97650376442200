import axios from 'axios';
import * as analytics from './analytics';
import { getAccessToken } from '../redux/auth';

export async function callApi(
  method,
  endPoint,
  params,
  body,
  attachments,
  onUploadProgress,
) {
  try {
    const url = `/${endPoint}${params ? '?' : ''}${params ? params : ''}`;
    const accessToken = getAccessToken();

    let formData;
    if (attachments) {
      const bodyKeys = Object.keys(body);
      if (bodyKeys) {
        formData = new FormData();
        bodyKeys.forEach(key => body[key] && formData.append(key, body[key]));
        Object.keys(attachments).forEach(key =>
          formData.append(
            `File-${key}`,
            attachments[key],
            attachments[key].name,
          ),
        );
      }
    }

    let headers = formData ? {} : { 'Content-Type': 'application/json' };

    if (accessToken) {
      headers = { ...headers, Authorization: `Bearer ${accessToken}` };
    }

    const res = await axios({
      url,
      method,
      headers,
      responseType: endPoint.includes('pdf') && 'blob',
      withCredentials: accessToken && true,
      data: formData || JSON.stringify(body),
      onUploadProgress: progress =>
        onUploadProgress && onUploadProgress(progress),
    });
    const responseBody = res.data || res.status;
    try {
      analytics.track('API Call', {
        method,
        endPoint,
      });
      return responseBody;
    } catch (error) {
      return res.status;
    }
  } catch (error) {
    analytics.track('API Call Error', {
      method,
      endPoint,
      error,
    });
    console.error(error);
    throw error;
  }
}
