import React from 'react';
import { connect } from 'react-redux';
import ConfirmContacts from './ConfirmContacts';
import { TriageItem } from './TriageItem';
import Panel from '../components/Panel';
import './TriageList.scss';

const TriageList = props => {
  const {
    items,
    selected,
    onItemClick,
    animating,
    auth: { user },
    progress,
  } = props;

  const animatingId = animating && animating.id;

  function getContactList() {
    const contacts = { email: [], phone: [] };

    // Get primary email and phone if they exist
    Object.keys(contacts).forEach(type => {
      if (user[type]) {
        contacts[type].push(user[type]);
      }
    });

    // Add in any alternate contacts
    if (user.contacts) {
      user.contacts.forEach(({ type, value }) => {
        contacts[type].push(value);
      });
    }

    return (
      <p style={{ fontWeight: 'bold' }}>
        {contacts.phone.map(number => (
          <React.Fragment key={number}>
            {number}
            <br />
          </React.Fragment>
        ))}
        {contacts.email.map(address => (
          <React.Fragment key={address}>
            {address}
            <br />
          </React.Fragment>
        ))}
      </p>
    );
  }

  const numTriaged =
    (progress && progress.triaged_sms ? progress.triaged_sms : 0) +
    (progress && progress.triaged_email ? progress.triaged_email : 0);

  return (
    <div className="TriageList">
      <div className="to-sort">
        You&rsquo;ve got <span>{items.length} to sort.</span>
      </div>

      {items.map(item => (
        <TriageItem
          key={item.id}
          selected={item.id === selected}
          animating={item.id === animatingId && animating.type}
          onClick={() => onItemClick(item.id)}
          item={item}
        />
      ))}

      {!!items.length && <div className="separator" />}

      {numTriaged <= 5 ? (
        <ConfirmContacts items={items} />
      ) : (
        <Panel>
          <p>
            {props.auth.family_metadata &&
            +props.auth.family_metadata.signup_version === 2
              ? `Add new items by texting "@add [your item]" to `
              : `Add new items by texting`}{' '}
            <a href="sms:206-202-0005">206-202-0005</a> or forwarding emails to{' '}
            <a href="mailto:add@joinmilo.com">add@joinmilo.com</a>.
          </p>
          <p>You can add to Triage from:</p>
          {getContactList()}
          <p>
            To update these, send a note to{' '}
            <a href="mailto:onboarding@joinmilo.com">onboarding@joinmilo.com</a>
            .
          </p>
        </Panel>
      )}
    </div>
  );
};

export default connect(state => ({
  auth: state.auth,
  progress: state.progress,
}))(TriageList);
