import React, { useEffect, useState } from 'react';
import { connect } from 'react-redux';
import getLinks from '../../../shared_modules/links';
import { Avatar } from '../components/Avatar';
import { EmailIframe } from '../components/ItemForm/EmailIframe';
import { ItemForm } from '../components/ItemForm/ItemForm';
import { H2 } from '../components/Type';
import openAttachment, { getEmailUrl } from '../openAttachment';
import './TriageDetail.scss';

export const TriageDetail = connect(
  state => ({ auth: state.auth }),
  {},
)(props => {
  const {
    item,
    type,
    setType,
    formData,
    setFormData,
    formErrors,
    auth,
    BottomBar,
  } = props;

  if (!item) {
    return <div className="TriageDetail empty"></div>;
  }

  const [emailUrl, setEmailUrl] = useState();
  const { title, description, attachments, created_by_id, data } = item;
  const icon = data.source === 'email' ? 'envelope' : 'message-rounded-detail';

  const author =
    auth.id === created_by_id
      ? 'you'
      : auth.users.find(user => user.id === created_by_id).name;

  const attachmentNote =
    attachments &&
    ` with ${attachments.length} attachment${
      attachments.length > 1 ? 's' : ''
    }`;

  // Fill formData when ItemForm is open
  useEffect(() => {
    if (item) {
      setFormData({ ...item, triage: true });
    } else {
      setFormData({});
    }
  }, [item, setFormData]);

  useEffect(() => {
    if (item) {
      getEmailUrl(item).then(setEmailUrl);
    }
  }, [item, setEmailUrl]);

  // If the email has a rich version attached
  // and there are:
  // more than 3 links in the description
  // or html symbols in the description
  // then show the rich version and clear out the description
  useEffect(() => {
    if (description && emailUrl) {
      const links = getLinks(description);
      if (links.length > 3 || description.includes('<html')) {
        setFormData(prevFormData => ({ ...prevFormData, description: null }));
      }
    }
  }, [description, emailUrl, setFormData]);

  return (
    <div className="TriageDetail">
      <div className="detail-container">
        {type ? (
          <ItemForm
            item={item}
            type={type}
            setType={setType}
            formData={formData}
            setFormData={setFormData}
            formErrors={formErrors}
          />
        ) : (
          </* Preview */>
            <H2 variant="sans">{title}</H2>
            <div className="source">
              <div className="icon">
                <box-icon name={icon} />
                {created_by_id && <Avatar id={created_by_id} />}
              </div>
              From {author} via {data.source}
              {attachmentNote}
              {attachments &&
                attachments.map(attachment => (
                  <div
                    key={attachment.id}
                    className="file-link"
                    to="#"
                    onClick={() => openAttachment(item, attachment.id)}
                  >
                    {attachment.name}
                  </div>
                ))}
            </div>
            {emailUrl && <EmailIframe emailUrl={emailUrl} />}
            {formData.description && (
              <p className="description">{description}</p>
            )}
          </>
        )}
      </div>
      {item && BottomBar}
    </div>
  );
});
