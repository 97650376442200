import pluralize from 'pluralize';
import React from 'react';
import { connect } from 'react-redux';
import { track } from '../../utils/analytics';
import AddCollaboratorForm from './AddCollaboratorForm';
import { Card, CardBody } from '../Home/components/Card';
import { Avatar } from '../Home/components/Avatar';
import { MiloModal, MiloModalContent } from '../Home/components/MiloModal';
import HelpImagePanel from '../Home/Dash2/HelpImagePanel';
import './FamilyModal.scss';

const FamilyModal = ({ isOpen, close, auth }) => {
  const users = auth.users
    .filter(user => user.name !== 'bot')
    .sort((a, b) => a.id - b.id);

  return (
    <MiloModal
      isOpen={isOpen}
      size="narrow"
      onRequestClose={close}
      title="Manage Family"
    >
      <MiloModalContent>
        <div className="FamilyModal">
          <HelpImagePanel
            storageKey="family-modal"
            src="https://mv-prod-content.s3.us-west-2.amazonaws.com/Screen+Shot+2021-07-21+at+3.34.09+PM.png"
          >
            Managing a family is a team sport. Add a partner or caregiver so
            they can be assigned to events or to dos:
          </HelpImagePanel>

          {users.map(user => {
            return (
              <Card key={user.id}>
                <CardBody>
                  <h2 style={{ display: 'flex' }}>
                    <Avatar id={user.id} />
                    &nbsp;{user.name}
                  </h2>
                </CardBody>
                {user.email && (
                  <CardBody border="top">
                    <div>
                      <strong>
                        Primary {pluralize('contact', user.phone ? 2 : 1)}
                      </strong>
                    </div>
                    <div>{user.email} (login, calendar invites, triage)</div>
                    {user.phone && <div>{user.phone} (SMS, triage)</div>}
                  </CardBody>
                )}
                {user.contacts && (
                  <CardBody border="top">
                    <div>
                      <strong>
                        Alternate {pluralize('contact', user.contacts.length)}{' '}
                        for Triage only
                      </strong>
                    </div>
                    {user.contacts.map(contact => (
                      <div key={contact.value}>{contact.value}</div>
                    ))}
                  </CardBody>
                )}
              </Card>
            );
          })}

          <AddCollaboratorForm
            afterSubmit={() => track('settings:collaborator:add')}
          />

          <p className="help-text">
            To update your email preferences, add additional contacts, or remove
            a user, please contact Milo at{' '}
            <a href="mailto:onboarding@joinmilo.com">onboarding@joinmilo.com</a>
            .
          </p>
        </div>
      </MiloModalContent>
    </MiloModal>
  );
};

export default connect(({ auth }) => ({ auth }))(FamilyModal);
