import React, { useState, useEffect } from 'react';
import { connect } from 'react-redux';
import {
  MiloModal,
  MiloModalContent,
  MiloModalButtons,
} from '../Home/components/MiloModal';
import { Button } from '../Home/components/Button';
import {
  ItemFieldWrapper,
  ItemFieldEmoji,
} from '../Home/components/ItemForm/ItemFields';
import { Level } from '../Home/components/Level';
import { callApi } from '../../utils/callApi';
import { saveFamilySettings } from '../../redux/auth';
import HelpImagePanel from '../Home/Dash2/HelpImagePanel';

import './FamilySettingsModal.scss';

const COLORS = ['fdfaf5', 'e9f2f4', 'e6f3f1', 'f9e9e8', 'fbf2e3', 'f8f4f7'];

const FamilySettingsModal = ({ isOpen, close, auth, saveFamilySettings }) => {
  const [emoji, setEmoji] = useState(auth.family_emoji);
  const [name, setName] = useState(auth.family);
  const [background, setBackground] = useState(
    (auth.family_metadata && auth.family_metadata.background_color) ||
      COLORS[0],
  );
  const [dirty, setDirty] = useState(false);

  useEffect(() => {
    if (auth.family_metadata && auth.family_metadata.background_color) {
      document.body.style.backgroundColor = `#${auth.family_metadata.background_color}`;
    }
  }, [auth.family_metadata]);

  async function save() {
    const settings = {
      background_color: background,
      emoji,
      name,
    };
    saveFamilySettings(settings);
    await callApi('PUT', `api/settings`, undefined, settings);
    close();
  }

  return (
    <MiloModal
      isOpen={isOpen}
      size="narrow"
      onRequestClose={close}
      title="Customize Family Dash"
    >
      <MiloModalContent>
        <div className="FamilySettingsModal">
          <HelpImagePanel
            storageKey="family-settings"
            src="https://mv-prod-content.s3.us-west-2.amazonaws.com/Screen+Shot+2021-07-22+at+10.06.25+AM.png"
          >
            Pick something the whole family will see:
          </HelpImagePanel>

          <h2>Family Emoji & Name</h2>
          <Level variant="center">
            <ItemFieldEmoji
              formData={{}}
              value={emoji}
              onChange={emoji => {
                setEmoji(emoji);
                setDirty(true);
              }}
            />
            <ItemFieldWrapper>
              <input
                type="text"
                value={name}
                onChange={evt => {
                  setName(evt.target.value);
                  setDirty(true);
                }}
                placeholder="Family name (required)"
              />
            </ItemFieldWrapper>
          </Level>
          <h2>Background</h2>
          <div className="background-colors">
            {COLORS.map(color => (
              <div
                key={color}
                className={`color ${color === background ? 'selected' : ''}`}
                style={{ backgroundColor: `#${color}` }}
                onClick={() => {
                  setBackground(color);
                  setDirty(true);
                }}
              />
            ))}
          </div>
        </div>
      </MiloModalContent>
      <MiloModalButtons>
        <Button onClick={close} variant="secondary" className="hide-mobile">
          Cancel
        </Button>

        <Button disabled={!dirty} onClick={save}>
          Save
        </Button>
      </MiloModalButtons>
    </MiloModal>
  );
};

export default connect(({ auth }) => ({ auth }), {
  saveFamilySettings,
})(FamilySettingsModal);
