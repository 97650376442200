import 'boxicons';
import moment from 'moment';
import React from 'react';
import classNames from 'classnames';
import { connect } from 'react-redux';
import { openItemFormModal } from '../../../redux/itemFormModal';
import { Button } from '../components/Button';
import { Card, CardBody, CardFooterLink } from '../components/Card';
import { TodayEmptyState } from '../components/EmptyState';
import { MiniItem } from '../components/MiniItem';
import { Level } from '../components/Level';
import { H1, H2 } from '../components/Type';
import Anticipator from '../components/Anticipator';
import { DATE_FORMAT } from '../constants';
import Widgets from '../Widgets/Widgets';
import './Summary.scss';
import ThisWeek from './ThisWeek';
import MiloCalendarCard from '../components/MiloCalendarCard';
import MissionCriticalWidget from './MissionCriticalWidget';

const Summary = props => {
  const {
    auth,
    setWeekToday,
    backlog,
    todayItems,
    miloCalendar,
    cardMarkComplete,
    openItemFormModal,
    thisWeeksSuggestions,
    missionCriticalItems
  } = props;

  const memoSetWeekToday = React.useCallback(setWeekToday, []);

  React.useEffect(() => {
    memoSetWeekToday();
  }, [memoSetWeekToday]);

  const leftCol = [
    <div className="widget-row" key="widget-row">
      <Widgets mode="button" />
    </div>,
    <div className="widget-help-row" key="widget-help-row">
      <Widgets mode="help" />
    </div>,
  ];

  const rightCol = [];

  const anticipator = <Anticipator key="anticipator" />;

  const widgetView = (
    <Widgets
      key="widget-view"
      mode="view"
      todoItems={backlog}
      cardMarkComplete={cardMarkComplete}
    />
  );

  const widgetContent = (
    <Widgets
      key="widget-content"
      mode="content"
      thisWeeksSuggestions={thisWeeksSuggestions}
      todoItems={backlog}
      cardMarkComplete={cardMarkComplete}
    />
  );

  const today = (
    <Card key="today">
      <CardBody>
        <Level>
          <H2>Here&rsquo;s what&rsquo;s up today</H2>
          <Button
            variant="clear"
            onClick={() =>
              openItemFormModal({
                date: moment().format(DATE_FORMAT),
              })
            }
          >
            <box-icon name="plus" />
          </Button>
        </Level>
      </CardBody>

      {miloCalendar &&
        miloCalendar.map(record => (
          <CardBody key={record.title} border="top" size="short">
            <MiloCalendarCard record={record} />
          </CardBody>
        ))}

      {todayItems.map(item => (
        <MiniItem key={item.id} item={item} cardMarkComplete={cardMarkComplete} />
      ))}
      {!todayItems.length && <TodayEmptyState />}
      <CardFooterLink to="/home/week">
        Go to week schedule
        <box-icon name="right-arrow-alt" />
      </CardFooterLink>
    </Card>
  );

  const headsUp = (
    <ThisWeek key="heads-up" thisWeeksSuggestions={thisWeeksSuggestions} />
  );
  const critical = (
    <MissionCriticalWidget key="mission-critical" missionCriticalItems={missionCriticalItems} cardMarkComplete={cardMarkComplete} />
   );

  if (window.innerWidth < 768) {
    leftCol.push(anticipator, critical, headsUp, widgetView, today, widgetContent);
  } else {
    leftCol.push(widgetView, today);
    rightCol.push(anticipator, critical, headsUp, widgetContent);
  }

  return (
    <div
      className={classNames('Summary', {
        dash_triage_button_only:
          auth.family_metadata && auth.family_metadata.dash_triage_button_only,
      })}
    >
      <Level variant="start">
        <H1>
          <span role="img" aria-label="hello"></span>👋 Hello{' '}
          {auth.name === 'bot' ? 'Milo' : auth.name}!
        </H1>
      </Level>

      <div className="summary-cols">
        <div className="left-col">{leftCol}</div>
        <div className="right-col">{rightCol}</div>
      </div>
    </div>
  );
};

export default connect(
  state => ({ auth: state.auth, itemFormModal: state.itemFormModal }),
  { openItemFormModal },
)(Summary);
