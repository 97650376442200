import React, { useRef, useState } from 'react';
import DatePicker from 'react-datepicker';
import moment from 'moment';
import { track } from '../../../../utils/analytics';
import { Dropdown, Option } from '../Dropdown';
import { sortItems } from '../../utils';

import {
  COMPLETE,
  COMPLETABLE,
  TYPE_EVENT,
  TYPE_TODO,
  DATE_FORMAT,
} from '../../constants';

const ItemFieldRelated = ({
  onChange,
  openChild,
  type,
  value = [],
  formData,
}) => {
  const [tempId, setTempId] = useState(0);

  const itemsWithTid = value.map(item => {
    if (item.tId) return item;
    return { ...item, tId: item.id };
  });

  // If related items then sort by date
  // otherwise sort by Tid
  const items = itemsWithTid.find(item => !item.status)
    ? sortItems(itemsWithTid)
    : itemsWithTid.sort((a, b) => {
        if (`${a.tId}` < `${b.tId}`) return -1;
        if (`${a.tId}` > `${b.tId}`) return 1;
        return 0;
      });

  // If the current last item has been edited then add
  // a new related item
  if (
    !items.length ||
    (type === TYPE_EVENT && items[items.length - 1].date) ||
    (type === TYPE_TODO && items[items.length - 1].title)
  ) {
    items.push({
      title: '',
      status: type === TYPE_TODO ? COMPLETABLE : undefined,
      type: null,
      tId: `temp-${type}-${tempId + 1}`,
    });
    // tempId.current = tempId.current + 1;
    track('item-form:related:add-new');
  }

  // const handleKeyDown = evt => {
  //   if ([13, 40].includes(evt.keyCode)) {
  //     const idx = inputArray.current.indexOf(evt.target);
  //     if (idx + 1 < inputArray.current.length) {
  //       inputArray.current[idx + 1].focus();
  //       evt.preventDefault();
  //     }
  //   } else if (evt.keyCode === 38) {
  //     const idx = inputArray.current.indexOf(evt.target);
  //     if (idx - 1 >= 0) {
  //       inputArray.current[idx - 1].focus();
  //       evt.preventDefault();
  //     }
  //   }
  // };

  const handleCheck = (evt, oldItem) => {
    const updated = {
      ...oldItem,
      status: evt.target.checked ? COMPLETE : COMPLETABLE,
      dirty: true,
    };
    onChange([
      ...items.filter(item => item.title && item.tId !== updated.tId),
      updated,
    ]);
  };

  const handleDateChange = (date, oldItem) => {
    const updated = {
      ...oldItem,
      date: date ? moment(date).format(DATE_FORMAT) : null,
      title: oldItem.title || formData.title || '',
      location: oldItem.location || formData.location || '',
      time: oldItem.time || formData.time || '',
      end_time: oldItem.end_time || formData.end_time || '',
      description: oldItem.description || formData.description || '',
      tags: oldItem.tags || formData.tags || '',
      internalTags: oldItem.internalTags || formData.internalTags || '',
      priority: oldItem.priority || formData.priority || '',
      assigned_to_ids:
        oldItem.assigned_to_ids || formData.assigned_to_ids,
      dirty: true,
    };
    onChange([
      ...items.filter(item => item.title && item.tId !== updated.tId),
      updated,
    ]);
    if (oldItem.tId > `temp-${type}-${tempId}`) {
      setTempId(tempId + 1);
    }
  };

  const handleTitleChange = (evt, oldItem) => {
    const updated = { ...oldItem, title: evt.target.value || ' ', dirty: true };
    onChange([
      ...items.filter(item => item.title && item.tId !== updated.tId),
      updated,
    ]);
    if (oldItem.tId > `temp-${type}-${tempId}`) {
      setTempId(tempId + 1);
    }
  };

  const handleRemove = oldItem => {
    onChange(items.filter(item => item.title && item.tId !== oldItem.tId));
  };

  const handleDelete = oldItem => {
    if (window.confirm('Are you sure you want to delete this item?')) {
      const updated = {
        ...oldItem,
        deleted: true,
      };
      onChange([
        ...items.filter(item => item.title && item.tId !== updated.tId),
        updated,
      ]);
    }
  };

  return (
    <div className="ItemFieldRelated">
      <div className="title">{type === TYPE_TODO ? 'Checklist' : 'Dates'}</div>
      {items
        .filter(item => {
          if (item.deleted) return false;
          if (type === TYPE_TODO && !!item.status) return true;
          if (type === TYPE_EVENT && !item.status) return true;
          return false;
        })
        .map(item => (
          <div className="related-item" key={item.tId}>
            {type === TYPE_TODO && (
              <input
                type="checkbox"
                checked={item.status === COMPLETE}
                disabled={!item.title}
                onChange={evt => handleCheck(evt, item)}
              />
            )}

            {type === TYPE_EVENT && (
              <DatePicker
                placeholderText="Add a date..."
                selected={item.date && moment(item.date).valueOf()}
                onChange={date => handleDateChange(date, item)}
                dateFormat="MMM d, yyyy"
              />
            )}
            {'  '}
            <input
              className="add-event"
              // onKeyDown={handleKeyDown}
              placeholder={type === TYPE_TODO ? 'Add a to do…' : ''}
              type="text"
              value={item.title}
              onChange={evt => handleTitleChange(evt, item)}
            />
            {item.title && (
              <RelatedMenu
                edit={() => openChild(item)}
                remove={type !== TYPE_EVENT && (() => handleRemove(item))}
                deleteItem={() => handleDelete(item)}
              />
            )}
          </div>
        ))}
    </div>
  );
};

const RelatedMenu = ({ edit, remove, deleteItem }) => {
  const ref = useRef();
  const [dropdownOpen, setDropdownOpen] = useState(false);

  return (
    <div className="RelatedMenu" ref={ref}>
      <box-icon
        name="dots-horizontal-rounded"
        onClick={() => setDropdownOpen(true)}
      />
      <Dropdown
        isOpen={dropdownOpen}
        close={() => setDropdownOpen(false)}
        wrapperRef={ref}
        side={'right'}
      >
        <Option
          onClick={() => {
            edit();
            setDropdownOpen(false);
          }}
        >
          <box-icon name="edit" /> Edit item
        </Option>
        {remove && (
          <Option onClick={remove}>
            <box-icon name="list-minus" /> Move to To Do
          </Option>
        )}
        <Option onClick={deleteItem}>
          <box-icon name="trash" /> Delete item
        </Option>
      </Dropdown>
    </div>
  );
};

export default ItemFieldRelated;
