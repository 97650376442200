import React, { useState, useEffect } from 'react';
import moment from 'moment';
import { DATE_FORMAT } from '../../Home/constants';

const QUESTION_TEXT = 'Our Terms of Service and Privacy Policy';
const RESPONSE = 'I accept';
const LANDING_URL = 'https://www.joinmilo.com';
const TERMS_OF_SERVICE_URL = `${LANDING_URL}/terms-of-service`;
const PRIVACY_POLICY_URL = `${LANDING_URL}/privacy-policy`;
const METADATA_KEY = 'accepted-tos-date';

const ToSQuestion = ({ isCurrent, onAnswer, data, showComment }) => {
  const [revisiting, setRevisiting] = useState(false);
  const savedData = data.family.metadata && data.family.metadata[METADATA_KEY];

  useEffect(() => {
    if (savedData && isCurrent) {
      onAnswer();
    }
  }, [savedData, isCurrent, onAnswer]);

  return (
    <div className="ToSQuestion">
      {!(isCurrent || revisiting) && (
        <>
          <div className="answered-question">{QUESTION_TEXT}</div>
          <div className="response-wrapper">
            <div className="response" onClick={() => setRevisiting(true)}>
              {RESPONSE}
              <box-icon name="check" />
            </div>
          </div>
          {showComment && (
            <div className="milo-comment">
              <span role="img" aria-label="Grinning Face with Sweat emoji">
                😅
              </span>{' '}
              Amazing. Last step.
            </div>
          )}
        </>
      )}

      {(isCurrent || revisiting) && (
        <>
          <div className="title">{QUESTION_TEXT}</div>
          <p>
            The TL;DR is that we take privacy and the information you entrust
            with us with the utmost responsibility and use it only to serve you
            and your family better.
            <br />
            <br />
            {revisiting && (
              <>
                Accepted:
                <br />
              </>
            )}
            <a
              href={TERMS_OF_SERVICE_URL}
              rel="noopener noreferrer"
              target="_blank"
            >
              Terms of Service{' '}
              <box-icon
                size="xs"
                name="link-external"
                style={{ position: 'relative', top: 2 }}
              />
            </a>
            <br />
            <a
              href={PRIVACY_POLICY_URL}
              rel="noopener noreferrer"
              target="_blank"
            >
              Privacy Policy{' '}
              <box-icon
                size="xs"
                name="link-external"
                style={{ position: 'relative', top: 2 }}
              />
            </a>
          </p>

          <div
            className="option"
            onClick={() => {
              if (revisiting) {
                setRevisiting(false);
              } else {
                onAnswer({
                  key: METADATA_KEY,
                  value: moment().format(DATE_FORMAT),
                });
              }
            }}
          >
            {revisiting ? 'Continue' : RESPONSE}
          </div>
        </>
      )}
    </div>
  );
};

export default ToSQuestion;
