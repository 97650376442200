import React, { forwardRef } from 'react';
import DatePicker from 'react-datepicker';
import moment from 'moment';
import { Button } from '../../components/Button';

import './MiniTimePicker.scss';

const MiniTimePicker = ({ value, onChange }) => {
  return (
    <div className="MiniTimePicker">
      <DatePicker
        placeholderText="Time"
        timeCaption="Time"
        selected={
          value && moment(moment().format('YYYY-MM-DD') + ' ' + value).valueOf()
        }
        onChange={time => onChange(time ? moment(time).format('HH:mm') : null)}
        showTimeSelect
        showTimeSelectOnly
        timeIntervals={15}
        dateFormat="h:mma"
        customInput={<CustomInput />}
      />
    </div>
  );
};

const CustomInput = forwardRef(({ value, onClick }, ref) => (
  <div ref={ref}>
    <Button variant="clear small" onClick={onClick}>
      {value} <box-icon name="chevron-down" />
    </Button>
  </div>
));

CustomInput.displayName = 'CustonInput';

export default MiniTimePicker;
