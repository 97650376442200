import React, { useState, useRef } from 'react';
import classNames from 'classnames';
import { connect } from 'react-redux';
import { getEmojiDataFromNative } from 'emoji-mart';
import emojiData from 'emoji-mart/data/all.json';
import moment from 'moment';
import { ItemFieldWrapper } from '../components/ItemForm/ItemFields';
import UniversalSearch from '../components/UniversalSearch';
import { openItemFormModal } from '../../../redux/itemFormModal';
import { DATE_FORMAT } from '../constants';
import './Search.scss';

const Search = connect(undefined, { openItem: openItemFormModal })(props => {
  const { openItem, className } = props;

  const [showResults, setShowResults] = useState(false);
  const [searchTerm, setSearchTerm] = useState('');
  const [expanded, setExpanded] = useState(false);
  const ref = useRef(null);

  return (
    <ItemFieldWrapper
      icon="search"
      className={classNames('Search', className, { expanded })}
      ref={ref}
    >
      <input
        type="text"
        placeholder="Search"
        value={searchTerm}
        onChange={evt => setSearchTerm(evt.target.value)}
        onClick={() => setShowResults(true)}
        onFocus={() => {
          setExpanded(true);
          setShowResults(true);
        }}
        onBlur={() => window.setTimeout(() => setExpanded(false), 10)}
      />
      <UniversalSearch
        isOpen={showResults}
        close={() => setShowResults(false)}
        query={searchTerm}
        hideCreateNew={true}
        onSelectHistorical={({ id }) => {
          setSearchTerm('');
          setShowResults(false);
          openItem(id);
        }}
        onSelectSuggested={({ title, description, emoji, id }) => {
          setSearchTerm('');
          setShowResults(false);
          openItem({
            date: moment().format(DATE_FORMAT),
            title: title,
            description: description,
            emoji: emoji && getEmojiDataFromNative(emoji, 'apple', emojiData),
            data: { suggestionId: id },
          });
        }}
        wrapperRef={ref}
      />
    </ItemFieldWrapper>
  );
});

export default Search;
