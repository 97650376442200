import { useEffect } from 'react';

const CorporateQuestion = ({ onAnswer, isCurrent }) => {
  useEffect(() => {
    if (isCurrent) {
      onAnswer({ key: 'corporate-complete', value: true });
    }
  }, [isCurrent, onAnswer]);

  return null;
};

export default CorporateQuestion;
