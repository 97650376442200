import idx from 'idx';
const PROGRESS_SET = 'PROGRESS_SET';
const INCREMENT_STREAK = 'INCREMENT_STREAK';
const initialState = null;

export function set(value) {
  return { type: PROGRESS_SET, value };
}

export function incrementStreak() {
  return { type: INCREMENT_STREAK };
}

export function reducer(state = initialState, action) {
  let result = state;
  if (action.type === PROGRESS_SET) {
    result = action.value;
  } else if (action.type === INCREMENT_STREAK) {
    if (idx(result, _ => _.achievements.top_three_streak.this_week)) {
      // If there's already a streak this week, do nothing
    } else if (idx(result, _ => _.achievements.top_three_streak.last_week)) {
      // If there's a streak for last week, continue it for this week
      result = {
        ...result,
        achievements: {
          ...result.achievements,
          top_three_streak: {
            ...result.achievements.top_three_streak,
            this_week: result.achievements.top_three_streak.last_week + 1,
          },
        },
      };
    } else {
      // If there are no streaks, start one for this week
      result = {
        ...result,
        achievements: {
          ...result.achievements,
          top_three_streak: {
            this_week: 1,
          },
        },
      };
    }
  }

  return result;
}

export default { set, incrementStreak };
