import React from 'react';
import './EmailIframe.scss';

export const EmailIframe = ({ emailUrl }) => {
  if (!emailUrl) {
    return null;
  }

  return <iframe title="Email" src={emailUrl} className="EmailIframe" />;
};
