import React from 'react';
import Widget from '../Widget';
import WisdomView from './WisdomView';

export const WIDGET_NAME = 'wisdom';

const modes = {
  content: WisdomView,
};

const WisdomWidget = props => {
  const { mode, open, close, thisWeeksSuggestions } = props;
  return modes[mode] ? (
    <Widget
      key={WIDGET_NAME}
      name={WIDGET_NAME}
      active={modes[mode]}
      open={open}
      onRequestClose={close}
      thisWeeksSuggestions={thisWeeksSuggestions}
    />
  ) : null;
};

export default { name: WIDGET_NAME, widget: WisdomWidget };
