import React from 'react';
import { connect } from 'react-redux';
import {
  MiloModal,
  MiloModalContent,
  MiloModalButtons,
} from '../components/MiloModal';
import { Button } from '../components/Button';
import Tomorrow from './Tomorrow';
import HelpImagePanel from './HelpImagePanel';

const TomorrowModal = ({ isOpen, close, ...props }) => {
  return (
    <MiloModal
      isOpen={isOpen}
      size="narrow"
      onRequestClose={close}
      title="Plan For Tomorrow"
      className="TomorrowModal"
    >
      <MiloModalContent>
        <HelpImagePanel
          storageKey="plan-for-tomorrow"
          src="https://mv-prod-content.s3.us-west-2.amazonaws.com/Screen+Shot+2021-07-21+at+3.26.15+PM.png"
        >
          Stop holding the whole family’s plan in your head. Daily rundown
          emails keep the whole crew in the loop:
        </HelpImagePanel>

        <Tomorrow hideTitle={true} {...props} />
      </MiloModalContent>
      <MiloModalButtons>
        <Button onClick={close}>Close</Button>
      </MiloModalButtons>
    </MiloModal>
  );
};

export default connect(({ auth }) => ({ auth }), {})(TomorrowModal);
