import React from 'react';
import Widget from '../Widget';
import TopThreeView from './TopThreeView';
import TopThreeEdit from './TopThreeEdit';

export const WIDGET_NAME = 'top-three';

const modes = {
  edit: TopThreeEdit,
  view: TopThreeView,
};

const TopThreeWidget = props => {
  const { mode, open, close, todoItems, cardMarkComplete } = props;
  return modes[mode] ? (
    <Widget
      key={WIDGET_NAME}
      name={WIDGET_NAME}
      active={modes[mode]}
      open={open}
      onRequestClose={close}
      todoItems={todoItems}
      cardMarkComplete={cardMarkComplete}
    />
  ) : null;
};

export default { name: WIDGET_NAME, widget: TopThreeWidget };
