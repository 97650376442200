import idx from 'idx';
import React from 'react';
import { connect } from 'react-redux';
import { BrowserRouter as Router, Route, Switch } from 'react-router-dom';
import { version } from '../../../package.json';
import { getAccessToken, reset, storeLogin } from '../../redux/auth';
import * as analytics from '../../utils/analytics';
import { callApi } from '../../utils/callApi';
import ConnectCalendars from '../Account/ConnectCalendars';
import Home from '../Home/Home';
import Join from '../Login/Join';
import Login from '../Login/Login';
import Signup from '../Login/Signup';
import Nav from '../Nav/Nav';
import Picks from '../Picks/Picks';
import SignupWizard from '../SignupWizard/SignupWizard';
import GroupSignupWizard from '../SignupWizard/GroupSignupWizard';
import UnpaidModal from './UnpaidModal';

class App extends React.Component {
  state = {
    loading: true,
  };

  async componentDidMount() {
    console.log(version);
    this.login();
  }

  async storageListener(event) {
    const { auth } = this.props;
    if (event.key === 'access_token') {
      let newAuth;
      try {
        newAuth = await callApi('POST', 'login');
      } catch (error) {
        // Could not log in
      }
      if (!newAuth || newAuth.id !== auth.id) {
        window.location.reload();
      }
    }
  }

  async login() {
    // check auth - with access token only
    if (getAccessToken()) {
      try {
        const auth = await callApi('POST', 'login');
        if (auth && auth.name) {
          this.props.handleStoreLogin(auth);
          window.addEventListener('storage', async event =>
            this.storageListener(event),
          );

          analytics.track('Login success', {
            userId: auth.id,
            method: 'token',
          });
          analytics.identify(auth.id, {
            method: 'token',
            familyId: auth.family_id,
            familyName: auth.family,
            name: auth.name,
          });
        }
      } catch (error) {
        console.error(error);
      }
    }
    this.setState({ loading: false });
  }

  render() {
    const { loggedIn, auth } = this.props;
    const { loading } = this.state;
    if (loading) {
      return <div>Loading...</div>;
    }

    return (
      <Router>
        <Switch>
          <Route path="/picks/:hash" component={Picks} />
          <Route path="/join/a/:groupHash" component={Join} />
          <Route path="/join/:familyHash" component={Join} />
          <Route path="/bot/:magicToken" component={Signup} />
          <Route path="/join-milo/g/:hash" component={GroupSignupWizard} />
          <Route
            path="/join-milo/:hash/:stripeStatus"
            component={SignupWizard}
          />
          <Route path="/join-milo/:hash" component={SignupWizard} />
          {!loggedIn && (
            <Switch>
              <Route path="/signup/:magicToken" component={Signup} />
              <Route path="/password/:magicToken" component={Signup} />
              <Route component={Login} />
            </Switch>
          )}
          {loggedIn && (
            <>
              <Nav />
              <Switch>
                <Route path="/calendar" component={ConnectCalendars} />
                <Route path="/calendar/:code" component={ConnectCalendars} />
                <Route path="/home" component={Home} />
                <Route component={Home} />
              </Switch>
              {!auth.has_paid && <UnpaidModal />}
            </>
          )}
        </Switch>
      </Router>
    );
  }
}

export default connect(
  state => ({
    loggedIn: idx(state, _ => _.auth.loggedIn),
    auth: state.auth,
  }),
  {
    handleStoreLogin: storeLogin,
    handleLogout: reset,
  },
)(App);
