import classNames from 'classnames';
import React, { useRef, useState } from 'react';
import DatePicker from 'react-datepicker';
import 'react-datepicker/dist/react-datepicker.css';
import { Button } from '../components/Button';
import { Dropdown } from '../components/Dropdown';

const CalendarColumnHeader = props => {
  const {
    className,
    style,
    date,
    activeDay,
    setActiveDay,
    hidePicker,
    backgroundColor,
  } = props;

  const [pickerOpen, setPickerOpen] = useState(false);
  const ref = useRef(null);

  return (
    <div
      className={classNames('CalendarColumnHeader', className)}
      style={style}
    >
      <div className="mobile-header" ref={ref}>
        <Button variant="clear" onClick={() => setActiveDay(-1)}>
          <box-icon size="md" name="chevron-left" />
        </Button>

        <div onClick={() => !hidePicker && setPickerOpen(!pickerOpen)}>
          <strong>{date.clone().format('ddd')}, </strong>
          {date.clone().format('MMM D')}
          {!hidePicker && (
            <Button variant="clear">
              <box-icon size="md" name="chevron-down" />
            </Button>
          )}
        </div>
        <Button variant="clear" onClick={() => setActiveDay(1)}>
          <box-icon size="md" name="chevron-right" />
        </Button>
        <Dropdown
          isOpen={pickerOpen}
          close={() => setPickerOpen(false)}
          wrapperRef={ref}
          unstyled={true}
          top={64}
          side={'center'}
        >
          <DatePicker
            selected={activeDay.toDate()}
            onChange={date => setActiveDay(date)}
            todayButton={'Today'}
            inline
          />
        </Dropdown>
      </div>

      <div className="desktop-header">
        <strong>{date.clone().format('ddd')}, </strong>
        {date.clone().format('MMM D')}
      </div>
    </div>
  );
};

export default CalendarColumnHeader;
