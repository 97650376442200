import classNames from 'classnames';
import moment from 'moment';
import React from 'react';
import { connect } from 'react-redux';
import { openItemFormModal } from '../../../redux/itemFormModal';
import { open as setOpenModal } from '../../../redux/modal';
import { track } from '../../../utils/analytics';
import { callApi } from '../../../utils/callApi';
import { Button } from '../components/Button';
import ButtonDropdown from '../components/ButtonDropdown';
import { Card, CardItems } from '../components/Card';
import HelpButton from '../components/HelpButton';
import { BlankItem, Item } from '../components/Item';
import MiloCalendarCard from '../components/MiloCalendarCard';
import Toolbar from '../components/Toolbar';
import { COMPLETABLE, DATE_FORMAT } from '../constants';
import { BLANK_TIME } from './align-days';
import CalendarColumnHeader from './CalendarColumnHeader';
import { splitItems } from './split-items';
import './Week.scss';
import WeekSelect from './WeekSelect';

const COLLAPSED_STORAGE_KEY = 'week:sections:collapsed';

// Load collapsed sections from localStorage
function loadWeekCollapsed() {
  return (window.localStorage.getItem(COLLAPSED_STORAGE_KEY) || '')
    .trim()
    .split(' ')
    .reduce((acc, section) => {
      acc[section] = true;
      return acc;
    }, {});
}

// Save to localStorage
export function saveWeekCollapsed(id, collapsed) {
  const existing = (
    window.localStorage.getItem(COLLAPSED_STORAGE_KEY) || ''
  ).split(' ');
  if (collapsed) {
    if (!existing.includes(id)) {
      window.localStorage.setItem(
        COLLAPSED_STORAGE_KEY,
        [...existing, id].join(' '),
      );
    }
  } else {
    window.localStorage.setItem(
      COLLAPSED_STORAGE_KEY,
      existing.filter(sectionId => sectionId !== id).join(' '),
    );
  }
}

class Week extends React.Component {
  state = {
    lastStartDate: undefined,
    activeDay: this.getActiveDay(),
    collapsed: {},
    expanded: {},
    showThisWeekBanner: false,
    showNextWeekBanner: false,
  };

  getActiveDay() {
    const start = moment(this.props.weekStartDate);
    const today = moment();
    if (today.isBefore(start) || today.isAfter(start.add(7, 'days'))) {
      return start;
    }
    return moment();
  }

  scrollDashboardToDay() {
    if (this.todayRef && this.todayRef.el) {
      // desktop
      document.documentElement.scrollTo(
        this.todayRef.el.offsetWidth * this.todayRef.i,
        0,
      );
    }
  }

  componentDidUpdate(prevProps) {
    const { scrollToToday, setScrollToToday, weekStartDate, itemsByDay } =
      this.props;
    if (scrollToToday) {
      this.scrollDashboardToDay();
      setScrollToToday(false);
    }

    // If week has changed, check to see if our activeDay is still within the week. If not, set it
    // to the start of the new week.
    if (prevProps.weekStartDate !== weekStartDate) {
      const activeDayWeekStart = this.state.activeDay
        .clone()
        .startOf('isoWeek')
        .format(DATE_FORMAT);

      if (activeDayWeekStart !== weekStartDate) {
        this.setState({ activeDay: moment(weekStartDate) });
      }
    }

    // If items have changed, re-load collapsed state from localStorage
    if (prevProps.itemsByDay !== itemsByDay) {
      this.setState({ collapsed: loadWeekCollapsed() });
    }

    // If week or items have changed, reset the banners
    if (
      prevProps.weekStartDate !== weekStartDate ||
      prevProps.itemsByDay !== itemsByDay
    ) {
      this.setBanner();
    }
  }

  componentDidMount() {
    document.documentElement.classList.add('body-scroll');
    this.scrollDashboardToDay();

    this.setState({ collapsed: loadWeekCollapsed() });

    this.setBanner();

    track('week:page:load');
  }

  componentWillUnmount() {
    document.documentElement.classList.remove('body-scroll');
  }

  setActiveDay(param) {
    const { weekStartDate, setWeekStart } = this.props;
    let activeDay;
    if (param === 1 || param === -1) {
      // Increment or decrement day
      activeDay = this.state.activeDay.clone().add(param, 'days');
    } else {
      // Jump to day
      activeDay = moment(param);
    }

    this.setState({ activeDay });

    // Change weeks if necessary. Do this after setting activeDay so the week update doesn't
    // accidentally also trigger an activeDay update.
    const newWeekStart = activeDay
      .clone()
      .startOf('isoWeek')
      .format(DATE_FORMAT);
    if (newWeekStart !== weekStartDate) {
      setWeekStart(newWeekStart);
    }
  }

  setBanner() {
    window.setTimeout(() => {
      const { weekStartDate, progress, itemsByDay } = this.props;

      const isThisWeek =
        weekStartDate === moment().startOf('isoWeek').format(DATE_FORMAT);
      const isFutureWeek =
        weekStartDate >=
        moment().add(7, 'days').startOf('isoWeek').format(DATE_FORMAT);

      if (isThisWeek || isFutureWeek) {
        if (isThisWeek && [5, 6, 0].includes(moment().day())) {
          // Fri–Sun this week
          // If no meal planner or schedule builder items next week, show next week banner
          this.setState({
            showThisWeekBanner: false,
            showNextWeekBanner: progress ? !progress.planned_next_week : false,
          });
        } else {
          // Mon–Thu this week OR a future week
          // If no meal planner or schedule builder items in the week currently being viewed, show
          // this week banner
          this.setState({
            showNextWeekBanner: false,
            showThisWeekBanner: !itemsByDay.find(day => {
              return day.find(item => {
                return (
                  !!item.data &&
                  ['meal-planner', 'planner'].includes(item.data.source)
                );
              });
            }),
          });
        }
      }
    }, 500);
  }

  async exportPDF() {
    const { weekStartDate } = this.props;
    const copy = await callApi('GET', `api/pdf/sunday/${weekStartDate}`);
    if (copy) {
      const file = new Blob([copy], { type: 'application/pdf' });
      const fileURL = URL.createObjectURL(file);
      window.open(fileURL);
    }
    track('week:pdf:click');
  }

  renderBlock(items, cardMarkComplete, { id: sectionId, hideEmpty }, date) {
    const numRealItems = items.filter(item => item.time !== BLANK_TIME).length;

    return (
      <div style={{ position: 'relative' }}>
        <Card>
          <CardItems>
            {items
              .slice(0, this.state.expanded[sectionId] ? undefined : 3)
              .map((item, index) => {
                if (item.time === BLANK_TIME) {
                  // If there are real items in this section, this isn't the first item, or we want
                  // to hide all empties in this column, apply the hide-mobile class.
                  return (
                    <BlankItem
                      key={index}
                      className={
                        numRealItems || !!index || hideEmpty
                          ? 'hide-mobile'
                          : ''
                      }
                    />
                  );
                } else {
                  return (
                    <Item
                      key={item.id}
                      item={item}
                      cardMarkComplete={cardMarkComplete}
                    />
                  );
                }
              })}
            {!items.length && !hideEmpty && <BlankItem />}
          </CardItems>
        </Card>
        {(sectionId === 'todos' || sectionId === 'evening') && (
          <div
            className="plus-button"
            onClick={() =>
              this.props.openItemFormModal({
                status: sectionId === 'todos' ? COMPLETABLE : undefined,
                date,
              })
            }
          >
            <box-icon name="plus" />
          </div>
        )}
        {numRealItems > 3 && (
          <div
            className="see-more-button"
            onClick={() =>
              this.setState({
                expanded: {
                  ...this.state.expanded,
                  [sectionId]: !this.state.expanded[sectionId],
                },
              })
            }
          >
            {this.state.expanded[sectionId]
              ? '– LESS'
              : `+${numRealItems - 3} MORE`}
          </div>
        )}
      </div>
    );
  }

  renderSlidingHeader({ name, id, button, className }, showMobile) {
    return (
      <div
        className={classNames('sliding-header', { 'show-mobile': showMobile })}
      >
        <h2
          onClick={() => {
            const collapsed = !this.state.collapsed[id];

            // Save to state
            this.setState({
              collapsed: {
                ...this.state.collapsed,
                [id]: collapsed,
              },
            });

            saveWeekCollapsed(id, collapsed);
          }}
          className={className}
        >
          {this.state.collapsed[id] ? (
            <box-icon name="caret-right" />
          ) : (
            <box-icon name="caret-down" />
          )}
          {name}
        </h2>
        {button}
      </div>
    );
  }

  renderBanner(showThisWeekBanner, showNextWeekBanner) {
    return (
      <div className="banner">
        <div className="banner-inner">
          {showThisWeekBanner && (
            <>
              Let’s plan the week:{' '}
              <span role="img" aria-label="shallow pan of food emoji">
                🥘
              </span>{' '}
              Meals,{' '}
              <span role="img" aria-label="check mark button emoji">
                ✅
              </span>{' '}
              To dos,{' '}
              <span role="img" aria-label="tear-off calendar emoji">
                📆
              </span>{' '}
              Schedule. (Bonus? Nag free week—everyone gets the daily rundown
              email.)
            </>
          )}
          {showNextWeekBanner && (
            <>
              <span
                role="img"
                aria-label="woman dancing medium skin tone emoji"
              >
                💃🏽
              </span>{' '}
              &nbsp;Let's set next week’s plan! Head to next week to knock it
              out.
            </>
          )}
        </div>
      </div>
    );
  }

  render() {
    const {
      weekStartDate,
      weekToday,
      weekBack,
      weekForward,
      open,
      plannerOpen,
      planner2Open,
      cardMarkComplete,
      miloCalendar,
      itemsByDay,
      toggleOnlyMe,
      showOnlyMe,
      auth,
    } = this.props;

    const { activeDay, showThisWeekBanner, showNextWeekBanner } = this.state;

    const miloCalendarByDay = miloCalendar.reduce((acc, item) => {
      if (acc[item.date]) {
        acc[item.date].push(item);
      } else {
        acc[item.date] = [item];
      }
      return acc;
    }, {});

    const maxNumMiloCalendar = Object.values(miloCalendarByDay).reduce(
      (max, items) => {
        return items.length > max ? items.length : max;
      },
      0,
    );

    const organizedItems = splitItems(itemsByDay);

    return (
      <div className="Week">
        <Toolbar>
          <div>
            <Button
              className="hide-mobile"
              variant="secondary"
              onClick={() => this.exportPDF()}
            >
              PDF
            </Button>
          </div>

          <WeekSelect
            weekStartDate={weekStartDate}
            weekToday={weekToday}
            weekBack={weekBack}
            weekForward={weekForward}
            width={256}
          />
          <div className="controls-wrapper">
            <ButtonDropdown
              side="right"
              variant="secondary"
              options={[
                {
                  name: 'Everyone',
                  onClick: toggleOnlyMe,
                  disabled: !showOnlyMe,
                },
                {
                  name: 'Only Me',
                  onClick: toggleOnlyMe,
                  disabled: showOnlyMe,
                },
              ]}
            >
              Show: {showOnlyMe ? 'Only Me' : 'Everyone'}
            </ButtonDropdown>
          </div>
        </Toolbar>

        {(showThisWeekBanner || showNextWeekBanner) &&
          this.renderBanner(showThisWeekBanner, showNextWeekBanner)}

        <div className="calendar-column-container">
          {[0, 1, 2, 3, 4, 5, 6].map(i => {
            const date = moment(weekStartDate).add(i, 'days');
            const isToday =
              date.format(DATE_FORMAT) === moment().format(DATE_FORMAT);
            const isPast = moment().startOf('day').isAfter(date);
            const isActive =
              date.format(DATE_FORMAT) === activeDay.format(DATE_FORMAT);

            const items = (organizedItems && organizedItems[i]) || [];
            const miloCalendarItems =
              miloCalendar &&
              miloCalendar.filter(
                calRecord => calRecord.date === date.format(DATE_FORMAT),
              );
            return (
              <div
                className={classNames('calendar-column', {
                  'active-day': isActive,
                })}
                key={i}
                ref={el => {
                  if (isToday) {
                    this.todayRef = { el, i };
                  }
                }}
              >
                <CalendarColumnHeader
                  className={classNames({ today: isToday, past: isPast })}
                  style={{
                    zIndex: !open && !plannerOpen && !planner2Open && 2,
                    backgroundColor:
                      (auth.family_metadata &&
                        auth.family_metadata.background_color &&
                        `#${auth.family_metadata.background_color}`) ||
                      undefined,
                  }}
                  date={date}
                  activeDay={activeDay}
                  setActiveDay={param => this.setActiveDay(param)}
                />
                <div
                  className={
                    (miloCalendar &&
                      miloCalendar.length > 0 &&
                      'milo-calendar-wrapper') ||
                    undefined
                  }
                  style={{ height: maxNumMiloCalendar * 24 }}
                >
                  {miloCalendarItems &&
                    miloCalendarItems.map(calRecord => (
                      <MiloCalendarCard
                        key={calRecord.title}
                        record={calRecord}
                      />
                    ))}
                </div>
                <div className="calendar-column-items">
                  {getSections(this.props.setOpenModal)
                    .filter(
                      section =>
                        !(
                          this.state.collapsed.today &&
                          section.className === 'small'
                        ),
                    )
                    .map(section => (
                      <React.Fragment key={section.name}>
                        <div className="sliding-header-wrapper">
                          {this.renderSlidingHeader(section, !!i)}
                        </div>

                        {!this.state.collapsed[section.id] &&
                          this.renderBlock(
                            items[section.id],
                            cardMarkComplete,
                            section,
                            date.format(DATE_FORMAT),
                          )}
                      </React.Fragment>
                    ))}
                </div>
              </div>
            );
          })}
        </div>
      </div>
    );
  }
}

export default connect(({ progress, auth }) => ({ progress, auth }), {
  setOpenModal,
  openItemFormModal,
})(Week);

function getSections(openModal) {
  return [
    {
      id: 'meals',
      name: 'Meals',
      button: (
        <div className="tool-wrapper">
          <Button variant="clear" onClick={() => openModal('meal-planner')}>
            <box-icon name="pencil" /> Meal Planner
          </Button>
          <HelpButton id={2} />
        </div>
      ),
    },
    { id: 'todos', name: 'To Do' },
    {
      id: 'today',
      name: 'Schedule',
      button: (
        <div className="tool-wrapper">
          <Button variant="clear" onClick={() => openModal('planner')}>
            <box-icon name="pencil" /> Schedule Builder
          </Button>
          <HelpButton id={1} />
        </div>
      ),
      hideEmpty: true,
    },
    { id: 'morning', name: 'Morning', className: 'small' },
    { id: 'afternoon', name: 'Afternoon', className: 'small' },
    { id: 'evening', name: 'Evening', className: 'small' },
  ];
}
