import moment from 'moment';
import React, { useCallback, useState } from 'react';
import { connect } from 'react-redux';
import uuid from 'uuid/v4';
import reduxItems from '../../../redux/items';
import { callApi } from '../../../utils/callApi';
import { DATE_FORMAT } from '../constants';
import './Widget.scss';

/**
 * Widget - Framework to build widgets with different modes
 *          that can utilize shared functionality provided
 *          by this module
 *
 * Usage - <Widget active={ReactComponentX} />
 *         ReactComponentX will be able to call any of the
 *         below methods from props.
 *         Functions include loading and saving items
 *         to/from state.
 *         Loading and creating/updating (applying) items
 *         to/from server.
 */

const Widget = connect(undefined, {
  updateGlobalItem: reduxItems.update,
  addGlobalItem: reduxItems.add,
  deleteGlobalItem: reduxItems.deleteItem,
})(props => {
  const {
    name,
    active: ActiveComponent,
    updateGlobalItem,
    addGlobalItem,
    deleteGlobalItem,
  } = props;

  // The items that this widget works with
  const [items, setItems] = useState([]);
  const [dirty, setDirty] = useState(false);

  // The start date - defaults to last or current Monday
  // Formatted YYYY-MM-DD
  const [startDate, setStartDate] = useState(
    moment().startOf('isoWeek').format(DATE_FORMAT),
  );

  // Cal API to load items in to state
  const loadItems = useCallback(
    async options => {
      // async function loadItems(options) {
      const { weekStartDate, withEvents, type, tag } = options;
      const date = weekStartDate || startDate;
      const endDate = moment(date).add(6, 'days').format(DATE_FORMAT);
      const newItems = await callApi(
        'GET',
        'api/items',
        `start_date=${date}&end_date=${endDate}${
          withEvents ? '&events=1' : ''
        }${type ? `&type=${type}` : ''}${tag ? `&tag=${tag}` : ''}`,
      );

      setItems(newItems);
      setDirty(false);
    },
    [startDate],
  );

  // Call API to save items in DB
  // Updates existing items, creates new ones and deletes as needed
  async function applyItems() {
    const newItems = items
      .filter(item => item.newItem && !item.deleted)
      .map(item => ({ ...item, id: undefined, newItem: undefined }));
    let created = [];
    if (newItems.length > 0) {
      created = await callApi('POST', 'api/items', undefined, newItems);
    }

    const existingItems = items.filter(item => !item.newItem && !item.deleted);
    const updatedItems = existingItems.filter(item => item.dirty);
    await Promise.all(
      updatedItems.map(item =>
        callApi('PUT', `api/items/${item.id}`, undefined, item),
      ),
    );

    const deletedItems = items.filter(
      item => item.deleted === true && item.newItem !== true,
    );
    await Promise.all(
      deletedItems.map(item => callApi('DELETE', `api/items/${item.id}`)),
    );

    setItems([
      ...created,
      ...existingItems.map(item => ({ ...item, dirty: undefined })),
    ]);
    setDirty(false);

    // Update global items
    updatedItems.forEach(item => updateGlobalItem(item));
    created.forEach(item => addGlobalItem(item));
    deletedItems.forEach(item => deleteGlobalItem(item));
  }

  // Update an item in state
  function setItem(editItem) {
    if (!editItem.title || editItem.title === '') {
      editItem.deleted = true;
    } else {
      editItem.deleted = undefined;
    }
    editItem.dirty = true;
    const newItems = [
      ...items.filter(item => item.id !== editItem.id),
      editItem,
    ];
    setItems(newItems);
    setDirty(true);
  }

  // Add a new item
  // Use uuid as temp id so we can edit in place before save
  function newItem(item) {
    return { ...item, id: uuid(), newItem: true };
  }

  // Calculate the formated date given the day of week (0 is Monday)
  function dateOnIndex(index) {
    return moment(startDate).add(index, 'days').format(DATE_FORMAT);
  }

  /**
   * Date manipulation
   */
  function weekForward() {
    const newDate = moment(startDate).add(7, 'days').format(DATE_FORMAT);
    setStartDate(newDate);
    return newDate;
  }

  function weekBack() {
    const newDate = moment(startDate).add(-7, 'days').format(DATE_FORMAT);
    setStartDate(newDate);
    return newDate;
  }

  function weekThisWeek() {
    const newDate = moment().startOf('isoWeek').format(DATE_FORMAT);
    setStartDate(newDate);
    return newDate;
  }

  /**
   * Settings: Load and save settings for the given widget
   */
  function loadSettings() {
    // TODO: Implement Widget::loadSettings
  }
  function saveSettings(settings) {
    // TODO: Implement Widget::saveSettings
  }

  /**
   * Data and methods to provide to all widgets
   */
  const widgetProps = {
    name,
    items,
    startDate,
    loadItems,
    newItem,
    setItem,
    setItems,
    dirty,
    setDirty,
    applyItems,
    setStartDate,
    dateOnIndex,
    weekBack,
    weekForward,
    weekThisWeek,
    loadSettings,
    saveSettings,
    ...props,
  };
  return <ActiveComponent {...widgetProps} />;
});

export default Widget;
