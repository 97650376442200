const OPEN_ITEM_FORM_MODAL = 'OPEN_ITEM_FORM_MODAL';
const CLOSE_ITEM_FORM_MODAL = 'CLOSE_ITEM_FORM_MODAL';
const initialState = { isOpen: false, itemId: null, prefill: {} };

export function openItemFormModal(param, opts) {
  let result;
  if (!param) {
    result = { itemId: null, prefill: {} };
  } else if (param instanceof Object) {
    result = { itemId: null, prefill: param };
  } else {
    result = { itemId: param, prefill: {} };
  }
  return {
    type: OPEN_ITEM_FORM_MODAL,
    result: { ...result, isOpen: true, ...opts },
  };
}

export function closeItemFormModal() {
  return { type: CLOSE_ITEM_FORM_MODAL };
}

export default function reducer(state = initialState, action) {
  let result = state;
  if (action.type === OPEN_ITEM_FORM_MODAL) {
    result = action.result;
  } else if (action.type === CLOSE_ITEM_FORM_MODAL) {
    result = initialState;
  }
  return result;
}
