import React, { useState, useEffect } from 'react';
import { loadStripe } from '@stripe/stripe-js';
import { useParams } from 'react-router-dom';
import { Button } from '../../Home/components/Button';
import SendButton from '../SendButton';

const QUESTION_TEXT = 'Payment';

const PaymentQuestion = ({ isCurrent, onAnswer, data }) => {
  const [revisiting, setRevisiting] = useState(false);
  const { hash, stripeStatus } = useParams();

  const paid = stripeStatus === 'success' || data.family.stripe_id;

  useEffect(() => {
    if (isCurrent && paid) {
      onAnswer({ key: 'payment-attached', value: true });
    }
  }, [isCurrent, paid, onAnswer]);

  async function redirectToStripe() {
    const stripe = await loadStripe(process.env.REACT_APP_STRIPE_API_KEY);
    stripe.redirectToCheckout({
      lineItems: [
        {
          price:
            data.stripe_price_id ||
            process.env.REACT_APP_STRIPE_SUBSCRIPTION_KEY,
          quantity: 1,
        },
      ],
      mode: 'subscription',
      customerEmail: data.family.users[0].email,
      successUrl: `${process.env.REACT_APP_URL}/join-milo/${hash}/success`,
      cancelUrl: `${process.env.REACT_APP_URL}/join-milo/${hash}/cancel`,
    });
  }

  return (
    <div className="PaymentQuestion">
      {!(isCurrent || revisiting) && (
        <>
          <div className="answered-question">{QUESTION_TEXT}</div>
          <div className="response-wrapper">
            <div className="response" onClick={() => setRevisiting(true)}>
              ···· ···· ···· ····
              <box-icon name="check" />
            </div>
          </div>
        </>
      )}

      {(isCurrent || revisiting) && (
        <>
          <div className="title">
            {QUESTION_TEXT}
            {data.waitlist.referred_by_user_id &&
              ' - Your first month is covered!'}
          </div>
          {paid ? (
            <p>
              Payment accepted. If you need to change your payment method,
              please email us at {process.env.REACT_APP_ONBOARDING_EMAIL}.
            </p>
          ) : data.waitlist.referred_by_user_id ? (
            <p>
              Your friend has gifted you the first month of Milo. After that,
              our $15/mo family subscription gives everyone in your household
              unlimited collaboration access.
            </p>
          ) : (
            <p>
              Our family subscription of $15/mo gives everyone in your household
              unlimited collaboration access. And if it isn’t quite right for
              you? No problem, we’ll refund you your first month—easy-peasy.
            </p>
          )}
          {paid ? (
            <SendButton onClick={() => setRevisiting(false)} />
          ) : (
            <>
              <Button variant="large" onClick={redirectToStripe}>
                <box-icon type="solid" name="credit-card" /> Add Card
              </Button>
              <p style={{ marginTop: 24 }}>
                “$15 a month for lightening my mental load and creating a more
                equitable, effective household is so worth the price.”
                <br />
                –KT M
              </p>
            </>
          )}
        </>
      )}
    </div>
  );
};

export default PaymentQuestion;
