import React, { useState, useEffect } from 'react';
import { Button } from '../Button';
import { Option, MultiLineOption } from '../Dropdown';
import { callApi } from '../../../../utils/callApi';
import { ItemFieldWrapper } from './ItemFields';
import { COMPLETABLE } from '../../constants';

const ItemMenuLists = props => {
  const { item, onSelect } = props;
  const [lists, setLists] = useState([]);
  const [loading, setLoading] = useState(true);
  const [createListVisible, setCreateListVisible] = useState(false);
  const [newListName, setNewListName] = useState('');

  useEffect(() => {
    callApi('get', 'api/items/lists').then(res => {
      setLists(res.filter(list => list.id !== item.id));
      setLoading(false);
    });
  }, [item.id]);

  const parentId =
    item.parent_id ||
    (item.linked_from && item.linked_from[0]
      ? item.linked_from[0].id
      : undefined);

  if (loading) {
    return (
      <Option disabled={true}>
        <box-icon name="loader-circle" animation="spin" /> Loading…
      </Option>
    );
  }

  return (
    <>
      {!!lists.length &&
        lists.map(list => (
          <Option onClick={() => onSelect(list)} key={list.id || 'new-list'}>
            {list.id === parentId ? (
              <box-icon name="check" />
            ) : (
              <box-icon name="list-plus" />
            )}{' '}
            {list.title}
          </Option>
        ))}

      {!lists.length && <Option disabled={true}>No lists</Option>}

      {!createListVisible && (
        <Option onClick={() => setCreateListVisible(true)}>
          <box-icon name="plus" /> Create new list…
        </Option>
      )}

      {createListVisible && (
        <MultiLineOption>
          <ItemFieldWrapper>
            <input
              value={newListName}
              type="text"
              placeholder="Enter new list name…"
              onChange={evt => setNewListName(evt.target.value)}
            />
          </ItemFieldWrapper>

          <Button
            variant="small"
            onClick={() => {
              if (newListName.length) {
                setLists([
                  ...lists,
                  { title: newListName, status: COMPLETABLE },
                ]);
                setCreateListVisible(false);
                onSelect({ title: newListName, status: COMPLETABLE });
              }
            }}
            type="button"
          >
            Create
          </Button>
        </MultiLineOption>
      )}
    </>
  );
};

export default ItemMenuLists;
