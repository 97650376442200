import React, { useEffect } from 'react';
import classNames from 'classnames';
import './Dropdown.scss';

export const Dropdown = props => {
  useOutsideAlerter(props.wrapperRef, () => props.close());

  if (!props.isOpen) return null;

  return (
    <div
      className={classNames('Dropdown', props.className, {
        left: !props.side || props.side === 'left',
        right: props.side === 'right',
        center: props.side === 'center',
        unstyled: !!props.unstyled,
        'full-width': props.fullWidth,
      })}
      style={{
        top: props.top || (!props.bottom && 24),
        bottom: props.bottom,
      }}
    >
      {props.children}
    </div>
  );
};

export const Option = props => (
  <div
    className={classNames('Option', { disabled: props.disabled })}
    onClick={props.onClick}
  >
    {props.icon}
    {props.children}
  </div>
);

export const MultiLineOption = props => (
  <div
    className={classNames('MultiLineOption', { disabled: props.disabled })}
    onClick={props.onClick}
  >
    {props.children}
  </div>
);

// Custom hook that calls the given function on clicks outside of the passed ref
function useOutsideAlerter(ref, callback) {
  useEffect(() => {
    /**
     * Alert if clicked on outside of element
     */
    function handleClickOutside(event) {
      if (ref && ref.current && !ref.current.contains(event.target)) {
        callback();
      }
    }

    // Bind the event listener
    document.addEventListener('mousedown', handleClickOutside);
    return () => {
      // Unbind the event listener on clean up
      document.removeEventListener('mousedown', handleClickOutside);
    };
  }, [ref, callback]);
}
