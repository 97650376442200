import React from 'react';
import Widget from '../Widget';
import MealPlannerButton from './MealPlannerButton';
import MealPlannerEdit from './MealPlannerEdit';

export const WIDGET_NAME = 'meal-planner';

const modes = {
  edit: MealPlannerEdit,
  button: MealPlannerButton,
};

const MealPlannerWidget = props => {
  const { mode, open, close, thisWeeksSuggestions } = props;
  const { globalWeekStartDate, globalWeekBack, globalWeekForward } = props;
  if (!modes[mode]) return null;
  return (
    <Widget
      key={WIDGET_NAME}
      name={WIDGET_NAME}
      active={modes[mode]}
      open={open}
      onRequestClose={close}
      thisWeeksSuggestions={thisWeeksSuggestions}
      globalWeekStartDate={globalWeekStartDate}
      globalWeekBack={globalWeekBack}
      globalWeekForward={globalWeekForward}
    />
  );
};

export default { name: WIDGET_NAME, widget: MealPlannerWidget };
