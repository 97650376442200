import React from 'react';
import { Button } from '../Home/components/Button';

import './SendButton.scss';

const SendButton = ({ onClick, disabled }) => {
  return (
    <div className="SendButton">
      <Button disabled={disabled} onClick={onClick} variant="large">
        Next
      </Button>
    </div>
  );
};

export default SendButton;
