import * as analytics from '../utils/analytics';
import { mvSocket } from '../components/Home/Home';

const AUTH_STORE_LOGIN = 'AUTH_STORE_LOGIN';
const AUTH_RESET = 'AUTH_RESET';
const UPDATE_USER_METADATA = 'UPDATE_USER_METADATA';
const ADD_USER = 'ADD_USER';
const SAVE_FAMILY_SETTINGS = 'SAVE_FAMILY_SETTINGS';

const initialState = {
  loggedIn: false,
  email: undefined,
  access_token: undefined,
  refresh_token: undefined,
};

export function storeLogin(authData) {
  if (authData.access_token) {
    localStorage.setItem('access_token', authData.access_token);
    localStorage.setItem('refresh_token', authData.refresh_token);
  }
  const user =
    authData.user || authData.users.find(user => user.id === authData.id);
  const data = {
    ...authData,
    user,
    loggedIn: true,
    access_token: undefined,
    refresh_token: undefined,
  };
  return { type: AUTH_STORE_LOGIN, data };
}

export function updateUserMetadata(data) {
  return { type: UPDATE_USER_METADATA, data };
}

export function saveFamilySettings(data) {
  return { type: SAVE_FAMILY_SETTINGS, data };
}

export function clearPasswordRequired() {
  const data = {
    password_required: false,
  };
  return { type: AUTH_STORE_LOGIN, data };
}

export function getAccessToken() {
  return localStorage.getItem('access_token');
}

export function getRefreshToken() {
  return localStorage.getItem('refresh_token');
}

export function reset() {
  localStorage.removeItem('access_token');
  localStorage.removeItem('refresh_token');

  analytics.track('logout');
  mvSocket && mvSocket.disconnect();

  return { type: AUTH_RESET };
}

export function addUser(data) {
  return { type: ADD_USER, data };
}

export default function reducer(state = initialState, action) {
  let result = state;
  if (action.type === AUTH_STORE_LOGIN) {
    result = { ...state, ...action.data };
  } else if (action.type === UPDATE_USER_METADATA) {
    result = {
      ...state,
      user: {
        ...state.user,
        metadata: { ...state.user.metadata, ...action.data },
      },
    };
  } else if (action.type === AUTH_RESET) {
    result = initialState;
  } else if (action.type === ADD_USER) {
    result = {
      ...state,
      users: [...state.users, action.data],
    };
  } else if (action.type === SAVE_FAMILY_SETTINGS) {
    result = {
      ...state,
      family: action.data.name,
      family_emoji: action.data.emoji,
      family_metadata: {
        ...state.family_metadata,
        background_color: action.data.background_color,
      },
    };
  }
  return result;
}
