import classNames from 'classnames';
import React from 'react';
import weekName from '../../../utils/week-name';
import { Button } from '../components/Button';
import { getDateRangeString } from '../utils';
import './WeekSelect.scss';

const WeekSelect = props => {
  const {
    weekStartDate,
    weekToday,
    weekBack,
    weekForward,
    hideTodayButton,
    showWeekName,
    showDay,
    showBothMonths,
    width,
    showYear = true,
  } = props;
  const name = showWeekName && weekName(weekStartDate);
  return (
    <div
      className={classNames('WeekSelect', {
        'with-today': !hideTodayButton,
      })}
    >
      <div className="date-select" style={{ width }}>
        <Button variant="secondary" onClick={() => weekBack()}>
          <box-icon name="chevron-left" />
        </Button>
        <div className="date-string">
          {name}
          {name && <br />}
          {getDateRangeString(weekStartDate, {
            showYear,
            showDay,
            showBothMonths,
          })}
        </div>
        <Button variant="secondary" onClick={() => weekForward()}>
          <box-icon name="chevron-right" />
        </Button>
      </div>
      {!hideTodayButton && (
        <Button
          className="today-button"
          variant="secondary"
          onClick={async () => {
            await weekToday();
          }}
        >
          Today
        </Button>
      )}
    </div>
  );
};

export default WeekSelect;
