import 'boxicons';
import classNames from 'classnames';
import idx from 'idx';
import React, { useState, useEffect } from 'react';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import { open as setOpenModal } from '../../redux/modal';
import Account from '../Account/Account';
import { Link } from 'react-router-dom';
import Search from '../Home/Search/Search';
import { Button } from '../Home/components/Button';
import { TYPE_NEW } from '../Home/constants';
import './Nav.scss';

const PAGES = [
  { id: 'dashboard', name: 'Dash' },
  { id: 'week', name: 'Week' },
  { id: 'todo', name: 'To Do' },
  { id: 'files', name: 'Files' },
  { id: 'library', name: 'Library' },
];
const DASH2_PAGES = ['dashboard', 'week', 'library'];

const Nav = props => {
  const { location, auth, setOpenModal, items } = props;
  const pathParts = idx(location, _ => _.pathname.split('/'));
  const active = pathParts && pathParts[2];
  const product = pathParts && pathParts[1];
  const [showAccountMenu, setShowAccountMenu] = useState(false);
  const triageItems = items.filter(item => item.type === TYPE_NEW);

  useEffect(() => {
    const { hostname } = window.location;
    if (
      hostname &&
      !hostname.toLowerCase().includes('joinmilo') &&
      !hostname.toLowerCase().includes('localhost')
    ) {
      window.location = process.env.REACT_APP_URL;
    }
  }, []);

  // Figure out which pages to show links for
  const dash2User =
    auth.family_metadata && auth.family_metadata.signup_version === '2';
  const pages = dash2User
    ? PAGES.filter(page => DASH2_PAGES.includes(page.id))
    : PAGES;

  return (
    <div
      className={classNames('Nav', {
        'account-menu-open': showAccountMenu,
        'admin-user': auth && auth.name === 'bot',
      })}
    >
      <div className="left-group">
        <Link to="/" className="logo">
          <img src="/logo192.png" alt="milo logo" />
          <span>milo</span>
        </Link>

        <div className="pages">
          {pages.map(({ id, name }, idx) => (
            <Link
              key={id}
              className={classNames('nav-link', {
                active: active === id || (!active && !idx),
              })}
              to={`/home/${id}`}
            >
              {name}
            </Link>
          ))}
        </div>
      </div>

      <div className="right-group">
        <Search className={active !== 'dashboard' && 'padded-right'} />
        <div className="triage-button">
          <Button variant="clear" onClick={() => setOpenModal('triage')}>
            <box-icon name="paper-plane" />
            {!!triageItems.length && (
              <div className="badge">{triageItems.length}</div>
            )}
          </Button>
        </div>
        <Account
          product={product}
          showMenu={showAccountMenu}
          setShowMenu={setShowAccountMenu}
        />
      </div>
    </div>
  );
};

export default connect(({ auth, items }) => ({ auth, items }), {
  setOpenModal,
})(withRouter(Nav));
