import { callApi } from '../../utils/callApi';

export default function openAttachmentUrl(item, fileId) {
  const windowReference = window.open();
  callApi('GET', `api/items/${item.id || 'null'}/attachments/${fileId}`).then(
    url => {
      windowReference.location = url;
    },
  );
}

export function getAttachmentUrl(item, fileId) {
  return callApi('GET', `api/items/${item.id || 'null'}/attachments/${fileId}`);
}

export async function getEmailUrl(item) {
  const name = 'email.html';
  const attachments = item && item.attachments;
  if (
    attachments &&
    attachments.length > 0 &&
    attachments.find(atch => atch.name === name)
  ) {
    const attachment = attachments.find(atch => atch.name === name);
    return await getAttachmentUrl(item, attachment.id);
  }
  return null;
}
