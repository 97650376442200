import React, { useState, useEffect } from 'react';
import { connect } from 'react-redux';
import { updateUserMetadata } from '../../../redux/auth';
import onboardingStep from '../../../redux/onboardingStep';
import { track } from '../../../utils/analytics';
import { callApi } from '../../../utils/callApi';
import {
  MiloModal,
  MiloModalContent,
  MiloModalButtons,
} from '../components/MiloModal';
import { Button } from '../components/Button';
import AddCollaboratorForm from '../../Account/AddCollaboratorForm';
import { open as setOpenModal } from '../../../redux/modal';
import { storeLogin } from '../../../redux/auth';

import './OnboardingModal.scss';

const OnboardingModal = props => {
  const [showOnboarding, setShowOnboarding] = useState(true);
  const {
    onboardingStep,
    setOnboardingStep,
    auth: { has_family_calendar: hasFamilyCalendar },
  } = props;

  useEffect(() => {
    if (hasFamilyCalendar) {
      setOnboardingStep(2);
    }
  }, [hasFamilyCalendar, setOnboardingStep]);

  const StepComponent = STEPS[onboardingStep];

  return (
    <MiloModal
      className="OnboardingModal"
      isOpen={showOnboarding}
      size="narrow"
      title="Welcome to Milo"
      fullWidthButtons={true}
    >
      <StepComponent setShowOnboarding={setShowOnboarding} {...props} />
    </MiloModal>
  );
};

const Step = ({
  name,
  back,
  hideBack,
  next,
  nextText,
  nextDisabled,
  hideSkip,
  stepText,
  children,
}) => (
  <>
    <MiloModalContent>
      <div className="header">
        <h2>{name}</h2>
        {stepText && <p style={{ opacity: 0.75 }}>{stepText}</p>}
      </div>

      {children}
    </MiloModalContent>
    <MiloModalButtons>
      <div>
        {!hideBack && (
          <Button variant="secondary" onClick={back}>
            Back
          </Button>
        )}
      </div>
      <div>
        {!hideSkip && (
          <Button onClick={next} variant="clear">
            Skip
          </Button>
        )}
        <Button disabled={nextDisabled} onClick={next}>
          {nextText || 'Next'}
        </Button>
      </div>
    </MiloModalButtons>
  </>
);

const IntroStep = ({ incrementOnboardingStep }) => (
  <Step
    name="Customize Milo for your family"
    next={incrementOnboardingStep}
    hideSkip={true}
    hideBack={true}
    nextText="Let’s Go"
  >
    <p>
      Our goal is to help you win the week, without having to remind or nag your
      crew.
    </p>

    <p>
      Enter the key things your family needs to do each day, and let Milo do the
      rest. We’ll send a digest email to your family every morning so everyone
      knows who’s on tap for what.
    </p>
  </Step>
);

const ConnectStep = ({
  decrementOnboardingStep,
  auth,
  setShowOnboarding,
  storeLogin,
  auth: { users, has_family_calendar: hasFamilyCalendar },
}) => {
  return (
    <Step
      name="Connect your family calendar"
      back={decrementOnboardingStep}
      next={() => {
        callApi('PUT', 'api/onboarding');
        setShowOnboarding(false);
        storeLogin({
          ...auth,
          family_metadata: {
            ...auth.family_metadata,
            onboarding_complete: true,
          },
        });
      }}
      nextDisabled={false}
      nextText="Finish"
      hideSkip={true}
      stepText="Step 2 of 2"
    >
      <p>
        Already use a family calendar? Milo integrates with Google Calendar.
        Anything added to the family calendar will be added to Milo, and vice
        versa.
      </p>

      {!hasFamilyCalendar && (
        <p style={{ display: 'flex', justifyContent: 'center' }}>
          <Button
            variant="large"
            onClick={async () => {
              const res = await callApi('GET', 'api/gcal/auth');
              if (res.auth_url) {
                window.location.href = res.auth_url;
              }
            }}
          >
            Connect Family Calendar
          </Button>
        </p>
      )}
      {hasFamilyCalendar && <div className="callout">Connected!</div>}

      <p style={{ fontWeight: 'bold' }}>
        <span role="img" aria-label="index finger pointing up emoji">
          ☝🏽
        </span>
        Heads up!
      </p>

      <p>
        When someone in your family is assigned something in Milo, they’ll get a
        calendar invite, no Google Calendar connection needed. These invites
        will go to:
      </p>

      <ul>
        {users
          .filter(user => user.name !== 'bot')
          .sort((a, b) => a.id - b.id)
          .map(user => (
            <li key={user.id}>
              {user.name} ({user.email})
            </li>
          ))}
      </ul>
    </Step>
  );
};

const CollaboratorStep = ({
  auth,
  decrementOnboardingStep,
  incrementOnboardingStep,
}) => {
  const users = auth.users
    .filter(user => user.name !== 'bot')
    .sort((a, b) => a.id - b.id);

  return (
    <Step
      name="Add your collaborators"
      back={decrementOnboardingStep}
      next={incrementOnboardingStep}
      nextDisabled={users.length <= 1}
      stepText="Step 1 of 2"
    >
      <p>Add your partner and caregivers to your Milo dash.</p>
      <p style={{ fontWeight: 'bold' }}>{auth.family}</p>
      <ul>
        {users.map(user => (
          <li key={user.id}>{user.name}</li>
        ))}
      </ul>
      <AddCollaboratorForm
        hideTitle={true}
        afterSubmit={() => track('onboarding:collaborator:add')}
      />
    </Step>
  );
};

const STEPS = [IntroStep, CollaboratorStep, ConnectStep];

export default connect(
  ({ auth, onboardingStep }) => ({ auth, onboardingStep }),
  {
    updateUserMetadata,
    setOpenModal,
    incrementOnboardingStep: onboardingStep.increment,
    decrementOnboardingStep: onboardingStep.decrement,
    setOnboardingStep: onboardingStep.set,
    storeLogin,
  },
)(OnboardingModal);
