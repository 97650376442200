import 'boxicons';
import moment from 'moment';
import React, { useEffect, useState } from 'react';
import { connect } from 'react-redux';
import { Link } from 'react-router-dom';
import { callApi } from '../../../utils/callApi';
import { Card, CardBody } from '../components/Card';
import { H2 } from '../components/Type';
import { Avatar } from '../components/Avatar';
import { DATE_FORMAT } from '../constants';
import './RecentHistory.scss';

const LOOKUP = {
  create_family: 'created this family on Milo',
  add_collaborator: 'added collaborator',
  item_created: 'created',
  item_updated: 'edited',
  item_completed: 'completed',
  item_deleted: 'deleted',
  sms_triage: 'texted in',
  sms_at_add: 'texted in',
  edit_settings: 'customized Milo',
};
const NUM_EVENTS = 20;

const RecentHistory = ({ auth, refresh }) => {
  const [recentHistory, setRecentHistory] = useState([]);

  useEffect(() => {
    let date = '';
    callApi('GET', 'api/events').then(results => {
      const sorted = results.sort((a, b) => b.id - a.id);
      const historyWithUsers = sorted.map(result => {
        const title = LOOKUP[result.type];
        if (!title) {
          return undefined;
        }

        const user = auth.users.find(user => user.id === result.user_id);
        const userName = user && user.name;
        if (userName === 'bot') {
          return undefined;
        }

        let newDate;
        const formattedDate = moment(result.created_at).format(DATE_FORMAT);
        if (formattedDate.localeCompare(date) !== 0) {
          newDate = formattedDate;
          date = formattedDate;
        }

        const collaborator =
          result.metadata &&
          result.metadata &&
          auth.users.find(user => user.id === result.metadata.new_user_id);
        const collaboratorName = collaborator && collaborator.name;

        return {
          ...result,
          newDate,
          title,
          userName,
          collaboratorName,
        };
      });
      setRecentHistory(
        historyWithUsers.filter(event => !!event).slice(0, NUM_EVENTS),
      );
    });
  }, [auth.users, refresh]);

  return (
    <Card className="RecentHistory">
      <CardBody>
        <H2>Recent activity</H2>
      </CardBody>

      <CardBody border="top">
        {!recentHistory.length && 'No recent family activity'}
        {recentHistory.map(event => (
          <React.Fragment key={event.id}>
            {event.newDate && (
              <div className="date">
                {moment(event.newDate).format('ddd MMM D')}
              </div>
            )}
            <div className="event">
              <Avatar id={event.user_id} className="small" />
              <div>
                {event.title}{' '}
                {event.item_id &&
                  (event.item_deleted ? (
                    event.item_title
                  ) : (
                    <Link to={`/home/dashboard?item=${event.item_id}`}>
                      {event.item_title}
                    </Link>
                  ))}{' '}
                {event.collaboratorName && `${event.collaboratorName} `}at{' '}
                {moment(event.created_at).format('h:mma')}
              </div>
            </div>
          </React.Fragment>
        ))}
      </CardBody>
    </Card>
  );
};

export default connect(state => ({
  auth: state.auth,
  itemFormModal: state.itemFormModal,
}))(RecentHistory);
