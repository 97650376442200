import 'boxicons';
import React, { useState } from 'react';
import marked from 'marked';
import DOMPurify from 'dompurify';
import { Card, CardBody, CardImg, CardFooterLink } from '../../components/Card';
import { track } from '../../../../utils/analytics';
import { MiloModal, MiloModalContent } from '../../components/MiloModal';

const WisdomView = props => {
  const [showContent, setShowContent] = useState();
  const { thisWeeksSuggestions } = props;
  const article = thisWeeksSuggestions.find(
    sug => sug.suggestion_type === 'wisdom',
  );

  if (!article) {
    return null;
  }
  return (
    <Card className="WisdomView">
      <CardBody>
        <h2>Milo Library</h2>
      </CardBody>
      <CardBody border="both">
        <Card>
          {!article.content && (
            <a
              href={article.url}
              target="_blank"
              rel="noopener noreferrer"
              onClick={() =>
                track('library:dash-card:click', { id: article.id })
              }
            >
              <CardImg src={article.image_url} />
              <CardBody>
                <h3>{article.title}</h3>
                <p>{article.summary}</p>
              </CardBody>
            </a>
          )}
          {article.content && (
            <div
              onClick={() => {
                track('library:dash-card:click', { id: article.id });
                setShowContent(article);
              }}
            >
              <CardImg src={article.image_url} />
              <CardBody>
                <h3>{article.title}</h3>
                <p>{article.summary}</p>
              </CardBody>
            </div>
          )}
        </Card>
      </CardBody>
      <CardFooterLink to="/home/library">
        Go to full library
        <box-icon name="right-arrow-alt" />
      </CardFooterLink>
      {showContent && (
        <MiloModal
          isOpen={true}
          onRequestClose={() => setShowContent()}
          title={showContent.title}
        >
          <MiloModalContent>
            <div
              dangerouslySetInnerHTML={{
                __html: DOMPurify.sanitize(marked(showContent.content)),
              }}
            />
          </MiloModalContent>
        </MiloModal>
      )}
    </Card>
  );
};

export default WisdomView;
