import React, { useState, useRef } from 'react';
import classNames from 'classnames';
import { Dropdown, Option } from './Dropdown';
import { Button } from './Button';

const ButtonDropdown = ({
  className,
  children,
  side,
  variant,
  options,
  top,
  fullWidth,
}) => {
  const [dropdownOpen, setDropdownOpen] = useState(false);
  const ref = useRef(null);

  return (
    <div className={classNames('ButtonDropdown', className)} ref={ref}>
      <Button onClick={() => setDropdownOpen(!dropdownOpen)} variant={variant}>
        {children} <box-icon style={{ height: 21 }} name="chevron-down" />
      </Button>
      <Dropdown
        isOpen={dropdownOpen}
        top={top || 56}
        close={() => setDropdownOpen(false)}
        wrapperRef={ref}
        side={side}
        fullWidth={fullWidth}
      >
        {options.map(({ name, onClick, disabled }) => (
          <Option
            key={name}
            onClick={() => {
              if (!disabled) {
                onClick();
                setDropdownOpen(false);
              }
            }}
            disabled={disabled}
          >
            {name}
          </Option>
        ))}
      </Dropdown>
    </div>
  );
};

export default ButtonDropdown;
