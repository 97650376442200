import React from 'react';
import { Dropdown } from '../components/Dropdown';
import ItemMenuLists from '../components/ItemForm/ItemMenuLists';

const TriageListMenu = props => {
  const { wrapperRef, isOpen, close, formData, onSelect } = props;
  return (
    <Dropdown
      isOpen={isOpen}
      close={close}
      wrapperRef={wrapperRef}
      side={'right'}
      bottom={75}
    >
      <ItemMenuLists item={formData} onSelect={onSelect} />
    </Dropdown>
  );
};

export default TriageListMenu;
