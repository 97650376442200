import Intro from './Intro';
import PasswordQuestion from './PasswordQuestion';
import ToSQuestion from './ToSQuestion';
import EmailPhoneQuestion from './EmailPhoneQuestion';
import FamilyQuestion from './FamilyQuestion';
import KidsQuestion from './KidsQuestion';
import CorporateQuestion from './CorporateQuestion';
import PaymentQuestion from './PaymentQuestion';
import Complete from './Complete';
import GroupComplete from './GroupComplete';

const questions = [
  Intro,
  EmailPhoneQuestion,
  FamilyQuestion,
  KidsQuestion,
  PasswordQuestion,
  ToSQuestion,
];

const groupQuestions = [
  EmailPhoneQuestion,
  FamilyQuestion,
  PasswordQuestion,
  ToSQuestion,
];

export {
  questions,
  groupQuestions,
  CorporateQuestion,
  PaymentQuestion,
  Complete,
  GroupComplete,
};
