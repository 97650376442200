import MealPlannerWidget from './MealPlanner';
import PlannerWidget from './Planner';
import TriageWidget from './Triage';
import WisdomWidget from './Wisdom';
import TopThreeWidget from './TopThree';

/**
 * Widgets - The current set of widgets configured
 *           for the logged in user
 */

const widgets = [
  MealPlannerWidget,
  PlannerWidget,
  TriageWidget,
  WisdomWidget,
  TopThreeWidget,
];

const Widgets = props => {
  const { mode, preferences = {}, open, close } = props;

  const widgetsToShow = widgets
    .filter(widget => preferences['show-' + widget.name] !== false)
    .map(widget =>
      widget.widget({
        ...props,
        name: widget.name,
        mode,
        open: open === widget.name,
        close,
      }),
    );
  return widgetsToShow;
};

export default Widgets;
