import 'boxicons';
import { getEmojiDataFromNative } from 'emoji-mart';
import emojiData from 'emoji-mart/data/all.json';
import moment from 'moment';
import React from 'react';
import { connect } from 'react-redux';
import { openItemFormModal } from '../../../redux/itemFormModal';
import { Card, CardBody } from '../components/Card';
import { Level } from '../components/Level';
import { H2, H3 } from '../components/Type';
import { DATE_FORMAT } from '../constants';
import { track } from '../../../utils/analytics';
import './ThisWeek.scss';

const ThisWeek = props => {
  const { openItemFormModal, thisWeeksSuggestions } = props;
  const suggestions = thisWeeksSuggestions.filter(
    suggestion => suggestion.suggestion_type === 'this-week',
  );
  if (!suggestions || !suggestions.length) {
    return null;
  }
  return (
    <Card>
      <CardBody border="bottom">
        <H2>This Week on Milo</H2>
      </CardBody>

      {suggestions.map((suggestion, index) => (
        <CardBody key={index}>
          <Level
            variant="media-object"
            onClick={() => {
              track('heads-up:dash-card:click', { id: suggestion.id });
              openItemFormModal(
                {
                  date: moment().format(DATE_FORMAT),
                  title: suggestion.title,
                  description: suggestion.description,
                  emoji:
                    suggestion.emoji &&
                    getEmojiDataFromNative(
                      suggestion.emoji,
                      'apple',
                      emojiData,
                    ),
                  data: { suggestionId: suggestion.id },
                },
                { hideSidePanel: true },
              );
            }}
          >
            <img alt="suggestion" src={suggestion.image_url} />
            <div className="media-object-text">
              <H3>
                {suggestion.emoji} {suggestion.title}
              </H3>
              <p>{suggestion.description}</p>
            </div>
          </Level>
        </CardBody>
      ))}
    </Card>
  );
};

export default connect(
  state => ({ auth: state.auth, itemFormModal: state.itemFormModal }),
  { openItemFormModal },
)(ThisWeek);
