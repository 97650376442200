import React from 'react';
import { callApi } from '../../../utils/callApi';
import './Week.scss';

class WeekExport extends React.Component {
  async componentDidMount() {
    const { weekStartDate } = this.props;
    const copy = await callApi('GET', `api/pdf/sunday/${weekStartDate}`);
    if (copy) {
      const file = new Blob([copy], { type: 'application/pdf' });
      const fileURL = URL.createObjectURL(file);
      window.location = fileURL;
    }
  }

  render() {
    return null;
  }
}

export default WeekExport;
