import React from 'react';
import Modal from 'react-modal';
import XIcon from '../Icons/BigX.png';
import './AccountModal.scss';

Modal.setAppElement('#root');

const AccountModal = props => {
  const { closeForm, children, title, open, className } = props;
  return (
    <Modal
      className={`AccountModal && ${className}`}
      overlayClassName="AccountModal__overlay"
      isOpen={open}
      onRequestClose={closeForm}
      closeTimeoutMS={100}
    >
      <div className="title">
        <div>{title}</div>
        {closeForm && (
          <div>
            <img className="x" src={XIcon} alt="X" onClick={closeForm} />
          </div>
        )}
      </div>
      {children}
    </Modal>
  );
};

export default AccountModal;
