import React from 'react';
import Modal from 'react-modal';
import './ConfirmModal.scss';

const ConfirmModal = props => {
  const { isOpen, setOpen, title, helptext, children } = props;
  return (
    <Modal
      isOpen={isOpen}
      onRequestClose={() => setOpen(false)}
      closeTimeoutMS={100}
      className="ConfirmModal"
      overlayClassName="ConfirmModal__Overlay"
    >
      <div>
        <h2>{title}</h2>
        {helptext &&
          (typeof helptext === 'string' ? <p>{helptext}</p> : helptext)}
      </div>

      <div className="buttons">{children}</div>
    </Modal>
  );
};

export default ConfirmModal;
