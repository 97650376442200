import React, { useEffect, useState } from 'react';
import marked from 'marked';
import DOMPurify from 'dompurify';
import { Card, CardBody, CardImg } from '../components/Card';
import { callApi } from '../../../utils/callApi';
import { track } from '../../../utils/analytics';
import './MiloLibrary.scss';
import { MiloModal, MiloModalContent } from '../components/MiloModal';

const MiloLibrary = () => {
  const [articles, setArticles] = useState([]);
  const [showContent, setShowContent] = useState();

  useEffect(() => {
    callApi('GET', `api/milo-library`).then(result => setArticles(result));
  }, []);

  useEffect(() => {
    track('library:page:load');
  }, []);

  const topics = articles.reduce((acc, article) => {
    if (!acc[article.topic]) {
      acc[article.topic] = [];
    }
    return { ...acc, [article.topic]: [...acc[article.topic], article] };
  }, {});

  return (
    <div className="MiloLibrary">
      <h2>Welcome to the Milo Library</h2>
      <p>
        Here is our growing library of the best practices that help us all
        benefit from the wisdom of the village.
      </p>
      <p>
        Science-based, broken down, and shared with options to recognize all the
        ways we parent.
      </p>

      {Object.entries(topics).map(([topic, articles]) => (
        <div key={topic}>
          <h1>{topic}</h1>
          <div className="articles">
            {articles.map(article => (
              <Card key={article.id}>
                {!article.content && (
                  <a
                    href={article.content_url}
                    target="_blank"
                    rel="noopener noreferrer"
                    onClick={() =>
                      track('library:card:click', { id: article.id })
                    }
                  >
                    <CardImg src={article.image_url} />
                    <CardBody>
                      <h3>{article.title}</h3>
                      <p>{article.summary}</p>
                    </CardBody>
                  </a>
                )}
                {article.content && (
                  <div
                    onClick={() => {
                      track('library:card:click', { id: article.id });
                      setShowContent(article);
                    }}
                  >
                    <CardImg src={article.image_url} />
                    <CardBody>
                      <h3>{article.title}</h3>
                      <p>{article.summary}</p>
                    </CardBody>
                  </div>
                )}
              </Card>
            ))}
          </div>
        </div>
      ))}
      {showContent && (
        <MiloModal
          isOpen={true}
          onRequestClose={() => setShowContent()}
          title={showContent.title}
        >
          <MiloModalContent>
            <div
              dangerouslySetInnerHTML={{
                __html: DOMPurify.sanitize(marked(showContent.content)),
              }}
            />
          </MiloModalContent>
        </MiloModal>
      )}
    </div>
  );
};

export default MiloLibrary;
