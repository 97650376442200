import React from 'react';
import { connect } from 'react-redux';
import { open as openModal } from '../../../../redux/modal';
import { TYPE_NEW } from '../../constants';
import './TriageButton.scss';

const TriageButton = props => {
  const { items } = props;
  const triageItems = items.filter(item => item.type === TYPE_NEW);

  return (
    <button
      className="WidgetButton TriageButton"
      type="button"
      onClick={() => props.openModal('triage')}
    >
      <box-icon name="paper-plane" />
      <div>Triage</div>
      {triageItems.length > 0 && (
        <div className="badge">{triageItems.length}</div>
      )}
      <box-icon name="chevron-right" />
    </button>
  );
};

export default connect(
  state => ({
    items: state.items,
  }),
  {
    openModal,
  },
)(TriageButton);
