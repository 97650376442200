import classNames from 'classnames';
import React from 'react';
import ReactModal from 'react-modal';
import { Button } from './Button';
import './MiloModal.scss';

export const MiloModal = props => {
  return (
    <ReactModal
      className={classNames('MiloModal', props.className, props.size, {
        'full-width-buttons': props.fullWidthButtons,
      })}
      overlayClassName={classNames('MiloModalOverlay', props.overlayClassName)}
      isOpen={props.isOpen}
      onRequestClose={props.onRequestClose}
    >
      {props.title && (
        <div className="title-bar">
          {!props.hideBackButton && (
            <Button variant="clear" className="show-mobile">
              <box-icon
                onClick={props.onRequestBack || props.onRequestClose}
                name="left-arrow-alt"
              />
            </Button>
          )}
          <h1>{props.title}</h1>
          {props.onRequestClose && (
            <Button variant="clear" className="hide-mobile">
              <box-icon onClick={props.onRequestClose} name="x" />
            </Button>
          )}
        </div>
      )}
      {props.children}
    </ReactModal>
  );
};

export const MiloModalControls = props => (
  <div className="MiloModalControls">{props.children}</div>
);

export const MiloModalContent = props => (
  <div className="MiloModalContent">{props.children}</div>
);

export const MiloModalButtons = props => (
  <div className="MiloModalButtons">{props.children}</div>
);
