import React from 'react';
import { connect } from 'react-redux';
import { storeLogin } from '../../redux/auth';
import { FontFamilyCooper, White } from '../../styles';
import * as analytics from '../../utils/analytics';
import { callApi } from '../../utils/callApi';
import { Button } from '../Home/components/Button';
import { LogoPanel, Panel, SignupPanel, TextInput } from './Components';
import './Login.scss';

class Login extends React.Component {
  state = {
    loading: false,
    forgotPassword: false,
    errors: undefined,
  };

  onLoginText(e) {
    this.setState({ email: e.target.value });
  }
  onPasswordText(e) {
    this.setState({ password: e.target.value });
  }

  async storageListener(event) {
    const { auth } = this.props;
    if (event.key === 'access_token') {
      let newAuth;
      try {
        newAuth = await callApi('POST', 'login');
      } catch (error) {
        // Could not log in
      }
      if (!newAuth || newAuth.id !== auth.id) {
        window.location.reload();
      }
    }
  }

  async handleLoginClick(e) {
    e.preventDefault();
    const { email, password } = this.state;
    if (!email || !email.length || !password || !password.length) {
      this.setState({ errors: ['Please enter email and password'] });
      return;
    }
    try {
      analytics.track('Login attempt', {
        user: email.replace(/@/g, '-at-'),
      });
      const body = { email, password };
      this.setState({ loading: true, errors: [] });
      const auth = await callApi('POST', 'login', undefined, body);
      if (auth && auth.access_token) {
        this.setState({ loading: false });
        this.props.handleStoreLogin(auth);

        window.addEventListener('storage', async event =>
          this.storageListener(event),
        );

        analytics.track('Login success', {
          userId: auth.id,
          method: 'email/password',
        });
        analytics.identify(auth.id, {
          familyId: auth.family_id,
          familyName: auth.family,
          name: auth.name,
          method: 'form',
        });
      } else {
        const errors = ['Login failed, please check email and password.'];
        this.setState({ loading: false, errors });
      }
    } catch (error) {
      let errors = [];
      if (error.response.status === 404) {
        errors = ['Login failed, please check email and password.'];
      } else if (error.response.status === 400) {
        errors = [error.response.data];
      }
      this.setState({ loading: false, errors });
    }
  }

  handleForgotPasswordClick(e) {
    e.preventDefault();
    const { email } = this.state;
    if (!email || !email.length) {
      this.setState({ errors: ['Please enter email'] });
      return;
    }

    callApi('POST', 'forgot-password', undefined, { email });
    this.setState({ forgotPassword: false });
  }

  render() {
    const { loading, errors, forgotPassword } = this.state;
    const fetchSupported = typeof fetch === 'function';
    return (
      <div className="Login">
        <LogoPanel>
          <img
            src={'/milo-logo_horizontal.png'}
            alt="logo"
            width="218px"
            height="96px"
          />
        </LogoPanel>
        <SignupPanel>
          <a
            style={{
              color: White,
              textDecoration: 'none',
              fontFamily: FontFamilyCooper,
            }}
            href="https://joinmilo.com"
          >
            Sign Up &gt;
          </a>
        </SignupPanel>
        <Panel>
          {!fetchSupported && (
            <div>This browser is not supported. Please update.</div>
          )}
          {fetchSupported && (
            <>
              <h1 style={{ color: White, paddingBottom: 30 }}>
                {forgotPassword ? 'Reset Password' : 'Log In'}
              </h1>
              <form
                onSubmit={e =>
                  forgotPassword
                    ? this.handleForgotPasswordClick(e)
                    : this.handleLoginClick(e)
                }
              >
                <TextInput
                  autoFocus
                  disabled={loading}
                  onChange={e => this.onLoginText(e)}
                  type="email"
                  placeholder="Your email address"
                ></TextInput>
                {!forgotPassword && (
                  <TextInput
                    disabled={loading}
                    type="password"
                    placeholder="Your password"
                    onChange={e => this.onPasswordText(e)}
                  ></TextInput>
                )}
                {errors && (
                  <div style={{ color: White }}>
                    {errors.map(error => (
                      <div key={error}>{error}</div>
                    ))}
                  </div>
                )}

                {!forgotPassword && (
                  <>
                    <div
                      style={{
                        textAlign: 'center',
                        marginTop: 20,
                        width: '100%',
                        display: 'flex',
                        justifyContent: 'space-between',
                      }}
                    >
                      <div
                        className="login-link"
                        onClick={() =>
                          this.setState({
                            forgotPassword: true,
                            errors: undefined,
                          })
                        }
                        type="button"
                      >
                        Forgot password
                      </div>

                      <Button
                        className="login-button"
                        type="submit"
                        disabled={loading}
                      >
                        {loading ? 'Loading...' : 'Log In'}
                      </Button>
                    </div>
                  </>
                )}
                {forgotPassword && (
                  <>
                    <div
                      style={{
                        textAlign: 'center',
                        marginTop: 20,
                      }}
                    >
                      <div style={{ marginBottom: 20, textAlign: 'left' }}>
                        Enter your email address above and click send to reset
                        your password. An email will be sent with a link to
                        login and set your password.
                      </div>
                      <div
                        style={{
                          display: 'flex',
                          justifyContent: 'space-between',
                        }}
                      >
                        <div
                          className="login-link"
                          onClick={() =>
                            this.setState({
                              forgotPassword: false,
                              errors: undefined,
                            })
                          }
                          type="button"
                        >
                          Cancel
                        </div>
                        <Button
                          className="login-button"
                          disabled={loading}
                          onClick={e => this.handleForgotPasswordClick(e)}
                        >
                          {loading ? 'Loading...' : 'Send'}
                        </Button>
                      </div>
                    </div>
                  </>
                )}
              </form>
            </>
          )}
        </Panel>
      </div>
    );
  }
}

export default connect(
  state => ({
    auth: state.auth,
  }),
  {
    handleStoreLogin: storeLogin,
  },
)(Login);
