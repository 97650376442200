import React from 'react';

import './Toolbar.scss';

const Toolbar = props => {
  return (
    <div className="Toolbar">
      <div className="sticky">{props.children}</div>
    </div>
  );
};

export default Toolbar;
