export function plannerItemsToTemplate(plannerItems) {
  const template = [];

  let counter = 0;
  plannerItems.forEach(item => {
    const templateLine = {
      block_number: counter++,
      title: item.title,
      time: item.time,
      end_time: item.endTime,
    };

    if (item.daily) {
      templateLine.block_type = 'd';
      item.days.forEach(
        (day, index) =>
          day.active &&
          template.push({
            ...templateLine,
            days: [index],
            description: day.description,
            assigned_to_id:
              day.assigned_to_id > 0 ? day.assigned_to_id : undefined,
          }),
      );
    } else {
      templateLine.block_type = 'g';
      templateLine.description = item.description;
      templateLine.assigned_to_id =
        item.assigned_to_id > 0 ? item.assigned_to_id : undefined;
      templateLine.days = item.days
        .map((day, index) => day.active && index)
        .filter(item => item !== undefined);
      template.push(templateLine);
    }
  });
  return template;
}
