import moment from 'moment';

const ITEMS_SET = 'ITEMS_SET';
const ITEMS_ADD = 'ITEMS_ADD';
const ITEMS_UPDATE = 'ITEMS_UPDATE';
const ITEMS_DELETE = 'ITEMS_DELETE';
const initialState = [];

export function set(items) {
  return { type: ITEMS_SET, items };
}

export function add(item) {
  return { type: ITEMS_ADD, item };
}

export function update(item) {
  return { type: ITEMS_UPDATE, item };
}

export function deleteItem(item) {
  return { type: ITEMS_DELETE, item };
}

export function reducer(state = initialState, action) {
  let result = state;
  if (action.type === ITEMS_SET) {
    result = action.items;
  } else if ([ITEMS_UPDATE, ITEMS_ADD].includes(action.type)) {
    const allBut = state.filter(existing => existing.id !== action.item.id);
    const udpatedList = [...allBut, action.item].sort(
      (a, b) => moment(a.created_at) - moment(b.created_at),
    );
    result = udpatedList;
  } else if (action.type === ITEMS_DELETE) {
    result = state.filter(existing => existing.id !== action.item.id);
  }
  return result;
}

export default { set, add, update, deleteItem };
