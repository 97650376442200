import idx from 'idx';
import React, { useEffect, useState } from 'react';
import { connect } from 'react-redux';
import { storeLogin } from '../../redux/auth';
import * as channelsAction from '../../redux/channels';
import { callApi } from '../../utils/callApi';
import { Button } from '../Home/components/Button.js';
import { ItemFieldWrapper } from '../Home/components/ItemForm/ItemFields';
import { H1, H2, H4 } from '../Home/components/Type.js';
import './Join.scss';

const Join = props => {
  const [loading, setLoading] = useState(false);
  const [formType, setFormType] = useState('signup');
  const [errors, setErrors] = useState(null);
  const [formData, setFormData] = useState({});
  const [inviteData, setInviteData] = useState(null);

  const {
    match: {
      params: { familyHash, groupHash },
    },
    loggedIn,
    handleStoreLogin,
    history: routerHistory,
    auth,
  } = props;

  useEffect(() => {
    callApi('GET', `api/join/${groupHash || familyHash}`).then(setInviteData);
  }, [groupHash, familyHash, setInviteData]);

  useEffect(() => {
    if (loggedIn && inviteData) {
      processExistingUser(
        groupHash,
        familyHash,
        handleStoreLogin,
        routerHistory,
        setLoading,
        inviteData,
        auth,
      );
    }
  }, [
    loggedIn,
    inviteData,
    auth,
    familyHash,
    groupHash,
    handleStoreLogin,
    routerHistory,
  ]);

  // Show possible page-blocking error states if logged in
  if (loggedIn && inviteData) {
    // If the invite is for a family but it's not your family, display an error
    if (familyHash && auth.family_id !== inviteData.family_id) {
      return (
        <div className="Join">
          <H1>
            <span role="img" aria-label="Warning Sign emoji">
              ⚠️
            </span>{' '}
            You&rsquo;ve already joined a Family
          </H1>
          <p>
            We see you&rsquo;re already a member of {auth.family}. Currently we
            only support being a part of one Family.
          </p>
          <Button className="large" onClick={() => routerHistory.push('/')}>
            Go to Family Account
          </Button>
        </div>
      );
    }

    // If the invite is for a group but your family is in a different group, display an error
    if (groupHash && auth.group_id && auth.group_id !== inviteData.group_id) {
      return (
        <div className="Join">
          <H1>
            <span role="img" aria-label="Warning Sign emoji">
              ⚠️
            </span>{' '}
            You&rsquo;ve already joined a Circle
          </H1>
          <p>
            We see you&rsquo;re already a member of {auth.group_name}. Currently
            we only support being a part of one Circle.
          </p>
          <Button
            className="large"
            onClick={() => routerHistory.push('/circles')}
          >
            Go to Circle
          </Button>
        </div>
      );
    }
  }

  const onInputChange = evt => {
    setFormData({ ...formData, [evt.target.name]: evt.target.value });
  };

  let inviteText = '';
  if (inviteData) {
    if (groupHash) {
      inviteText = `${
        inviteData.user_name
      } has invited your family to join their Circle. You’ll be able to collaboratively manage a shared calendar, tasks and resources. Get started now by ${
        formType === 'signup' ? 'creating your family' : 'signing into your'
      } account.`;
    } else {
      inviteText = `${
        inviteData.family_name
      } invited you to join their family account. You’ll be able to collaboratively manage a shared calendar, tasks and resources. Get started now by ${
        formType === 'signup' ? 'creating your family' : 'signing into your'
      } account.`;
    }
  }

  return (
    <div className="Join">
      <H2>Modern Village</H2>
      <div className="switch-form-header">
        <span
          onClick={() =>
            setFormType(formType === 'signup' ? 'signin' : 'signup')
          }
        >
          {formType === 'signup' ? 'Sign In' : 'Sign Up'}
        </span>
      </div>
      <H4>Step 1 of {groupHash ? 3 : 2}</H4>
      <H1>
        <span role="img" aria-label="Waving hand emoji">
          👋
        </span>{' '}
        Join{' '}
        {inviteData &&
          (groupHash ? inviteData.group_name : inviteData.family_name)}
      </H1>
      {inviteText && <p>{inviteText}</p>}

      <form>
        {formType === 'signup' && (
          <ItemFieldWrapper>
            <input
              autoFocus
              disabled={loading}
              onChange={onInputChange}
              type="name"
              name="name"
              placeholder="Your name (e.g. Bill)"
            />
          </ItemFieldWrapper>
        )}
        {formType === 'signup' && !!groupHash && (
          <ItemFieldWrapper>
            <input
              disabled={loading}
              onChange={onInputChange}
              type="name"
              name="family_name"
              placeholder="Family name (e.g. The Smiths)"
            />
          </ItemFieldWrapper>
        )}

        <ItemFieldWrapper>
          <input
            disabled={loading}
            onChange={onInputChange}
            type="email"
            name="email"
            placeholder="Your email address"
          />
        </ItemFieldWrapper>

        <ItemFieldWrapper>
          <input
            disabled={loading}
            type="password"
            name="password"
            placeholder="Your password"
            onChange={onInputChange}
          />
        </ItemFieldWrapper>
        {formType === 'signup' && (
          <>
            <ItemFieldWrapper>
              <input
                disabled={loading}
                type="password"
                name="confirm"
                placeholder="Confirm password"
                onChange={onInputChange}
              />
            </ItemFieldWrapper>
            <Button
              disabled={loading}
              onClick={evt =>
                handleSignUp(
                  evt,
                  formData,
                  familyHash,
                  groupHash,
                  setLoading,
                  setErrors,
                  handleStoreLogin,
                  routerHistory,
                )
              }
            >
              {loading ? 'Loading...' : 'Sign Up'}
            </Button>
          </>
        )}
        {formType === 'signin' && (
          <Button
            disabled={loading}
            onClick={evt =>
              handleSignIn(
                evt,
                groupHash,
                formData,
                setLoading,
                handleStoreLogin,
                setErrors,
              )
            }
          >
            {loading ? 'Loading...' : 'Sign In'}
          </Button>
        )}

        {errors && (
          <div className="errors">
            {errors.map((error, idx) => (
              <div key={idx}>{error}</div>
            ))}
          </div>
        )}

        <div className="switch-form-footer">
          {formType === 'signup'
            ? 'Already have an account?'
            : 'Need an account?'}{' '}
          <span
            onClick={() =>
              setFormType(formType === 'signup' ? 'signin' : 'signup')
            }
          >
            {formType === 'signup' ? 'Sign In' : 'Sign Up'}
          </span>
        </div>
      </form>
    </div>
  );
};

export default connect(
  state => ({
    loggedIn: idx(state, _ => _.auth.loggedIn),
    auth: state.auth,
  }),
  {
    handleStoreLogin: storeLogin,
    storeChannels: channelsAction.set,
  },
)(Join);

async function processExistingUser(
  groupHash,
  familyHash,
  handleStoreLogin,
  routerHistory,
  setLoading,
  inviteData,
  auth,
) {
  // The user is being invited to join a family
  if (familyHash) {
    if (auth.family_id === inviteData.family_id) {
      // You're already a member of that family
      routerHistory.push('/circles?join=family');
    }
    // Else: error display handled in render path
    return;
  }

  // The user's family is being invited to join a group
  if (auth.group_id) {
    // Your family is already part of a group
    if (auth.group_id === inviteData.group_id) {
      // Your family is already a member of that group
      routerHistory.push('/circles?join=circle');
    }
    // Else: error display handled in render path
  } else {
    // Join this group
    const result = await callApi('POST', 'api/join', undefined, {
      group_hash: groupHash,
    });

    if (result && result.error) {
      alert(result.error);
      setLoading(false);
      return;
    }

    // Re-login to get circle list
    await handleStoreLogin(await callApi('POST', 'login'));
    routerHistory.push('/circles?join=circle');
  }
}

async function handleSignUp(
  evt,
  formData,
  familyHash,
  groupHash,
  setLoading,
  setErrors,
  handleStoreLogin,
  routerHistory,
) {
  evt.preventDefault();

  const { name, email, password, confirm, family_name } = formData;

  if (
    !name ||
    !email ||
    !password ||
    !confirm ||
    (!family_name && !familyHash)
  ) {
    setErrors(['Please complete all fields']);
    return;
  }

  if (password !== confirm) {
    setErrors(['Password and confirmation do not match']);
    return;
  }

  setLoading(true);

  // Create user and add to this group/family
  const result = await callApi('POST', 'api/join', undefined, {
    name,
    email,
    password,
    family_hash: familyHash,
    group_hash: groupHash,
    family_name,
  });

  if (result && result.error) {
    alert(result.error);
    setLoading(false);
    return;
  }

  // Log this user in and update local auth
  const loginResponse = await callApi('POST', 'login', undefined, {
    email,
    password,
  });
  await handleStoreLogin(loginResponse);
  routerHistory.push(`/circles?join=${familyHash ? 'family' : 'circle'}`);
}

async function handleSignIn(
  evt,
  groupHash,
  formData,
  setLoading,
  handleStoreLogin,
  setErrors,
) {
  evt && evt.preventDefault();

  // Validate form data
  if (!formData.email || !formData.password) {
    setErrors(['Please complete all fields']);
    return;
  }
  setLoading(true);

  // Log this user in and update local auth if successful (has access_token)
  const loginResponse = await callApi('POST', 'login', undefined, formData);
  if (loginResponse.access_token) {
    await handleStoreLogin(loginResponse);
  } else {
    // login unsuccessful? should an error be displayed?
    setErrors(['Incorrect email and/or password']);
    setLoading(false);
  }
}
