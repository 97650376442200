import 'boxicons';
import React, { useEffect, useState, useRef, Fragment } from 'react';
import { useParams } from 'react-router-dom';
import { callApi } from '../../utils/callApi';
import {
  questions,
  CorporateQuestion,
  PaymentQuestion,
  Complete,
} from './questions';

import './SignupWizard.scss';

const SignupWizard = () => {
  const { hash, stripeStatus } = useParams();
  const [data, setData] = useState({
    waitlist: {},
    family: {},
  });

  // Skip the first step if we're coming back after paying with Stripe
  const [stepIndex, setStepIndex] = useState(stripeStatus ? 1 : 0);
  const [complete, setComplete] = useState();
  const scrollRef = useRef(null);
  const QUESTIONS = [
    ...questions,
    data.waitlist.organization ? CorporateQuestion : PaymentQuestion,
  ];

  // Load the existing data
  useEffect(() => {
    callApi('GET', `api/invite/${hash}`).then(result => {
      setData(data => ({ ...data, ...result }));
    });
  }, [hash]);

  useEffect(() => {
    if (scrollRef.current) {
      scrollRef.current.scrollIntoView({ behavior: 'smooth' });
    }
    if (stepIndex >= QUESTIONS.length) {
      setComplete(true);
    }
  }, [stepIndex, QUESTIONS.length]);

  function saveAnswer(answer, isCurrent) {
    if (!answer) {
      // Not a question, just a confirmation step
      setStepIndex(stepIndex + 1);
    } else {
      callApi(
        'PUT',
        `api/invite/${hash}`,
        undefined,
        Array.isArray(answer) ? answer : [answer],
      ).then(result => {
        setData(result);
        if (isCurrent) {
          // Only increment stepIndex if this is the current question
          // (we're not revisiting a previous question)
          setStepIndex(stepIndex + 1);
        }
      });
    }
  }

  if (!data.waitlist.user_email) {
    return null;
  }

  return (
    <div className="SignupWizard">
      <div className="header">Milo Setup</div>
      <div className="questions">
        {!complete &&
          QUESTIONS.slice(0, stepIndex + 1).map((Question, idx) => (
            <Fragment key={idx}>
              {stepIndex === idx + 1 && <div ref={scrollRef} />}

              <Question
                data={data}
                onAnswer={answer => saveAnswer(answer, idx === stepIndex)}
                isCurrent={stepIndex === idx}
                showComment={stepIndex === idx + 1}
                hash={hash}
              />
            </Fragment>
          ))}
        {complete && <Complete />}
      </div>
    </div>
  );
};

export default SignupWizard;
