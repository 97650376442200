import queryString from 'query-string';
import React from 'react';
import { connect } from 'react-redux';
import { storeLogin } from '../../redux/auth';
import { callApi } from '../../utils/callApi';
import AccountModal from '../Account/AccountModal';
import { Button } from '../Home/components/Button';
import Calendar from './Calendar';
import './ConnectCalendars.scss';

class ConnectCalendar extends React.Component {
  state = {
    loading: false,
    timezone: this.props.auth.timezone || 'US/Pacific',
    connections: [],
  };

  async componentDidMount() {
    const { auth, storeLogin } = this.props;
    const params = queryString.parse(this.props.location.search);
    if (params.code) {
      await this.authenticate(params.code);
      await storeLogin({ ...auth, has_family_calendar: true });
      this.props.history.push(`/calendar`);
    } else {
      this.loadConnections();
    }
  }

  async authenticate(code) {
    this.setState({ loading: true });
    const url = 'api/gcal/auth';
    await callApi('POST', url, undefined, { code });
    await this.loadConnections();
    this.setState({ loading: false });
  }

  async loadConnections() {
    this.setState({ loading: true });
    await Promise.all([
      this.getCalendarConnections(),
      this.getPrimaryCalendar(),
    ]);
    this.setState({ loading: false });
  }

  async getCalendarConnections() {
    const connections = await callApi('GET', 'api/gcal/connections');
    this.setState({ connections });
  }

  async getPrimaryCalendar() {
    const res = await callApi('GET', 'api/gcal/primary');
    this.setState({
      primaryConnectionId: res.calendar_id,
      primaryCalendarId: res.google_calendar_id,
    });
  }

  async handleConnectClick() {
    this.setState({ loading: true });
    const res = await callApi('GET', 'api/gcal/auth');
    if (res.auth_url) {
      window.location.href = res.auth_url;
    }
  }

  async handleRemoveConnection(connectionId) {
    const confirm = window.confirm(
      'Are you use you want to disconnect this calendar?',
    );
    if (!confirm) {
      return;
    }
    await this.handleSetPrimary(null, null);
    await callApi('DELETE', `api/gcal/connections/${connectionId}`);
    this.loadConnections();
  }

  async handleSetPrimary(connectionId, calendarId) {
    const body = { calendar_id: connectionId, google_calendar_id: calendarId };
    const res = await callApi('POST', 'api/gcal/primary', undefined, body);
    if (res === 200) {
      await this.setState({
        primaryConnectionId: connectionId,
        primaryCalendarId: calendarId,
      });
    }
  }

  onClose() {
    this.props.history.push(`/`);
  }

  onChangeTimezone(e) {
    e.preventDefault();
    this.setState({ timezone: e.target.value });
  }

  async onSave() {
    const { timezone } = this.state;
    const { auth, storeLogin } = this.props;
    const url = 'api/settings';
    const res = await callApi('PUT', url, undefined, { timezone });
    if (res === 204) {
      await storeLogin({ ...auth, timezone });
      this.onClose();
    }
  }

  inOnboardingFlow() {
    const { auth } = this.props;
    return (
      auth.family_metadata &&
      auth.family_metadata.signup_version &&
      !auth.family_metadata.onboarding_complete
    );
  }

  render() {
    const { auth } = this.props;
    const {
      connections = [],
      loading,
      primaryConnectionId,
      primaryCalendarId,
      timezone,
    } = this.state;
    const timezones = ['US/Eastern', 'US/Central', 'US/Mountain', 'US/Pacific'];
    if (!timezones.includes(timezone)) {
      timezones.push(timezone);
    }

    const hideCalendarConnect =
      auth.family_metadata &&
      auth.family_metadata.hide_calendar_connect === 'true';

    return (
      <AccountModal
        className="Settings"
        title="Settings"
        open={true}
        closeForm={() => this.onClose()}
      >
        {loading && 'Loading...'}
        {!loading && !this.inOnboardingFlow() && (
          <>
            Timezone:{' '}
            <select onChange={e => this.onChangeTimezone(e)} value={timezone}>
              {timezones.map(zone => (
                <option key={zone} value={zone}>
                  {zone}
                </option>
              ))}
            </select>
          </>
        )}
        <br />

        {!hideCalendarConnect && (
          <div className="ConnectCalendars">
            <hr />
            {!loading && !connections.length && (
              <div className="connect-panel">
                <div className="copy">Connect a Google Calendar to Milo</div>
                <div
                  className="copy"
                  style={{ fontSize: 13, fontStyle: 'italic' }}
                >
                  We recommend only syncing a family calendar as all events
                  added to Milo will sync to your family calendar and all events
                  added to your family calendar will sync to Milo.
                </div>
                <Button onClick={() => this.handleConnectClick()}>
                  Connect
                </Button>
              </div>
            )}
            {!loading &&
              connections.map(connection => (
                <div className="calendar-line" key={connection.id}>
                  <Calendar
                    connectionId={connection.id}
                    primaryConnectionId={primaryConnectionId}
                    primaryCalendarId={primaryCalendarId}
                    setPrimary={this.handleSetPrimary.bind(this)}
                  />
                  {primaryCalendarId && (
                    <Button
                      disabled={loading}
                      type="button"
                      onClick={() => this.handleRemoveConnection(connection.id)}
                    >
                      Disconnect
                    </Button>
                  )}
                </div>
              ))}
          </div>
        )}
        <div className="close-button-line">
          <Button
            variant="clear"
            disabled={loading}
            type="button"
            onClick={() => this.onClose()}
          >
            Cancel
          </Button>
          <Button
            disabled={loading}
            type="button"
            onClick={() => this.onSave()}
          >
            Save
          </Button>
        </div>
      </AccountModal>
    );
  }
}

export default connect(
  state => ({
    auth: state.auth,
    onboardingStep: state.onboardingStep,
  }),
  { storeLogin },
)(ConnectCalendar);
