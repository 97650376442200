import React, { useCallback, useEffect, useState } from 'react';
import { DragDropContext, Draggable, Droppable } from 'react-beautiful-dnd';
import { useDispatch } from 'react-redux';
import { openItemFormModal } from '../../../redux/itemFormModal';
import { Button } from '../components/Button';
import { Card, CardBody, CardFooterLink } from '../components/Card';
import Toolbar from '../components/Toolbar';
import {
  AssignedEmptyState,
  UnassignedEmptyState,
} from '../components/EmptyState';
import { Item } from '../components/Item';
import { Level } from '../components/Level';
import ButtonDropdown from '../components/ButtonDropdown';
import { COMPLETABLE, COMPLETE } from '../constants';
import { getBotUser } from '../utils';
import { track } from '../../../utils/analytics';
import './Inbox.scss';

const Inbox = props => {
  const {
    backlog = [],
    auth,
    showCompleteInBacklog,
    cardMarkComplete,
    saveItem,
    setShowCompleteInBacklog,
  } = props;
  const [columns, setColumns] = useState({});
  const [activeCol, setActiveCol] = useState('backlog');

  const dispatch = useDispatch();
  const openItemForm = useCallback(
    prefill => dispatch(openItemFormModal(prefill)),
    [dispatch],
  );

  const botUserId = getBotUser(auth);

  useEffect(() => {
    document.documentElement.classList.add('body-scroll');
    return () => {
      document.documentElement.classList.remove('body-scroll');
    };
  }, []);

  // Process incoming items into their columns
  useEffect(() => {
    // Initialize the column structure with the default backlog
    const columnsObj = {
      backlog: {
        id: 'backlog',
        title: 'Backlog',
        items: [],
      },
    };

    // Loop through the users and create a column for each one
    const userIds = auth.users.map(user => user.id);
    auth.users
      .filter(user => user.name !== 'bot')
      .forEach(user => {
        columnsObj[user.id] = {
          id: user.id,
          title: user.name,
          items: [],
        };
      });

    backlog
      .filter(item => showCompleteInBacklog || item.status !== COMPLETE)
      .filter(
        item =>
          !(item.linked_items && item.linked_items.find(child => child.date)),
      )
      .filter(
        item =>
          !item.linked_from ||
          (item.assigned_to_ids && item.assigned_to_ids.length),
      )
      .sort((a, b) => a.id - b.id)
      .forEach(item => {
        (item.assigned_to_ids || ['backlog']).forEach(assigned_to_id =>
          columnsObj[
            assigned_to_id > 0 &&
            assigned_to_id !== botUserId &&
            userIds.includes(assigned_to_id)
              ? assigned_to_id
              : 'backlog'
          ].items.push(item),
        );
      });

    // Save the assembled columns into state
    setColumns(columnsObj);
  }, [backlog, setColumns, auth.users, showCompleteInBacklog, botUserId]);

  useEffect(() => {
    track('todo:page:load');
  }, []);

  const sortedColumns = Object.entries(columns).sort((a, b) => {
    if (a[0] === 'backlog') return -1;
    if (b[0] === 'backlog') return 1;
    return a[0] - b[0];
  });

  return (
    <div className="Inbox">
      <Toolbar>
        <div />
        <ButtonDropdown
          side="right"
          variant="secondary"
          options={[
            {
              name: 'Incomplete',
              onClick: () => setShowCompleteInBacklog(false),
              disabled: !showCompleteInBacklog,
            },
            {
              name: 'All',
              onClick: () => setShowCompleteInBacklog(true),
              disabled: showCompleteInBacklog,
            },
          ]}
        >
          Show: {showCompleteInBacklog ? 'All' : 'Incomplete'}
        </ButtonDropdown>
      </Toolbar>
      <div className="card-container">
        <DragDropContext
          onDragEnd={async result => {
            // Handle a drop. This will move the item in local state, then make the necessary API
            // calls for the backend.

            const { source, destination } = result;

            // Return early if a drop is cancelled
            if (!destination) return;

            // Only support drag from one column to another
            if (destination.droppableId !== source.droppableId) {
              // If destination and source aren't the same, we're moving from one column to another

              // Grab the columns and make copies of their item arrays
              const sourceColumn = columns[source.droppableId];
              const destinationColumn = columns[destination.droppableId];
              const sourceItems = [...sourceColumn.items];
              const destinationItems = [...destinationColumn.items];

              // Splice the item from the source to the destination
              const [draggedItem] = sourceItems.splice(source.index, 1);
              destinationItems.splice(destination.index, 0, draggedItem);

              // Set the local column state with both modified columns
              setColumns({
                ...columns,
                [sourceColumn.id]: {
                  ...sourceColumn,
                  items: sourceItems,
                },
                [destinationColumn.id]: {
                  ...destinationColumn,
                  items: destinationItems,
                },
              });

              // Make the API call to update the item on the server with its new assignee
              await saveItem({
                ...draggedItem,
                assigned_to_ids: isNaN(destinationColumn.id)
                  ? null
                  : [parseInt(destinationColumn.id, 10)],
              });
            }
          }}
        >
          {sortedColumns.map(([columnId, { title, items }], idx) => (
            <Card
              key={columnId}
              className={columnId !== activeCol ? 'hide-mobile' : ''}
            >
              <CardBody>
                <Level>
                  <Button
                    variant="clear"
                    className="show-mobile"
                    onClick={() => setActiveCol(sortedColumns[idx - 1][0])}
                    disabled={idx === 0}
                  >
                    <box-icon name="chevron-left" />
                  </Button>

                  <ButtonDropdown
                    variant="clear"
                    side="center"
                    className="show-mobile column-picker"
                    top={30}
                    options={sortedColumns.map(([colId, { title }]) => {
                      return {
                        name: title,
                        onClick: () => setActiveCol(colId),
                        disabled: colId === activeCol,
                      };
                    })}
                  >
                    <h2>{title}</h2>
                  </ButtonDropdown>
                  <h2 className="hide-mobile">{title}</h2>

                  <Button
                    variant="clear"
                    className="show-mobile"
                    onClick={() => setActiveCol(sortedColumns[idx + 1][0])}
                    disabled={idx === sortedColumns.length - 1}
                  >
                    <box-icon name="chevron-right" />
                  </Button>

                  <Button
                    className="hide-mobile"
                    variant="clear"
                    onClick={() =>
                      openItemForm({
                        assigned_to_ids:
                          columnId !== 'backlog' ? [+columnId] : null,
                        status: COMPLETABLE,
                      })
                    }
                  >
                    <box-icon name="plus" />
                  </Button>
                </Level>
              </CardBody>
              <Droppable droppableId={columnId.toString()}>
                {droppableProvided => (
                  <div
                    className="droppable-container"
                    ref={droppableProvided.innerRef}
                    {...droppableProvided.droppableProps}
                  >
                    {items.map((item, index) => (
                      <Draggable
                        index={index}
                        key={item.id}
                        draggableId={item.id.toString()}
                      >
                        {(draggableProvided, draggableSnapshot) => (
                          <Item
                            ref={draggableProvided.innerRef}
                            draggableProps={draggableProvided.draggableProps}
                            dragHandleProps={draggableProvided.dragHandleProps}
                            item={item}
                            cardMarkComplete={cardMarkComplete}
                            variant={draggableSnapshot.isDragging && 'dragging'}
                            showDate={true}
                          />
                        )}
                      </Draggable>
                    ))}
                    {droppableProvided.placeholder}
                    {!items.length &&
                      (columnId === 'backlog' ? (
                        <UnassignedEmptyState />
                      ) : (
                        <AssignedEmptyState />
                      ))}
                  </div>
                )}
              </Droppable>
              <CardFooterLink
                onClick={() =>
                  openItemForm({
                    assigned_to_ids:
                      columnId !== 'backlog' ? [+columnId] : null,
                    status: COMPLETABLE,
                  })
                }
              >
                <box-icon name="plus" />
                Add Item
              </CardFooterLink>
            </Card>
          ))}
        </DragDropContext>
      </div>
    </div>
  );
};

export default Inbox;
