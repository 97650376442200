export const COMPLETE = 'complete';
export const COMPLETABLE = 'completable';
export const BOT = 'bot';
export const DATE_FORMAT = 'YYYY-MM-DD';
export const DAYS_OF_WEEK = [
  'Monday',
  'Tuesday',
  'Wednesday',
  'Thursday',
  'Friday',
  'Saturday',
  'Sunday',
];

export const CATEGORIES = [
  'milo',
  'general',
  'contacts',
  'meals',
  'kids-activities',
  'documents',
  'school',
  'lists',
  'shopping',
  'childcare',
  'health',
  'finances',
  'travel',
  'social',
  'maintenance',
];

export const DEFAULT_CATEGORIES = [
  'milo',
  'general',
  'contacts',
  'meals',
  'kids-activities',
];

export const CATEGORY_EMOJI = {
  milo: '✨',
  general: '🏡',
  contacts: '☎️',
  meals: '🥬',
  'kids-activities': '⚽️',
  documents: '📁',
  school: '🎒',
  lists: '📝',
  shopping: '🛍',
  childcare: '💕',
  health: '👩🏾‍⚕️',
  finances: '💰',
  travel: '🌎',
  social: '🎈',
  maintenance: '🔧',
};

export const TYPE_EVENT = 'event';
export const TYPE_TODO = 'todo';
export const TYPE_FILE = 'file';
export const TYPE_NEW = 'new';
export const TYPE_SERIES = 'series';

export const STORAGE_KEY_ONLY_ME = 'showOnlyMe';
