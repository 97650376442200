import React, { useState, useEffect } from 'react';
import DatePicker from 'react-datepicker';
import moment from 'moment';
import pluralize from 'pluralize';
import { ItemFieldWrapper } from '../../Home/components/ItemForm/ItemFields';

const QUESTION_TEXT = 'How old are your kids?';

const KidsQuestion = ({ onAnswer, isCurrent, data }) => {
  const [revisiting, setRevisiting] = useState(false);
  const savedData = data.family.metadata['kids-dobs'];
  const [dobs, setDobs] = useState((savedData || '').split(','));
  if (!dobs.length) {
    setDobs(['']);
  }

  useEffect(() => {
    if (savedData && isCurrent) {
      onAnswer();
    }
  }, [savedData, isCurrent, onAnswer]);

  function updateDob(index, dob) {
    const dobsCopy = dobs.slice();
    dobsCopy[index] = dob;
    setDobs(dobsCopy);
  }

  function addDob() {
    setDobs([...dobs, '']);
  }

  function removeDob(index) {
    setDobs(dobs.filter((_, dobIndex) => dobIndex !== index));
  }

  return (
    <div className="AddKidsQuestion">
      {!(isCurrent || revisiting) && (
        <>
          <div className="answered-question">{QUESTION_TEXT}</div>
          <div className="response-wrapper">
            <div className="response" onClick={() => setRevisiting(true)}>
              {dobs.length} {pluralize('kid', dobs.length)}
              <box-icon name="check" />
            </div>
          </div>
        </>
      )}

      {(isCurrent || revisiting) && (
        <>
          <div className="title">{QUESTION_TEXT}</div>
          <p>
            We&rsquo;ll use this info to customize the best recommendations and
            ideas for these ages.
          </p>
          <div style={{ marginBottom: 32 }}>
            {dobs.map((dob, index) => (
              <ItemFieldWrapper
                key={index}
                onClose={index ? () => removeDob(index) : undefined}
              >
                <DatePicker
                  dateFormat="MMMM yyyy"
                  showMonthYearPicker
                  placeholderText="Month & year of birth"
                  selected={dob && moment(dob).valueOf()}
                  onChange={time =>
                    updateDob(index, moment(time).format('YYYY-MM-01'))
                  }
                />
              </ItemFieldWrapper>
            ))}
          </div>

          {!!dobs.filter(dob => !!dob).length && (
            <>
              <div className="option" onClick={addDob}>
                Add Another Kid
              </div>
              <div
                className="option"
                onClick={() => {
                  setRevisiting(false);
                  onAnswer({
                    key: 'kids-dobs',
                    value: dobs.filter(dob => !!dob).join(),
                  });
                }}
              >
                All Done
              </div>
            </>
          )}
        </>
      )}
    </div>
  );
};

export default KidsQuestion;
