import pluralize from 'pluralize';
import React, { useState } from 'react';
import { connect } from 'react-redux';
import { updateUserMetadata } from '../../../redux/auth';
import { track } from '../../../utils/analytics';
import { callApi } from '../../../utils/callApi';
import { Button } from '../components/Button';
import { ItemFieldWrapper } from '../components/ItemForm/ItemFields';
import Panel from '../components/Panel';
import './ConfirmContacts.scss';

const ConfirmContacts = props => {
  const {
    progress,
    items,
    auth,
    auth: { user },
  } = props;

  const [loading, setLoading] = useState();
  const [phone, setPhone] = useState(user.phone);
  const [errors, setErrors] = useState([]);
  const [showPhoneForm, setShowPhoneForm] = useState(false);

  const [emailAddress, setEmailAddress] = useState(user.email);
  const [showEmailForm, setShowEmailForm] = useState(false);

  /** Phone format needs to be +12223334444
   *  where 1 is the country code
   */
  function validatePhone(phone) {
    const numbers = phone.replace(/[^0-9.]/g, '');
    let savePhone = numbers;
    if (numbers.length < 10) {
      setErrors(['The number entered is not valid']);
      return;
    }

    if (numbers[0] !== '1') {
      savePhone = `1${numbers}`;
    }
    savePhone = `+${savePhone}`;
    return savePhone;
  }

  function confirmPhone() {
    const newPhone = validatePhone(phone);
    if (!newPhone) return;
    setLoading(true);
    callApi('POST', 'api/confirm', undefined, { phone: newPhone }).then(() => {
      setShowPhoneForm(false);
      props.updateUserMetadata({ milo_sent_sms: 'true' });
      setLoading(false);
      track('triage:setup:confirm-phone', {
        u: auth.userId,
        f: auth.family_id,
      });
    });
  }

  function confirmEmail() {
    setLoading(true);
    callApi('POST', `api/confirm`, undefined, { email: emailAddress }).then(
      () => {
        setShowEmailForm(false);
        props.updateUserMetadata({ milo_sent_email: 'true' });
        setLoading(false);
        track('triage:setup:confirm-email', {
          u: auth.id,
          f: auth.family_id,
        });
      },
    );
  }

  const { sms, email, triaged_sms, triaged_email } = progress || {};
  const { milo_sent_sms, milo_sent_email } = user.metadata || {};
  const receivedSms = !!items.filter(
    item =>
      item.data && item.data.source === 'sms' && item.created_by_id === auth.id,
  ).length;
  const receivedEmail = !!items.filter(
    item =>
      item.data &&
      item.data.source === 'email' &&
      item.created_by_id === auth.id,
  ).length;

  // Default first step - nothing has happened
  let smsStep = 0;
  if ((sms && sms > 0) || receivedSms) {
    // The user sent an sms in
    smsStep = 2;
  } else if (milo_sent_sms && !showPhoneForm) {
    // We sent an sms to the user
    smsStep = 1;
  }

  let emailStep = 0;
  if ((email && email > 0) || receivedEmail) {
    emailStep = 2;
  } else if (milo_sent_email && !showEmailForm) {
    emailStep = 1;
  }

  const numTriaged =
    (triaged_sms ? triaged_sms : 0) + (triaged_email ? triaged_email : 0);

  return (
    <div className="ConfirmContacts">
      {/* Show this panel until you've sent something in */}
      {smsStep < 2 && emailStep < 2 && (
        <Panel>
          <p>
            Want to deal with something later? Send it to Milo and it’ll be
            waiting here for you, when you need it.
          </p>
        </Panel>
      )}

      <Panel variant={smsStep > 1 ? 'green' : ''}>
        {smsStep > 1 && <box-icon name="check" />}
        <h2 className={`sans ${smsStep > 1 ? 'line-through' : ''}`}>
          <span role="img" aria-label="speech balloon emoji">
            💬
          </span>
          &nbsp; 1. Send Milo a text
        </h2>
        {smsStep === 0 && (
          <>
            <p>
              We’ll send you an SMS text to say hello. Save our contact info and
              text back a to-do in your head now (e.g., register for swim
              lessons).
            </p>
            <ItemFieldWrapper className="attached-button">
              <input
                type="text"
                value={phone}
                name="phone"
                onChange={evt => setPhone(evt.target.value)}
              />
              <Button disabled={loading} variant="large" onClick={confirmPhone}>
                Try it out
              </Button>
              {errors && <div className="errors">{errors}</div>}
            </ItemFieldWrapper>
          </>
        )}

        {smsStep === 1 && (
          <>
            <p>
              A text is on its way to {phone}. Reply to the message with a to-do
              in your head now.
            </p>
            <div className="callout">Check your SMS</div>
            <p>
              <a href="#" onClick={() => setShowPhoneForm(true)}>
                Click here to check your phone number
              </a>{' '}
              if you don’t recieve a text.
            </p>
          </>
        )}
      </Panel>

      <Panel variant={emailStep > 1 ? 'green' : ''}>
        {emailStep > 1 && <box-icon name="check" />}
        <h2 className={`sans ${emailStep > 1 ? 'line-through' : ''}`}>
          <span role="img" aria-label="envelope emoji">
            ✉️
          </span>
          &nbsp; 2. Send Milo an email
        </h2>
        {emailStep === 0 && (
          <>
            <p>
              We’ll send you an email to say hello. Save our contact info and
              forward us an email that you’d like to act on later (e.g., kids’
              school schedules).
            </p>
            <ItemFieldWrapper className="attached-button">
              <input
                type="text"
                value={emailAddress}
                name="email"
                onChange={evt => setEmailAddress(evt.target.value)}
              />
              <Button disabled={loading} variant="large" onClick={confirmEmail}>
                Try it out
              </Button>
            </ItemFieldWrapper>
          </>
        )}

        {emailStep === 1 && (
          <>
            <p>
              An email is on its way to {emailAddress}. Reply with a thing you
              want to offload—like a quick grocery list or a reminder.
            </p>
            <div className="callout">Check your email</div>
            <p>
              <a href="#" onClick={() => setShowEmailForm(true)}>
                Click here to check your address
              </a>{' '}
              if you don’t receive an email (heads up that emails can take up to
              15 minutes to travel the interwebs).
            </p>
            <p>
              Want to send emails in from more addresses? Send a note to{' '}
              <a href="mailto:onboarding@joinmilo.com">
                onboarding@joinmilo.com
              </a>{' '}
              and we’ll add them.
            </p>
          </>
        )}
      </Panel>

      <Panel variant={numTriaged >= 5 ? 'green' : ''}>
        {numTriaged >= 5 && <box-icon name="check" />}
        <h2 className={`sans ${numTriaged >= 5 ? 'line-through' : ''}`}>
          <span role="img" aria-label="card index dividers emoji">
            🗂
          </span>
          &nbsp; 3. Sort {numTriaged < 5 ? 5 - numTriaged : 5}
          {!!numTriaged && numTriaged < 5 && ' more'}{' '}
          {pluralize('items', 5 - numTriaged)}
        </h2>

        {/* Don't show the progress bar if it's empty, that's a bummer */}
        {!!numTriaged && (
          <div className="progress">
            <div
              className="progress-bar"
              style={{ width: (numTriaged * 100) / 5 + '%' }}
              role="progressbar"
              aria-valuenow={numTriaged}
              aria-valuemin="0"
              aria-valuemax="5"
            ></div>
          </div>
        )}

        {numTriaged < 5 && (
          <p>
            Organize the items you added to Triage by turning them into events,
            adding them to your to-do list, or filing them away.
          </p>
        )}
      </Panel>
    </div>
  );
};

export default connect(
  state => ({
    auth: state.auth,
    progress: state.progress,
  }),
  {
    updateUserMetadata,
  },
)(ConfirmContacts);
