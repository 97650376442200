import React from 'react';
import { Button } from '../../Home/components/Button';

const Intro = ({ onAnswer, isCurrent, data }) => {
  if (!isCurrent) return null;
  const returning = !!data.family.id;
  const name =
    (data.family.users && data.family.users[0] && data.family.users[0].name) ||
    data.waitlist.user_name;
  return (
    <div className="Intro">
      <div className="title serif">
        {returning ? 'Welcome back!' : "Let's get you set up!"}
      </div>
      <p className="intro" style={{ marginBottom: 12 }}>
        <span role="img" aria-label="waving emoji">
          👋
        </span>{' '}
        Hi{name && ` ${name}`}
        {'! '}
        {!returning && <span>I&rsquo;m Milo.</span>}
      </p>
      <p className="intro">
        {returning ? 'M' : 'By way of quick intro, m'}y name stands for
        &ldquo;My Important Loved Ones&rdquo; and I support you each week by 1.{' '}
        <span role="img" aria-label="Nerd Face emoji">
          🤓
        </span>{' '}
        being your second brain and taking care of the mundane logistics and 2.
        <span role="img" aria-label="Smiling Face with Hearts emoji">
          🥰
        </span>{' '}
        helping you spend your time doing what matters most to you.
      </p>
      <Button
        variant="green large"
        onClick={() =>
          onAnswer(returning ? undefined : { key: 'started', value: 'true' })
        }
      >
        {returning ? 'Finish my Milo Setup' : "Let's do this 💪🏽"}
      </Button>
    </div>
  );
};

export default Intro;
