import { COMPLETE, COMPLETABLE } from '../constants';
import { BLANK_TIME } from './align-days';

/**
 * Take the items for each day and organize in to
 * meals, todos, today, morning, afternoon, evening
 *
 * Insert blank items if there are more on one day
 * than another so that all columns line up
 */

export function splitItems(itemsByDay, isMobile, signupVersion2) {
  const week = itemsByDay.map(daysItems => splitDay(daysItems));

  // Now look at the count of each and fill in blanks where required
  const maxCounts = {};
  week.forEach(daysItems => {
    const keys = Object.keys(daysItems);
    keys.forEach(key => {
      if (!maxCounts[key] || maxCounts[key] < daysItems[key].length) {
        maxCounts[key] = daysItems[key].length;
      }
    });
  });

  // Now go and pad the lesser sections
  week.forEach(daysItems => {
    const keys = Object.keys(daysItems);
    keys.forEach(key => {
      let count = isMobile ? 0 : maxCounts[key] - daysItems[key].length;
      while (count >= (signupVersion2 ? 0 : 1)) {
        daysItems[key].push({ time: BLANK_TIME });
        count--;
      }
    });
  });

  return week;
}

function splitDay(items) {
  const day = {
    meals: [],
    todos: [],
    today: [],
    morning: [],
    afternoon: [],
    evening: [],
  };
  const sorted = items.sort((a, b) => a.time - b.time);
  sorted.forEach(item => {
    const tags = item.tags || '';
    if (
      tags.toLowerCase().includes('meal') &&
      (tags.toLowerCase().includes('breakfast') ||
        tags.toLowerCase().includes('lunch') ||
        tags.toLowerCase().includes('dinner'))
    ) {
      day.meals.push(item);
      return;
    }
    if ([COMPLETE, COMPLETABLE].includes(item.status)) {
      day.todos.push(item);
      return;
    }
    if (!item.time) {
      day.today.push(item);
      return;
    }
    if (item.time < '12:00') {
      day.morning.push(item);
      return;
    }
    if (item.time >= '12:00' && item.time < '17:00') {
      day.afternoon.push(item);
      return;
    }
    if (item.time >= '17:00') {
      day.evening.push(item);
      return;
    }
  });
  return day;
}
