import React from 'react';
import { connect } from 'react-redux';
import { open as openModal } from '../../../../redux/modal';

const PlannerButton = props => {
  return (
    <button
      className="WidgetButton PlannerButton"
      type="button"
      onClick={() => props.openModal('planner')}
    >
      <box-icon type="solid" name="calendar-plus" />
      <div>Schedule Builder</div>
    </button>
  );
};

export default connect(
  state => ({
    items: state.items,
  }),
  {
    openModal,
  },
)(PlannerButton);
