import React from 'react';
import Widget from '../Widget';
import TriageButton from './TriageButton';
import TriageHelp from './TriageHelp';

const WIDGET_NAME = 'triage';

const modes = {
  button: TriageButton,
  help: TriageHelp,
};

const TriageWidget = props => {
  const { mode, open, close } = props;
  return modes[mode] ? (
    <Widget
      key={WIDGET_NAME}
      name={WIDGET_NAME}
      active={modes[mode]}
      open={open}
      onRequestClose={close}
    />
  ) : null;
};

export default { name: WIDGET_NAME, widget: TriageWidget };
