import { useEffect, useCallback, useRef } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory } from 'react-router-dom';
import {
  openItemFormModal,
  closeItemFormModal,
} from '../../redux/itemFormModal';
import { closeAll, open } from '../../redux/modal';

const ModalManager = () => {
  const dispatch = useDispatch();

  // Create stable versions of all needed functions
  const openModal = useCallback(name => dispatch(open(name)), [dispatch]);
  const closeAllModals = useCallback(() => dispatch(closeAll()), [dispatch]);
  const openItemModal = useCallback(id => dispatch(openItemFormModal(id)), [
    dispatch,
  ]);
  const closeItemModal = useCallback(() => dispatch(closeItemFormModal()), [
    dispatch,
  ]);

  // Grab values from Redux
  const modal = useSelector(state => state.modal);
  const itemModal = useSelector(state => state.itemFormModal);

  const history = useHistory();
  const firstLoad = useRef(true);

  // Open a modal if the proper param is in the URL
  useEffect(() => {
    const params = new URLSearchParams(history.location.search);

    if (params.get('show')) {
      openModal(params.get('show'));
    } else {
      closeAllModals();
    }

    if (params.get('item')) {
      openItemModal(+params.get('item'));
    } else {
      closeItemModal();
    }
  }, [
    history.location.search,
    openModal,
    closeAllModals,
    openItemModal,
    closeItemModal,
  ]);

  // Set the URL param whenever a modal is opened
  useEffect(() => {
    // Skip this on the first load, so the above effect has a chance to run
    if (firstLoad.current) {
      firstLoad.current = false;
      return;
    }

    const params = new URLSearchParams(history.location.search);
    const queryString = params.toString();

    if (modal) {
      params.set('show', modal);
    } else {
      params.delete('show');
    }

    if (itemModal.itemId) {
      params.set('item', itemModal.itemId);
    } else {
      params.delete('item');
    }

    // Avoid pushing duplicates onto history
    if (params.toString() !== queryString) {
      history.push(`${history.location.pathname}?${params}`);
    }
  }, [history, modal, itemModal]);

  return null;
};

export default ModalManager;
