import classNames from 'classnames';
import moment from 'moment';
import React, { useState, useEffect } from 'react';
import { BlankItem, Item } from '../components/Item';
import { DATE_FORMAT } from '../constants';
import { BLANK_TIME } from '../Week/align-days';
import CalendarColumnHeader from '../Week/CalendarColumnHeader';
import './Preview.scss';

const Preview = props => {
  const {
    weekStartDate,
    cardMarkComplete,
    itemsByDay,
    getDateRangeString,
    showTemplateWarning,
  } = props;

  const [activeDay, setActiveDay] = useState(moment(weekStartDate));

  useEffect(() => setDay(moment(weekStartDate)), [weekStartDate]);

  const dates = getDateRangeString(weekStartDate);
  const errorText = [];
  if (moment(weekStartDate).isBefore(moment().startOf('day'))) {
    errorText.push(
      '👻 Are you sure you selected the correct week? It looks like some of these dates are in the past.',
    );
  }
  if (showTemplateWarning) {
    errorText.push(
      '👯‍♀️ This template was already applied to the week of ' +
        `${dates}. Applying it again will show some events twice.`,
    );
  }

  function setDay(param) {
    if (
      (activeDay.day() === 1 && param === -1) ||
      (activeDay.day() === 0 && param === 1)
    ) {
      return;
    }
    let newActiveDay;
    if (param === 1 || param === -1) {
      // Increment or decrement day
      newActiveDay = activeDay.clone().add(param, 'days');
    } else {
      // Jump to day
      newActiveDay = moment(param);
    }

    setActiveDay(newActiveDay);
  }

  return (
    <div className="Preview">
      {errorText &&
        !!errorText.length &&
        errorText.map((text, idx) => (
          <div className="error" key={idx}>
            {text}
          </div>
        ))}

      <div className="preview-body">
        <div className="calendar-column-container">
          {[0, 1, 2, 3, 4, 5, 6].map(i => {
            const date = moment(weekStartDate).add(i, 'days');
            const isToday =
              date.format(DATE_FORMAT) === moment().format(DATE_FORMAT);
            const isPast = moment().startOf('day').isAfter(date);
            const isActive =
              date.format(DATE_FORMAT) === activeDay.format(DATE_FORMAT);
            const items = (itemsByDay && itemsByDay[i]) || [];
            return (
              <div
                className={classNames('calendar-column', {
                  'active-day': isActive,
                })}
                key={i}
              >
                <CalendarColumnHeader
                  className={classNames({ today: isToday, past: isPast })}
                  date={date}
                  activeDay={activeDay}
                  setActiveDay={setDay}
                  hidePicker={true}
                />

                <div className="calendar-column-items">
                  {items.map((item, index) => {
                    if (item.time === BLANK_TIME) {
                      return <BlankItem key={index} />;
                    } else {
                      return (
                        <Item
                          key={index}
                          item={item}
                          cardMarkComplete={cardMarkComplete}
                          readOnly={true}
                        />
                      );
                    }
                  })}
                </div>
              </div>
            );
          })}
        </div>
      </div>
    </div>
  );
};

export default Preview;
