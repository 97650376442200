import classNames from 'classnames';
import moment from 'moment';
import React, { useEffect, useRef, useState } from 'react';
import {
  TYPE_EVENT,
  TYPE_NEW,
  TYPE_TODO,
  TYPE_SERIES,
  COMPLETE,
  COMPLETABLE,
} from '../../constants';
import { followTime } from '../../utils';
import itemFields, {
  FIELD_DISPLAY_ORDER,
  FIELD_TYPE_PRESETS,
} from './ItemFields';
import { ItemFieldTray } from './ItemFieldTray';
import './ItemForm.scss';
import TypeSwitcher from './TypeSwitcher';
import ItemMenu from './ItemMenu';
import { Level } from '../Level';

export const ItemForm = props => {
  const {
    item,
    type,
    setType,
    formData,
    setFormData,
    openChild,
    openParent,
    formErrors,
    showSearchDropdown,
    showAddMoreDates,
    removeChildFromParent,
  } = props;
  const [visibleFields, setVisibleFields] = useState(
    FIELD_TYPE_PRESETS[type].visible,
  );

  // Save values in fields that are x-ed out in case the user adds them again
  const [cacheData, setCacheData] = useState({});

  const [parentTitle, setParentTitle] = useState('');

  useEffect(() => {
    setParentTitle(
      (formData.linked_from &&
        formData.linked_from[0] &&
        formData.linked_from[0].title) ||
        '',
    );
  }, [setParentTitle, formData.linked_from]);

  // When the input item changes, set any already filled fields to visible, and clear the cache
  useEffect(() => {
    if (item) {
      const filledFields = FIELD_DISPLAY_ORDER.reduce((acc, field) => {
        acc[field] = !!item[field];
        return acc;
      }, {});
      setVisibleFields(prevVisibleFields => ({
        ...prevVisibleFields,
        ...filledFields,
        end_time: false, // end time is shown alongside start time automatically
      }));
    }
    setCacheData({});
  }, [item]);

  // When the type changes, inject the prefill options for the new type
  useEffect(() => {
    setFormData(prevFormData => ({
      ...prevFormData,
      ...FIELD_TYPE_PRESETS[type].prefill,
    }));
    setVisibleFields(prevVisibleFields => ({
      ...prevVisibleFields,
      ...FIELD_TYPE_PRESETS[type].visible,
    }));
  }, [setFormData, type]);

  // When type is determined to be a todo, set status to COMPLETABLE if it's not COMPLETE already
  useEffect(() => {
    if (type === TYPE_TODO) {
      setFormData(prevFormData => {
        if (prevFormData.status === COMPLETE) {
          return prevFormData;
        } else {
          return { ...prevFormData, status: COMPLETABLE };
        }
      });
    }
  }, [setFormData, type]);

  // TODO(nirav) should field visibility only work this way instead of manually doing it when fields
  // are shown and hidden?
  useEffect(() => {
    if (formData.location || formData.tags) {
      setVisibleFields(prevVisibleFields => ({
        ...prevVisibleFields,
        location: !!formData.location,
        tags: !!formData.tags,
      }));
    }
  }, [formData.location, formData.tags]);

  function setFormDataAndDirty(data, field) {
    if (data.linked_items && field) {
      data.linked_items.forEach(child => {
        if (
          !child.status &&
          (!child[field] ||
            0 ===
              JSON.stringify(child[field]).localeCompare(
                JSON.stringify(formData[field]),
              ))
        ) {
          child[field] = data[field];
          child.dirty = true;
        }
      });
    }
    setFormData({ ...data, dirty: true });
  }

  const endTimeRef = useRef(null);
  const hasRelatedEvents =
    !!formData.linked_items &&
    formData.linked_items.some(item => !!item.date && !item.status);
  const isChildEvent =
    formData.linked_from &&
    formData.linked_from.length &&
    formData.date &&
    !formData.status;
  const parentHeader = isChildEvent
    ? `View all ${parentTitle} events`
    : parentTitle;

  return (
    <form className={classNames('ItemForm', `type-${type}`)}>
      <Level>
        <TypeSwitcher
          name={FIELD_TYPE_PRESETS[type].name}
          new={!(item && item.id)}
          setType={
            formData.parent_id ||
            (formData.linked_from && formData.linked_from.length) ||
            (formData.linked_items && formData.linked_items.length)
              ? undefined
              : setType
          }
        />
        {!parentTitle && (
          <ItemMenu
            formData={formData}
            onChange={list =>
              setFormDataAndDirty({
                ...formData,
                parent_id: list.id,
                linked_from: [list],
              })
            }
            setParentTitle={setParentTitle}
            removeChildFromParent={removeChildFromParent}
          />
        )}
      </Level>
      {parentTitle && (
        <div className="ItemParentTitle" onClick={openParent}>
          <box-icon size="xs" name="list-ul" /> {parentHeader}
        </div>
      )}

      {/* Title */}
      <itemFields.title
        value={formData.title}
        formData={formData}
        onChange={title => setFormDataAndDirty({ ...formData, title }, 'title')}
        setFormData={setFormData}
        showSearchDropdown={showSearchDropdown}
      />
      {/* Emoji */}
      <itemFields.emoji
        value={formData.emoji}
        formData={formData}
        onChange={emoji => setFormDataAndDirty({ ...formData, emoji }, 'emoji')}
      />
      {/* Render all fields marked visible, special case for date/time/end_time */}
      {FIELD_DISPLAY_ORDER.filter(field => {
        // Don't show checkbox if it's a new item or in triage
        if (
          field === 'status' &&
          (!item || !item.id || item.type === TYPE_NEW)
        ) {
          return false;
        }
        return visibleFields[field];
      }).map(name => {
        if (name === 'time') {
          // Special case for `time` field

          return (
            <div className="time-field-container" key="time">
              <itemFields.time
                value={formData.time}
                formData={formData}
                onChange={time => {
                  let update = { time };

                  /* If there is an end time and start time changed then
                     update the end time to keep the duration the same  */
                  if (formData.end_time) {
                    update.end_time = followTime(
                      formData.end_time,
                      formData.time,
                      time,
                    );
                  } else if (!formData.end_time || time > formData.end_time) {
                    /* If end_time is blank or before time, also set end_time */
                    update.end_time = moment(formData.date + ' ' + time)
                      .add(30, 'minutes')
                      .format('HH:mm');
                    endTimeRef && endTimeRef.current.setOpen(true);
                  }

                  setFormDataAndDirty({ ...formData, ...update }, 'time');
                  setFormDataAndDirty({ ...formData, ...update }, 'end_time');
                }}
              />
              <itemFields.end_time
                value={formData.end_time}
                formData={formData}
                onChange={end_time =>
                  setFormDataAndDirty({ ...formData, end_time }, 'end_time')
                }
                onClose={
                  // Only allow closing if field isn't mandatory (preset to visible)
                  !FIELD_TYPE_PRESETS[type].visible.time &&
                  (() => {
                    setVisibleFields({
                      ...visibleFields,
                      time: false,
                    });
                    setCacheData({
                      ...cacheData,
                      time: formData.time,
                      end_time: formData.end_time,
                    });
                    setFormData({ ...formData, time: null, end_time: null });
                  })
                }
                ref={endTimeRef}
              />
            </div>
          );
        } else if (name === 'date') {
          // Special case for `date` field

          if (hasRelatedEvents) return null;

          return (
            <itemFields.date
              key={'date'}
              value={formData.date}
              formData={formData}
              autoFocus={!formData.date && type !== TYPE_EVENT}
              onChange={date => setFormDataAndDirty({ ...formData, date })}
              onMoreDatesClick={
                showAddMoreDates
                  ? () => {
                      setType(TYPE_SERIES);
                      setFormDataAndDirty({
                        ...formData,
                        date: undefined,
                        type: TYPE_SERIES,
                        linked_items: [
                          ...(formData.linked_items || []),
                          {
                            title: formData.title,
                            description: formData.description,
                            location: formData.location,
                            tags: formData.tags,
                            internalTags: formData.internalTags,
                            priority: formData.priority,
                            time: formData.time,
                            end_time: formData.end_time,
                            type: null,
                            tId: `temp-${TYPE_EVENT}`,
                            date: formData.date,
                            assigned_to_ids: formData.assigned_to_ids,
                            dirty: true,
                          },
                        ],
                      });
                    }
                  : undefined
              }
              onClose={
                // Only allow closing if field isn't mandatory (preset to visible)
                !FIELD_TYPE_PRESETS[type].visible.date &&
                (() => {
                  setVisibleFields({
                    ...visibleFields,
                    date: false,
                    time: false,
                  });
                  setCacheData({
                    ...cacheData,
                    date: formData.date,
                    time: formData.time,
                    end_time: formData.end_time,
                  });
                  setFormData({
                    ...formData,
                    date: null,
                    time: null,
                    end_time: null,
                  });
                })
              }
            />
          );
        } else {
          // All other fields

          const FieldComponent = itemFields[name];
          return (
            <FieldComponent
              key={name}
              value={formData[name]}
              formData={formData}
              onChange={value =>
                setFormDataAndDirty({ ...formData, [name]: value }, name)
              }
              onClose={
                // Only allow closing if field isn't mandatory (preset to visible)
                !FIELD_TYPE_PRESETS[type].visible[name] &&
                (() => {
                  setVisibleFields({
                    ...visibleFields,
                    [name]: false,
                  });
                  setFormData({ ...formData, [name]: null });
                  setCacheData({
                    ...cacheData,
                    [name]: formData[name],
                  });
                })
              }
            />
          );
        }
      })}
      <ItemFieldTray
        type={type}
        visibleFields={visibleFields}
        setVisibleFields={setVisibleFields}
        formData={formData}
        setFormData={setFormData}
        cacheData={cacheData}
      />
      {/* Priority */}
      {formData.date && (
        <itemFields.priority
          value={formData.priority}
          formData={formData}
          onChange={priority => setFormDataAndDirty({ ...formData, priority })}
        />
      )}
      {!!formErrors.length && (
        <div className="errors">
          {formErrors.map(error => (
            <div key={error}>{error}</div>
          ))}
        </div>
      )}
      {/* Description */}
      <itemFields.description
        value={formData.description}
        formData={formData}
        onChange={description =>
          setFormDataAndDirty({ ...formData, description }, 'description')
        }
      />
      {/* Attachment Pills */}
      <itemFields.attachmentPills
        value={formData.attachments}
        onChange={attachments =>
          setFormDataAndDirty({ ...formData, attachments })
        }
        formData={formData}
      />
      {/* Related Events */}
      {!parentTitle && hasRelatedEvents && (
        <itemFields.related
          type={TYPE_EVENT}
          value={(formData.linked_items || []).filter(child => child.date)}
          formData={formData}
          onChange={linked_items =>
            setFormDataAndDirty({
              ...formData,
              linked_items: [
                ...(formData.linked_items || []).filter(child => !child.date),
                ...linked_items,
              ],
            })
          }
          openChild={openChild}
        />
      )}
      {/* Checklist */}
      {!parentTitle && (
        <itemFields.related
          type={TYPE_TODO}
          value={(formData.linked_items || []).filter(child => child.status)}
          onChange={linked_items =>
            setFormDataAndDirty({
              ...formData,
              linked_items: [
                ...(formData.linked_items || []).filter(child => !child.status),
                ...linked_items,
              ],
            })
          }
          openChild={openChild}
        />
      )}
    </form>
  );
};
