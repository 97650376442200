import React, { useState, useEffect } from 'react';
import moment from 'moment';
import { callApi } from '../../utils/callApi';
import { track } from '../../utils/analytics';
import { validateEmail } from '../../shared_modules/contactUtils';
import { Card, CardBody } from '../Home/components/Card';
import { MiloModal, MiloModalContent } from '../Home/components/MiloModal';
import { Button } from '../Home/components/Button';
import { ItemFieldWrapper } from '../Home/components/ItemForm/ItemFields';
import { Level } from '../Home/components/Level';
import './FamilyModal.scss';

const REFERRAL_INTERVAL = 3;

const ReferralsModal = ({ isOpen, close }) => {
  const [form, setForm] = useState({});
  const [errors, setErrors] = useState([]);
  const [referrals, setReferrals] = useState([]);

  useEffect(() => {
    callApi('GET', 'api/referrals').then(setReferrals);
  }, []);

  function reset() {
    setForm({});
    setErrors([]);
  }

  async function add() {
    if (!validateEmail(form.email)) {
      setErrors(['Invalid email address.']);
      return;
    }

    try {
      const newReferrals = await callApi(
        'PUT',
        'api/referrals',
        undefined,
        form,
      );
      setReferrals(newReferrals);
      reset();
      track('settings:referrals:send');
    } catch (error) {
      if ([400, 409].includes(error.response.status)) {
        setErrors([error.response.data.error]);
      }
    }
  }

  let numReferrals = REFERRAL_INTERVAL;

  // Determine if we should add more referrals
  if (referrals.length) {
    // Start with however many referral intervals are greater than the number they've already sent
    numReferrals =
      Math.ceil(referrals.length / REFERRAL_INTERVAL) * REFERRAL_INTERVAL;

    // Sort existing referrals by date and get the last date one was sent
    const sorted = referrals.map(r => r.created).sort();
    const lastDateSent = moment(sorted[sorted.length - 1]);

    // If the user's sent referrals divides evenly by the interval AND their last sent one is more
    // a day old, add another batch of referrals.
    if (
      !(referrals.length % REFERRAL_INTERVAL) &&
      lastDateSent.isBefore(moment(), 'day')
    ) {
      numReferrals += REFERRAL_INTERVAL;
    }
  }

  return (
    <MiloModal
      isOpen={isOpen}
      size="narrow"
      onRequestClose={() => {
        close();
        reset();
      }}
      title="Gift your friends a month of Milo"
    >
      <MiloModalContent>
        <div className="FamilyModal">
          <Card>
            <CardBody>
              <h2>
                You have {numReferrals - referrals.length} invite
                {numReferrals - referrals.length !== 1 && 's'}
              </h2>
            </CardBody>

            {referrals.length >= numReferrals && (
              <CardBody border="top">
                You’ve used all of your invites—check back tomorrow for more!
              </CardBody>
            )}

            {referrals.length < numReferrals && (
              <>
                <CardBody border="top">
                  Give a friend the gift of trying Milo for a month - send an
                  invite{' '}
                  <span role="img" aria-label="love letter emoji">
                    💌
                  </span>{' '}
                  to a friend and they’ll skip the waitlist. Just add their
                  email address below and we’ll take care of the rest!
                </CardBody>

                <CardBody border="top">
                  <ItemFieldWrapper>
                    <input
                      value={form.email || ''}
                      onChange={evt =>
                        setForm({ ...form, email: evt.target.value })
                      }
                      type="text"
                      placeholder="Email"
                    />
                  </ItemFieldWrapper>
                </CardBody>

                {!!errors.length && (
                  <CardBody border="top">
                    {errors.map(error => (
                      <div key={error} className="error">
                        {error}
                      </div>
                    ))}
                  </CardBody>
                )}

                <CardBody border="top">
                  <Level>
                    <div>{/* empty div to push button to the right */}</div>
                    <Button onClick={add} disabled={!form.email}>
                      <box-icon name="mail-send" style={{ marginRight: 4 }} />
                      Invite
                    </Button>
                  </Level>
                </CardBody>
              </>
            )}
          </Card>

          <Card>
            <CardBody>
              <h2>
                Your invites ({referrals.length} of {numReferrals} sent)
              </h2>
            </CardBody>
            <CardBody border="top">
              {referrals.map(referral => (
                <div key={referral.email}>
                  <h3>
                    {referral.email} ({referral.joined ? 'joined' : 'invited'})
                  </h3>
                </div>
              ))}
              {!referrals.length && <p>No invites sent.</p>}
            </CardBody>
          </Card>
        </div>
      </MiloModalContent>
    </MiloModal>
  );
};

export default ReferralsModal;
