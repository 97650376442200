import 'boxicons';
import moment from 'moment';
import React, { useState } from 'react';
import { connect } from 'react-redux';
import { openItemFormModal } from '../../../redux/itemFormModal';
import { Card, CardBody } from '../components/Card';
import { Dash2EmptyState } from './Dash2EmptyState';
import { MiniItem } from '../components/MiniItem';
import { Level } from '../components/Level';
import { H2 } from '../components/Type';
import { DATE_FORMAT } from '../constants';
import MiloCalendarCard from '../components/MiloCalendarCard';
import { ItemFieldWrapper } from '../components/ItemForm/ItemFields';
import { Button } from '../components/Button';
import { sortItemsByTime } from '../utils';
import './Tomorrow.scss';

const suggestions = [
  'Jot down something your family can’t forget tomorrow!',
  'Add a to do that needs to get done',
  'Plans for dinner? Add them here',
  'Do the kids have activities tomorrow? Write them down!',
  'Add something fun for the family',
];

const Tomorrow = ({
  miloCalendar,
  items,
  saveItem,
  cardMarkComplete,
  hideTitle,
}) => {
  const [editedItemTitle, setEditedItemTitle] = useState('');
  const [suggestion, setSuggestion] = useState(0);
  const date = moment().add(1, 'day').format(DATE_FORMAT);
  const tomorrowItems = sortItemsByTime(
    items.filter(item => item.date === date),
  );

  function onTextChanged(evt) {
    setEditedItemTitle(evt.target.value);
  }

  function addItem() {
    saveItem({ title: editedItemTitle, date });
    setEditedItemTitle('');
    setSuggestion(suggestion < suggestions.length - 1 ? suggestion + 1 : 0);
  }

  function handleKeyDown(evt) {
    if ([13].includes(evt.keyCode)) {
      addItem();
    }
  }

  return (
    <Card className="Tomorrow" key="tomorrow">
      <CardBody>
        {!hideTitle && <h2 style={{ marginBottom: 8 }}>Plan for tomorrow</h2>}

        <p>
          {!!tomorrowItems.length &&
            'This plan will be emailed to your family at 2am PST'}
          {!tomorrowItems.length &&
            'Anything you add to this plan will be emailed to your family at 2am PST'}
        </p>
      </CardBody>
      <CardBody>
        <ItemFieldWrapper className="attached-button">
          <input
            value={editedItemTitle}
            type="text"
            onChange={onTextChanged}
            onKeyDown={handleKeyDown}
            placeholder="Type and hit enter…"
          />
          <Button type="button" onClick={addItem} variant="large">
            Add
          </Button>
        </ItemFieldWrapper>
      </CardBody>

      {miloCalendar &&
        miloCalendar
          .filter(record => record.date === date)
          .map(record => (
            <CardBody key={record.title} border="top" size="short">
              <MiloCalendarCard record={record} />
            </CardBody>
          ))}
      {tomorrowItems.map(item => (
        <MiniItem
          key={item.id}
          item={item}
          cardMarkComplete={cardMarkComplete}
        />
      ))}

      <CardBody border="top" size="short">
        {suggestions[suggestion]}
      </CardBody>
    </Card>
  );
};

export default connect(
  state => ({ auth: state.auth, itemFormModal: state.itemFormModal }),
  { openItemFormModal },
)(Tomorrow);
