import React from 'react';
import classNames from 'classnames';
import './Button.scss';

export const Button = props => (
  <button
    className={classNames('Button', props.variant, props.className)}
    style={props.style}
    onClick={props.onClick}
    type={props.type || 'submit'}
    disabled={props.disabled}
  >
    {props.children}
  </button>
);
