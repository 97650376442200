import React, { useState } from 'react';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import { callApi } from '../../utils/callApi';
import { storeLogin, clearPasswordRequired } from '../../redux/auth';
import {
  MiloModal,
  MiloModalContent,
  MiloModalButtons,
} from '../Home/components/MiloModal';
import { Button } from '../Home/components/Button';
import { ItemFieldWrapper } from '../Home/components/ItemForm/ItemFields';

import './ChangePassword.scss';

const ChangePassword = props => {
  const {
    closeForm,
    passwordRequired,
    dispatchClearPasswordRequired,
    history,
  } = props;

  const [currentPassword, setCurrentPassword] = useState('');
  const [newPassword, setNewPassword] = useState('');
  const [confirmPassword, setConfirmPassword] = useState('');
  const [errors, setErrors] = useState([]);
  const [loading, setLoading] = useState(false);

  async function handleSubmitClick() {
    const formErrors = [];

    if (!passwordRequired && !(currentPassword && currentPassword.length)) {
      formErrors.push('Please enter current password');
    }

    if (!(newPassword && newPassword.length)) {
      formErrors.push('Please enter new password');
    }

    if (newPassword && newPassword.localeCompare(confirmPassword)) {
      formErrors.push('Password and confirm must match');
    }
    if (formErrors.length) {
      setErrors(formErrors);
      return;
    }

    setLoading(true);
    try {
      const body = {
        current: currentPassword,
        password: newPassword,
      };
      const res = await callApi('PUT', 'login', undefined, body);

      if (res === 202) {
        if (passwordRequired) {
          await dispatchClearPasswordRequired();
          history.push('/');
        }
        closeForm();
        return;
      }
    } catch (error) {
      if (error.response && error.response.data) {
        setErrors([error.response.data.error]);
      }
    }

    setLoading(false);
  }

  return (
    <MiloModal
      className="ChangePassword"
      isOpen={true}
      onRequestClose={passwordRequired ? null : closeForm}
      title={passwordRequired ? 'Set Password' : 'Change Password'}
      size="narrow"
      hideBackButton={passwordRequired}
    >
      <MiloModalContent>
        <p>Security starts with a good password. Try to use:</p>

        <ul>
          <li>8 or more characters</li>
          <li>At least one capital letter</li>
          <li>At least one number</li>
        </ul>
        {!passwordRequired && (
          <ItemFieldWrapper>
            <input
              autoFocus={!passwordRequired}
              disabled={loading}
              onChange={evt => setCurrentPassword(evt.target.value)}
              placeholder="Current password"
              type="password"
            />
          </ItemFieldWrapper>
        )}

        <ItemFieldWrapper>
          <input
            autoFocus={passwordRequired}
            disabled={loading}
            onChange={evt => setNewPassword(evt.target.value)}
            placeholder="New password"
            type="password"
          />
        </ItemFieldWrapper>

        <ItemFieldWrapper>
          <input
            disabled={loading}
            onChange={evt => setConfirmPassword(evt.target.value)}
            placeholder="Confirm new password"
            type="password"
          />
        </ItemFieldWrapper>

        {errors && (
          <div style={{ color: 'red' }}>
            {errors.map(error => (
              <div key={error}>{error}</div>
            ))}
          </div>
        )}
      </MiloModalContent>

      <MiloModalButtons>
        {!passwordRequired && (
          <Button
            onClick={closeForm}
            variant="secondary"
            className="hide-mobile"
          >
            Cancel
          </Button>
        )}

        <Button disabled={loading} onClick={handleSubmitClick}>
          Save
        </Button>
      </MiloModalButtons>
    </MiloModal>
  );
};

export default connect(() => ({}), {
  handleStoreLogin: storeLogin,
  dispatchClearPasswordRequired: clearPasswordRequired,
})(withRouter(ChangePassword));
