import React, { useEffect, useState } from 'react';
import { connect } from 'react-redux';
import classNames from 'classnames';
import { track } from '../../../../utils/analytics';
import { Button } from '../../components/Button';
import { Card, CardItems, CardBody } from '../../components/Card';
import { Item } from '../../components/Item';
import { Level } from '../../components/Level';
import { openItemFormModal } from '../../../../redux/itemFormModal';
import {
  MiloModal,
  MiloModalButtons,
  MiloModalContent,
} from '../../components/MiloModal';
import { ItemFieldWrapper } from '../../components/ItemForm/ItemFields';
import { WIDGET_NAME } from './';
import ItemFormModal from '../../ItemFormModal/ItemFormModal';
import { COMPLETE, COMPLETABLE } from '../../constants';
import Placeholder from './Placeholder';
import HelpButton from '../../components/HelpButton';

import './TopThree.scss';

const TopThreeEdit = props => {
  const {
    open,
    onRequestClose,
    todoItems,
    cardMarkComplete,
    items,
    newItem,
    setItem,
    setItems,
    applyItems,
    dirty,
    setDirty,
  } = props;

  const [topThreeItems, setTopThreeItems] = useState([]);
  const [editItem, setEditItem] = useState();
  const [filter, setFilter] = useState('');
  const [animating, setAnimating] = useState(0);

  useEffect(() => {
    if (open) {
      track(`${WIDGET_NAME}:edit:open`);
    }
  }, [open]);

  // Find the items tagged with top-three in all to dos and bring them into the widget
  useEffect(() => {
    if (dirty) return;

    setItems(
      todoItems.filter(item => item.tags && item.tags.includes(WIDGET_NAME)),
    );
  }, [todoItems, setItems, dirty]);

  // Order the actual top three for display
  useEffect(() => {
    setTopThreeItems(
      [1, 2, 3].map(pri =>
        items.find(
          item =>
            item.tags &&
            item.tags.includes(WIDGET_NAME) &&
            item.data &&
            item.data.top_three_priority === pri,
        ),
      ),
    );
  }, [items]);

  const filteredTodoItems = todoItems.filter(item => {
    if (item.status !== COMPLETABLE) {
      return false;
    }
    if (topThreeItems.find(block => block && block.id === item.id)) {
      return false;
    }
    const checked = filter.split(' ').map(word => {
      const result =
        item.title && item.title.toLowerCase().includes(word.toLowerCase());
      return result;
    });
    return !checked.includes(false);
  });

  function add(itemId, priority) {
    const item = todoItems.find(item => item.id === itemId);
    if (!item) return;
    const tags = `${(item.tags || '').replace(
      /top-three/g,
      '',
    )} ${WIDGET_NAME}`.trim();
    setItem({
      ...item,
      tags,
      data: { ...item.data, top_three_priority: priority },
    });
  }

  // Remove the tag from the item
  // Leave the priority so that we know it was once a top-three
  function remove(itemId) {
    const item = items.find(item => item.id === itemId);
    if (!item) return;
    setItem({
      ...item,
      tags: item.tags.replace(/top-three/g, '').trim(),
    });
  }

  function removeCompleted() {
    const updatedItems = items.map(item => {
      if (item.status === COMPLETE) {
        setDirty(true);
        return {
          ...item,
          tags: item.tags.replace(/top-three/g, '').trim(),
          dirty: true,
        };
      }
      return item;
    });
    setItems(updatedItems);
  }

  function createItem(title, priority) {
    const item = newItem({
      title,
      data: { top_three_priority: priority },
      status: 'completable',
      tags: WIDGET_NAME,
    });
    setItem(item);
  }

  function save() {
    applyItems();
    track(`${WIDGET_NAME}:edit:save`);
    onRequestClose();
  }

  function close() {
    const warning = 'Leaving will lose any changes. Are you sure?';
    if (!dirty || window.confirm(warning)) {
      setDirty(false);
      onRequestClose();
    }
  }

  return (
    <>
      <MiloModal
        className="TopThreeEdit"
        isOpen={open}
        onRequestClose={close}
        size="narrow"
        title="Top 3 priorities"
      >
        <MiloModalContent>
          <div className="subtitle">
            <Level variant="center">
              <p>
                The <strong>top three things</strong> you’re going to get done
                this week.
              </p>
              <HelpButton id={3} />
            </Level>
          </div>
          <Card>
            <CardItems>
              {topThreeItems.map((item, idx) => {
                return item ? (
                  <Item
                    key={item.id}
                    item={item.newItem ? { ...item, status: undefined } : item}
                    cardMarkComplete={cardMarkComplete}
                    showDate={true}
                    openItemForm={() => setEditItem(item)}
                    button={
                      <div
                        className={classNames('top-three-toggle-button on', {
                          animating: animating === item.id,
                        })}
                        onClick={evt => {
                          setAnimating(item.id);
                          window.setTimeout(() => {
                            remove(item.id);
                            setAnimating(0);
                          }, 500);
                          evt.stopPropagation();
                        }}
                      >
                        <box-icon name="star" type="solid" size="md" />
                      </div>
                    }
                  />
                ) : (
                  <Placeholder
                    key={idx}
                    onSelectHistorical={({ id }) => add(id, idx + 1)}
                    onSelectCreateNew={title => createItem(title, idx + 1)}
                  />
                );
              })}
            </CardItems>
          </Card>
          <Button
            variant="secondary"
            onClick={removeCompleted}
            style={{ margin: '16px auto', display: 'block' }}
          >
            <box-icon name="check-double" style={{ marginRight: 4 }} />
            Archive Completed
          </Button>
          <h2 className="sans" style={{ margin: '32px 4px 16px' }}>
            Add an existing To Do
          </h2>
          <Card>
            <CardBody>
              <ItemFieldWrapper>
                <input
                  type="text"
                  onChange={e => setFilter(e.target.value)}
                  placeholder="Search for a To Do…"
                  value={filter}
                />
              </ItemFieldWrapper>
            </CardBody>
            <CardItems>
              {filteredTodoItems.map(item => (
                <Item
                  key={item.id}
                  item={item}
                  showDate={true}
                  hideCheckbox={true}
                  button={
                    <div
                      className={classNames('top-three-toggle-button off', {
                        animating: animating === item.id,
                      })}
                      onClick={evt => {
                        const freeBlock = topThreeItems.findIndex(
                          block => !block,
                        );
                        if (freeBlock >= 0) {
                          setAnimating(item.id);
                          window.setTimeout(() => {
                            add(item.id, freeBlock + 1);
                            setFilter('');
                            setAnimating(0);
                          }, 500);
                        }

                        evt.stopPropagation();
                      }}
                    >
                      <box-icon name="star" type="solid" size="md" />
                    </div>
                  }
                />
              ))}
              {/*filter && (
                <Item
                  key={'new-item'}
                  item={{ title: `New to do: “${filter}”` }}
                  showDate={true}
                  hideCheckbox={true}
                  onClick={evt => {
                    const freeBlock = topThreeItems.findIndex(block => !block);
                    if (freeBlock >= 0) {
                      setAnimating('new-item');
                      window.setTimeout(() => {
                        createItem(filter, freeBlock + 1);
                        setFilter('');
                        setAnimating(0);
                      }, 500);
                    }

                    evt.stopPropagation();
                  }}
                  button={
                    <div
                      className={classNames('top-three-toggle-button off', {
                        animating: animating === 'new-item',
                      })}
                      onClick={evt => {
                        const freeBlock = topThreeItems.findIndex(
                          block => !block,
                        );
                        if (freeBlock >= 0) {
                          setAnimating('new-item');
                          window.setTimeout(() => {
                            createItem(filter, freeBlock + 1);
                            setFilter('');
                            setAnimating(0);
                          }, 500);
                        }

                        evt.stopPropagation();
                      }}
                    >
                      <box-icon name="star" type="solid" size="md" />
                    </div>
                  }
                />
              )*/}
            </CardItems>
          </Card>
        </MiloModalContent>
        <MiloModalButtons>
          <Button variant="secondary" onClick={close} className="hide-mobile">
            Cancel
          </Button>
          <Button onClick={save}>Save</Button>
        </MiloModalButtons>
      </MiloModal>
      <ItemFormModal
        open={!!editItem}
        requestClose={setEditItem}
        item={editItem}
        saveItem={setItem}
        deleteItem={() => {
          remove(editItem.id);
          setEditItem();
        }}
        hideSidePanel={true}
        openItemFormModal={openItemFormModal}
      />
    </>
  );
};

export default connect(state => ({}), {
  openItemFormModal,
})(TopThreeEdit);
