import { getEmojiDataFromNative } from 'emoji-mart';
import emojiData from 'emoji-mart/data/all.json';
import pluralize from 'pluralize';
import React, { useCallback, useEffect, useRef, useState } from 'react';
import { connect } from 'react-redux';
import { track } from '../../../../utils/analytics';
import { Button } from '../../components/Button';
import { Card, CardBody } from '../../components/Card';
import { ItemFieldWrapper } from '../../components/ItemForm/ItemFields';
import { Level } from '../../components/Level';
import {
  MiloModal,
  MiloModalButtons,
  MiloModalContent,
  MiloModalControls,
} from '../../components/MiloModal';
import UniversalSearch from '../../components/UniversalSearch';
import { DAYS_OF_WEEK } from '../../constants';
import ItemFormModal from '../../ItemFormModal/ItemFormModal';
import WeekSelect from '../../Week/WeekSelect';
import { WIDGET_NAME } from './';
import './MealPlannerEdit.scss';
import MiloPicksButton from './MiloPicksButton';
import MiniTimePicker from './MiniTimePicker';
import { saveWeekCollapsed } from '../../Week/Week';

const TAG_MEAL = 'meal';
const MEALS = [
  {
    emoji: '🍳',
    emojiName: 'cooking',
    name: 'Breakfast',
    key: 'breakfast',
    time: '07:00',
  },
  {
    emoji: '🍞',
    emojiName: 'bread',
    name: 'Lunch',
    key: 'lunch',
    time: '12:00',
  },
  {
    emoji: '🍽',
    emojiName: 'fork and knife with plate',
    name: 'Dinner',
    key: 'dinner',
    time: '18:00',
  },
];

const MealPlannerEdit = connect(state => ({ auth: state.auth }))(props => {
  const {
    open,
    startDate,
    dirty,
    loadItems,
    applyItems,
    onRequestClose,
    setStartDate,
    auth,
    thisWeeksSuggestions,
    globalWeekStartDate,
    globalWeekBack,
    globalWeekForward,
  } = props;

  // Track global start date
  useEffect(() => {
    setStartDate(globalWeekStartDate);
  }, [setStartDate, globalWeekStartDate]);

  useEffect(() => {
    if (!open) return;
    track(`${WIDGET_NAME}:edit:open`);
  }, [open]);

  useEffect(() => {
    if (!open) return;
    loadItems({ tag: TAG_MEAL });
  }, [open, loadItems, startDate]);

  function closeModal() {
    const warning = 'Leaving will lose any changes. Are you sure?';
    if (!dirty || window.confirm(warning)) onRequestClose();
  }

  function checkDirty() {
    const warning = 'Are you sure you want to lose your changes?';
    return !dirty || window.confirm(warning);
  }

  function onWeekBack() {
    if (checkDirty()) globalWeekBack();
  }

  function onWeekForward() {
    if (checkDirty()) globalWeekForward();
  }

  function save() {
    saveWeekCollapsed('meals', false);
    applyItems();
    track(`${WIDGET_NAME}:edit:save`, { u: auth.userId, f: auth.family_id });
    onRequestClose();
  }

  return (
    <MiloModal
      isOpen={open}
      onRequestClose={closeModal}
      size="narrow"
      title="Meal Planner"
    >
      <MiloModalControls>
        <WeekSelect
          weekStartDate={startDate}
          weekBack={onWeekBack}
          weekForward={onWeekForward}
          hideTodayButton={true}
          showDay={true}
          showBothMonths={true}
        />
      </MiloModalControls>
      <MiloModalContent>
        <div className="MealPlannerEdit">
          {MEALS.map(meal => (
            <Meal
              key={meal.name}
              meal={meal}
              widgetProps={props}
              thisWeeksSuggestions={thisWeeksSuggestions}
            />
          ))}
        </div>
      </MiloModalContent>
      <MiloModalButtons>
        <Button
          variant="secondary"
          onClick={closeModal}
          className="hide-mobile"
        >
          Cancel
        </Button>
        <Button onClick={save}>Save</Button>
      </MiloModalButtons>
    </MiloModal>
  );
});

const Meal = props => {
  const { meal, widgetProps, thisWeeksSuggestions } = props;
  const { items, dateOnIndex, newItem, setItems, setDirty } = widgetProps;
  const hideStorageKey = `${WIDGET_NAME}:${meal.key}:hide`;
  const timeStorageKey = `${WIDGET_NAME}:${meal.key}:time`;

  const [preferHide, setPreferHide] = useState(
    window.localStorage.getItem(hideStorageKey),
  );
  const time = window.localStorage.getItem(timeStorageKey) || meal.time;
  const setTime = useCallback(
    newTime => {
      window.localStorage.setItem(timeStorageKey, newTime);
    },
    [timeStorageKey],
  );
  const [loading, setLoading] = useState(false);

  const suggestion_type = `${TAG_MEAL}-${meal.key}`;
  const suggestions = thisWeeksSuggestions.filter(
    suggestion => suggestion.suggestion_type === suggestion_type,
  );

  const mealItems = items.filter(
    item => item.tags && item.tags.includes(meal.key) && !item.deleted,
  );

  const hide = mealItems.length === 0 && preferHide;

  function setMealItems(newMealItems) {
    const otherMealItems = items.filter(item => !item.tags.includes(meal.key));
    setItems([...otherMealItems, ...newMealItems]);
    setDirty(true);
  }

  useEffect(() => {
    if (preferHide) {
      window.localStorage.setItem(hideStorageKey, true);
    } else {
      window.localStorage.removeItem(hideStorageKey);
    }
  }, [preferHide, hideStorageKey]);

  useEffect(() => {
    if (mealItems && mealItems.length > 0) {
      setTime(mealItems[0].time);
    }
  }, [mealItems, setTime]);

  function hideMeal() {
    if (mealItems.length > 0) {
      setMealItems(mealItems.map(item => ({ ...item, deleted: true })));
    }
    setPreferHide(true);
  }

  function showMeal() {
    setPreferHide(false);
  }

  function onChangeTime(time) {
    setTime(time);
    const newItems = mealItems.map(item => ({
      ...item,
      time: time,
      end_time: time,
      dirty: true,
    }));
    setMealItems(newItems);
    setDirty(true);
  }

  function askMilo() {
    if (loading) return;
    setLoading(true);
    const warning = `Replace current ${pluralize(
      meal.name,
    )} with Milo suggestions?`;
    if (mealItems.length > 0 && !window.confirm(warning)) {
      setLoading(false);
      return;
    }
    const cleanItems = items.filter(item => !item.tags.includes(meal.key));

    const deletedItems = items
      .filter(item => item.tags.includes(meal.key))
      .map(item => ({ ...item, deleted: true }));

    const newItems = suggestions.map((suggestion, index) =>
      newItem({
        title: suggestion.title.includes(meal.name)
          ? suggestion.title
          : `${meal.name}: ${suggestion.title}`,
        description: suggestion.description,
        tags: `${TAG_MEAL} ${meal.key}`,
        date: dateOnIndex(index),
        time: time,
        data: { source: WIDGET_NAME },
        emoji:
          suggestion.emoji &&
          getEmojiDataFromNative(suggestion.emoji, 'apple', emojiData),
      }),
    );

    setItems([...cleanItems, ...newItems, ...deletedItems]);
    setDirty(true);
    setLoading(false);
  }

  const mealDays = DAYS_OF_WEEK.map(
    (_, index) =>
      mealItems.find(
        item =>
          item.date === dateOnIndex(index) &&
          item.tags.includes(TAG_MEAL) &&
          item.tags.includes(meal.key),
      ) ||
      newItem({
        tags: `${TAG_MEAL} ${meal.key}`,
        date: dateOnIndex(index),
        time: time,
        end_time: time,
        send_invite: true,
        data: { source: WIDGET_NAME },
      }),
  );
  return (
    <Card>
      {!hide && <box-icon onClick={hideMeal} name="x" />}
      {hide && <box-icon onClick={showMeal} name="plus" />}
      <CardBody border={!hide && 'bottom'}>
        <Level>
          <h2>
            <span role="img" aria-label={`${meal.emojiName} emoji`}>
              {meal.emoji}
            </span>{' '}
            {meal.name}
          </h2>
          {!hide && (
            <MiniTimePicker
              value={time}
              onChange={onChangeTime}
              formData={mealDays[0]}
            />
          )}
        </Level>
        {!hide && suggestions.length > 0 && (
          <MiloPicksButton
            options={[
              {
                title: 'Milo Lineup',
                description: 'A ready-to-go lineup of yummy and easy meals.',
                onClick: askMilo,
              },
              {
                title: 'Coming Soon: My Meals',
                description: 'A selection of your favs and go-tos.',
              },
              {
                title: 'Coming Soon: Copy Last Week',
                description: 'Start with your regular base. Easy-peasy.',
              },
            ]}
          />
        )}

        {hide && <p>Removed from this meal plan.</p>}
      </CardBody>
      {!hide && (
        <CardBody>
          {mealDays.map((item, index) => (
            <MealDay
              key={index}
              day={DAYS_OF_WEEK[index]}
              item={item}
              mealName={meal.name}
              widgetProps={widgetProps}
            />
          ))}
        </CardBody>
      )}
    </Card>
  );
};

const MealDay = props => {
  const { day, item, mealName, widgetProps } = props;
  const { setItem } = widgetProps;
  const [showResults, setShowResults] = useState(false);
  const [editItem, setEditItem] = useState();
  const ref = useRef(null);

  const title = item && item.title && item.title.replace(`${mealName}: `, '');

  function onChange(value) {
    setItem({
      ...item,
      title: value && `${mealName}: ${value}`,
    });
  }

  // Search filter
  function queryFilter(result) {
    if (result.keywords) {
      // It's a suggestion from airtable
      if (!result.keywords.includes(mealName.toLowerCase())) {
        return false;
      }
    } else {
      // Searching historical
      // Anything with category meal but if it was created
      // by the meal planner then the meal name (e.g. lunch)
      // needs to match;
      const tags = result.tags || '';
      const fromMealPlanner =
        tags.toLowerCase().includes('meal') &&
        (tags.toLowerCase().includes('breakfast') ||
          tags.toLowerCase().includes('lunch') ||
          tags.toLowerCase().includes('dinner'));
      if (fromMealPlanner) {
        if (!tags.toLowerCase().includes(mealName.toLowerCase())) {
          return false;
        }
      }
    }
    return true;
  }

  return (
    <div className="meal-day">
      <div className="day">{day.substring(0, 2)}</div>
      <ItemFieldWrapper ref={ref}>
        <input
          placeholder="Add meal..."
          value={title || ''}
          onChange={evt => onChange(evt.target.value)}
          onFocus={() => setShowResults(true)}
          onClick={() => setShowResults(true)}
        />
        <UniversalSearch
          isOpen={showResults}
          close={() => setShowResults(false)}
          query={`meal ${title || ''}`}
          queryFilter={queryFilter}
          hideCreateNew={true}
          onSelectHistorical={({
            title,
            description,
            emoji,
            location,
            tags,
            id,
          }) => {
            setShowResults(false);
            setItem({
              ...item,
              title,
              description,
              emoji,
              location,
              tags: [...new Set(`${item.tags} ${tags}`.split(' '))].join(' '),
              data: { ...item.data, historicalId: id },
            });
          }}
          onSelectSuggested={({ title, description, emoji, id }) => {
            setShowResults(false);
            setItem({
              ...item,
              title,
              description,
              emoji: emoji && getEmojiDataFromNative(emoji, 'apple', emojiData),
              data: { ...item.data, suggestionId: id },
            });
          }}
          wrapperRef={ref}
        />
      </ItemFieldWrapper>
      {item.title && (
        <Button variant="clear" onClick={() => setEditItem(item)}>
          <box-icon name="edit" />
        </Button>
      )}
      <ItemFormModal
        open={!!editItem}
        requestClose={setEditItem}
        item={item}
        saveItem={setItem}
        deleteItem={() => {
          setItem({ ...item, title: '' });
          setEditItem();
        }}
        hideSidePanel={true}
      />
    </div>
  );
};

export default MealPlannerEdit;
