import classNames from 'classnames';
import React, { useRef, useState } from 'react';
import { Dropdown, Option } from '../Dropdown';
import './TypeSwitcher.scss';

const TypeSwitcher = props => {
  const [open, setOpen] = useState(false);
  const ref = useRef(null);

  return (
    <div
      className={classNames('TypeSwitcher', {
        enabled: props.setType,
      })}
      onClick={props.setType ? () => setOpen(!open) : undefined}
      ref={ref}
    >
      <span>
        {props.new && 'New '}
        {props.name}
      </span>
      <box-icon name="chevron-down" size="sm" />
      {props.setType && (
        <Dropdown isOpen={open} close={() => setOpen(false)} wrapperRef={ref}>
          <Option
            icon={<box-icon name={'calendar-alt'} />}
            onClick={() => props.setType('event')}
          >
            {props.new && 'New '}Event
          </Option>
          <Option
            icon={<box-icon name={'check-square'} />}
            onClick={() => props.setType('todo')}
          >
            {props.new && 'New '}To Do
          </Option>
          <Option
            icon={<box-icon name={'file'} />}
            onClick={() => props.setType('file')}
          >
            {props.new && 'New '}File
          </Option>
        </Dropdown>
      )}
    </div>
  );
};

export default TypeSwitcher;
