import React, { useState, useRef } from 'react';
import { PlaceholderItem } from '../../components/Item';
import { ItemFieldWrapper } from '../../components/ItemForm/ItemFields';
import UniversalSearch from '../../components/UniversalSearch';
import { COMPLETABLE } from '../../constants';

import './TopThree.scss';

const Placeholder = props => {
  const [value, setValue] = useState('');
  const [showResults, setShowResults] = useState(false);
  const ref = useRef(null);

  return (
    <PlaceholderItem className="TopThreePlaceHolder">
      <box-icon name="plus" />
      <ItemFieldWrapper ref={ref}>
        <input
          placeholder="Start typing&hellip;"
          value={value}
          onChange={evt => setValue(evt.target.value)}
          onFocus={() => setShowResults(true)}
          onClick={() => setShowResults(true)}
        />
        <UniversalSearch
          isOpen={showResults}
          close={() => {
            if (showResults) {
              if (value) {
                props.onSelectCreateNew(value);
              }
              setShowResults(false);
            }
          }}
          query={value || '*'}
          onSelectHistorical={props.onSelectHistorical}
          onSelectCreateNew={props.onSelectCreateNew}
          hideSuggested={true}
          wrapperRef={ref}
          params={{ status: COMPLETABLE }}
          itemHeading="Family To Dos"
        />
      </ItemFieldWrapper>
    </PlaceholderItem>
  );
};

export default Placeholder;
