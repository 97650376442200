import moment from 'moment';

export default function weekName(date) {
  let result = '';
  const selected = moment(date);
  const lastWeekStart = moment().subtract(6, 'days').startOf('isoWeek');
  const thisWeekStart = moment().startOf('isoWeek');
  const nextWeekStart = moment().add(7, 'days').startOf('isoWeek');
  const nextWeekEnd = moment().add(7, 'days').endOf('isoWeek');
  const isLastWeek =
    selected.isSameOrAfter(lastWeekStart) && selected.isBefore(thisWeekStart);
  const isThisWeek =
    selected.isSameOrAfter(thisWeekStart) && selected.isBefore(nextWeekStart);
  const isNextWeek =
    selected.isSameOrAfter(nextWeekStart) &&
    selected.isSameOrBefore(nextWeekEnd);

  if (isLastWeek) {
    result = 'Last Week';
  }
  if (isThisWeek) {
    result = 'This Week';
  }
  if (isNextWeek) {
    result = 'Next Week';
  }
  return result;
}
