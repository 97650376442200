import React, { useState } from 'react';
import { Card, CardBody } from '../components/Card';
import { Level } from '../components/Level';

const HelpImagePanel = props => {
  const STORAGE_KEY_HIDDEN = `get-started:${props.storageKey}:hidden`;

  const [hidden, setHidden] = useState(
    !!window.localStorage.getItem(STORAGE_KEY_HIDDEN),
  );

  if (hidden) return null;

  return (
    <Card className="inverse">
      <CardBody>
        <Level variant="start">
          <span>
            <span role="img" aria-label="light bulb emoji">
              💡
            </span>{' '}
            {props.children}
          </span>
          <div
            onClick={() => {
              setHidden(true);
              window.localStorage.setItem(STORAGE_KEY_HIDDEN, 'hidden');
            }}
          >
            <box-icon name="x" />
          </div>
        </Level>
        <img src={props.src} />
      </CardBody>
    </Card>
  );
};

export default HelpImagePanel;
