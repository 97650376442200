import React from 'react';
import { connect } from 'react-redux';
import { open as openModal } from '../../../../redux/modal';

const MealPlannerButton = props => (
  <button
    className="WidgetButton MealPlannerButton"
    type="button"
    onClick={() => props.openModal('meal-planner')}
  >
    <box-icon type="solid" name="pizza" />
    <div>Meal Planner</div>
  </button>
);

export default connect(undefined, {
  openModal,
})(MealPlannerButton);
