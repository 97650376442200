import momentTz from 'moment-timezone';
import React, { useEffect, useState } from 'react';
import ReactSelect, { components as selectComponents } from 'react-select';
import { callApi } from '../../../utils/callApi';
import { ItemFieldWrapper } from '../../Home/components/ItemForm/ItemFields';
import { Level } from '../../Home/components/Level';
import SendButton from '../SendButton';
import {
  validateEmail,
  formatPhone,
  validatePhone,
  validateZip,
} from '../../../shared_modules/contactUtils';

const QUESTION_TEXT = 'Let’s get your account set up';
const INFO_TEXT = `We use email for account-related communication. Your phone number allows you to interact with our SMS assistant. Your info is never shared with a 3rd party.`;

const EmailPhoneQuestion = ({
  onAnswer,
  data,
  isCurrent,
  showComment,
  hash,
}) => {
  const [revisiting, setRevisiting] = useState(false);
  const [nonUS, setNonUS] = useState(data.family.metadata['invalid-zip']);
  const [errors, setErrors] = useState([]);
  const [user, setUser] = useState({ ...data.family.users[0] });
  const [family, setFamily] = useState({
    zip:
      data.family.zip ||
      (data.waitlist.metadata && data.waitlist.metadata.user_zip),
    timezone: data.family.timezone,
  });

  const guessedTimezone = momentTz.tz.guess();
  const timezones = [
    guessedTimezone,
    'US/Eastern',
    'US/Central',
    'US/Mountain',
    'US/Pacific',
  ];

  if (family.timezone && !timezones.includes(family.timezone)) {
    timezones.push(family.timezone);
  }

  useEffect(() => {
    if (
      isCurrent &&
      data.family.timezone &&
      data.family.zip &&
      validateZip(data.family.zip) &&
      data.family.users[0].phone &&
      data.family.users[0].email &&
      data.family.users[0].name &&
      data.family.users[0].last_name
    ) {
      onAnswer();
    }
  }, [data.family, isCurrent, onAnswer]);

  return (
    <div className="EmailPhoneQuestion">
      {!(isCurrent || revisiting) && (
        <>
          <div className="answered-question">{QUESTION_TEXT}</div>
          <div className="response-wrapper">
            <div className="response" onClick={() => setRevisiting(true)}>
              {user.name} {user.last_name}
              <br />
              {user.phone}
              <br />
              {user.email}
              <br />
              {family.zip}
              <br />
              {family.timezone || data.family.timezone}
              <br />
              <box-icon name="check" />
            </div>
          </div>
          {showComment && (
            <div className="milo-comment">Beautiful. Onwards!</div>
          )}
        </>
      )}

      {(isCurrent || revisiting) && (
        <>
          <div className="title">{QUESTION_TEXT}</div>
          <Level>
            <ItemFieldWrapper icon="user">
              <input
                value={user.name || ''}
                onChange={evt => setUser({ ...user, name: evt.target.value })}
                placeholder="First name"
              />
            </ItemFieldWrapper>
            <ItemFieldWrapper icon="id-card">
              <input
                value={user.last_name || ''}
                onChange={evt =>
                  setUser({ ...user, last_name: evt.target.value })
                }
                placeholder="Last name"
              />
            </ItemFieldWrapper>
          </Level>
          <Level>
            <ItemFieldWrapper icon="phone">
              <input
                value={user.phone || ''}
                onChange={evt => setUser({ ...user, phone: evt.target.value })}
                placeholder="Phone"
                onBlur={() =>
                  setUser({ ...user, phone: formatPhone(user.phone) })
                }
              />
            </ItemFieldWrapper>
            <ItemFieldWrapper icon="envelope">
              <input
                value={user.email || ''}
                onChange={evt =>
                  setUser({ ...user, email: evt.target.value.trim() })
                }
                placeholder="Email"
                onBlur={() =>
                  setUser({ ...user, email: user.email.toLowerCase() })
                }
              />
            </ItemFieldWrapper>
          </Level>

          <Level variant="start">
            <ItemFieldWrapper icon="map-pin">
              <input
                value={family.zip || ''}
                onChange={evt =>
                  setFamily({ ...family, zip: evt.target.value })
                }
                placeholder="ZIP Code"
              />
            </ItemFieldWrapper>
            <ItemFieldWrapper icon="globe">
              <ReactSelect
                placeholder="Timezone"
                value={
                  (family.timezone || guessedTimezone) && {
                    value: family.timezone || guessedTimezone,
                    label: family.timezone || guessedTimezone,
                  }
                }
                onChange={evt => {
                  setFamily({ ...family, timezone: evt.value });
                }}
                options={timezones.map(tz => ({
                  value: tz,
                  label: tz,
                }))}
                isSearchable={false}
                autosize={false}
                components={{
                  Control,
                  ValueContainer,
                  DropdownIndicator: null,
                  IndicatorSeparator: null,
                }}
                openMenuOnFocus
              />
            </ItemFieldWrapper>
          </Level>
          {errors.map(error => (
            <div className="error" key={error}>
              {error}
            </div>
          ))}
          <p>{INFO_TEXT}</p>
          {nonUS && (
            <div className="error">
              We’re currently only available in the US. Please stay connected
              with us on Instagram{' '}
              <a href="https://www.instagram.com/join_milo/" target="__blank">
                @join_milo
              </a>{' '}
              to get updates on when we're coming to you!
            </div>
          )}
          <SendButton
            disabled={
              !(
                (family.timezone || guessedTimezone) &&
                family.zip &&
                user.phone &&
                user.email &&
                user.name
              )
            }
            onClick={() => {
              const formErrors = validateFields(user);
              const answer = [
                { key: 'user', value: user },
                {
                  key: 'timezone',
                  value: family.timezone || guessedTimezone,
                },
                { key: 'zip', value: family.zip },
              ];
              if (formErrors.length) {
                setErrors(formErrors);
              } else if (!validateZip(family.zip)) {
                setNonUS(true);
                // Save answers without advancing the form
                callApi('PUT', `api/invite/${hash}`, undefined, answer);
              } else {
                setRevisiting(false);
                onAnswer(answer);
              }
            }}
          />
        </>
      )}
    </div>
  );
};

function validateFields(user) {
  const err = [];

  if (!validateEmail(user.email)) {
    err.push('Invalid email address.');
  }

  if (!validatePhone(user.phone)) {
    err.push('Invalid phone number.');
  }

  return err;
}

const Control = ({ children, ...props }) => (
  <selectComponents.Control className="Control" {...props}>
    {children}
  </selectComponents.Control>
);

const ValueContainer = ({ children, ...props }) => {
  return (
    <selectComponents.ValueContainer
      className="ValueContainer"
      style={{ paddingLeft: 16 }}
      {...props}
    >
      {children}
    </selectComponents.ValueContainer>
  );
};

export default EmailPhoneQuestion;
