import 'boxicons';
import React, { useState } from 'react';
import { CardBody } from '../components/Card';
import './MissionCriticalList.scss';
import { MiniItem } from '../components/MiniItem';

const MissionCriticalList = props => {
  const { data, cardMarkComplete, title, subtitle, timestamp, subTimestamp, border } = props;
  const [ showAll, toggleShowAll ] = useState(false);
  const list = data.slice(0, showAll ? data.length : 3);
  const emptyText = "😎 All clear for this week!";
  let emptyBorder = "";
  if (border !== "") {
    emptyBorder = "emptyBorder";
  }
  return (
    <div className="MissionCritical">
        <CardBody>
          <h2>{title}</h2>
          <p>{subtitle}</p>
        </CardBody>
        <p className="timestamp">{timestamp}</p>
        <p className="subTimestamp">{subTimestamp}</p>
        {list.map((item, index) => {
            return (
              <MiniItem key={index} item={item} cardMarkComplete={cardMarkComplete} showDate />
            );
          }
        )}
        {!showAll && data.length >= 4 && (
          <div className={"seeMoreWrapper seeMoreWrapper" + border}>
            <div className="seeMore">
              <a onClick={() => toggleShowAll(true)}> Show All</a>
            </div>
          </div>
        )}
        {!data.length && 
          <div className={emptyBorder}>
            <div className={"empty"}>
              <p>{emptyText}</p>
            </div>
          </div>
        }
      </div>
  );
};

export default MissionCriticalList;
