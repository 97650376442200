export function templateToPlannerItems(templateId, planItems) {
  // group blocks
  const blocks = planItems.reduce(
    (acc, item) => ({
      ...acc,
      [item.block_number]: (acc[item.block_number] || []).concat(item),
    }),
    {},
  );

  // Now go create a plan for each block
  const newPlannerItems = Object.keys(blocks).map(id => ({ id, templateId }));

  // Populate the items for each block
  newPlannerItems.forEach(plannerItem => {
    const items = blocks[plannerItem.id];
    plannerItem.title = items[0].title;
    plannerItem.time = items[0].time;
    plannerItem.endTime = items[0].end_time;
    plannerItem.daily = items[0].block_type === 'd';
    plannerItem.days = [{}, {}, {}, {}, {}, {}, {}];
    items.forEach(item =>
      item.days.forEach(day => {
        if (plannerItem.days[day]) {
          plannerItem.days[day].active = true;
        }
      }),
    );
    if (plannerItem.daily) {
      items.forEach(item => {
        item.days.forEach(day => {
          plannerItem.days[day].assigned_to_id = item.assigned_to_id;
          plannerItem.days[day].description = item.description;
        });
      });
    } else {
      plannerItem.description = items[0].description;
      plannerItem.assigned_to_id = items[0].assigned_to_id;
    }
  });
  return newPlannerItems;
}
