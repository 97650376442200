import TextareaAutosize from 'react-textarea-autosize';
import styled from 'styled-components';
import * as styles from '../../styles';

export const Container = styled.div`
  flex: 1;
  display: flex;
  color: black;
  font-family: ${styles.FontFamilyCereal};
  overflow-x: hidden;
`;

export const CalendarPanel = styled.div`
  background-color: ${styles.BackgroundColor};
  flex: 1;
  display: flex;
  overflow: scroll;
  height: calc(100vh - 100px);
  scroll-snap-type: x proximity;
  @media (max-width: 768px) {
    height: calc(100vh - 220px);
  }
`;

export const Checkbox = styled.div`
  position: relative;
  height: 20px;
  width: 20px;

  @media (max-width: 768px) {
    height: 28px;
    width: 28px;
  }

  input[type='checkbox'] {
    opacity: 0;
    height: 20px;
    width: 20px;
    margin: 0;
    @media (max-width: 768px) {
      height: 28px;
      width: 28px;
    }
  }

  label {
    position: absolute;
    right: -5px;
    padding-left: 5px;
    cursor: pointer;

    @media (max-width: 768px) {
      padding-top: 3px;
    }

    @media (hover: hover) {
      :hover::after {
        box-shadow: inset 0 0 6px rgb(145, 182, 165);
      }
    }

    ::after {
      content: '';
      cursor: pointer;
      position: absolute;
      top: 0;
      left: -20px;
      height: 20px;
      width: 20px;
      border: 1px solid rgb(191, 191, 191);
      box-sizing: border-box;
      border-radius: 4px;
      transition: box-shadow 0.1s ease;

      @media (max-width: 768px) {
        height: 28px;
        width: 28px;
        left: -28px;
      }
    }
  }

  input[type='checkbox']:checked + label::after {
    border: 0;
    background-color: rgb(145, 182, 165);
    background-image: url(${props => props.icon});
    background-position: center;
    background-size: 12px;
    background-repeat: no-repeat;
  }
`;

export const Button = styled.a`
  cursor: pointer;
`;

export const DateButton = styled.button`
  cursor: pointer;
  border: none;
`;

export const Select = styled.select`
  appearance: none;
  width: 100%;
  height: 42px;
  background: url(${props => props.icon}) no-repeat scroll 8px 7px;
  background-size: 24px;
  padding: 10px;
  padding-left: 36px;
  font-size: 16px;
  font-family: ${styles.FontFamilyCereal}
  border-radius: 8px;
  border: none;
  transition: background-color 0.1s ease;

  &:hover {
    background-color: ${styles.BackgroundColor};
  }
  &:focus {
    background-color: ${styles.BackgroundColor};
  }

  option {
    color: black;
    background-color: white;
    font-weight: small;
    display: flex;
    white-space: pre;
    min-height: 20px;
    padding: 0px 2px 1px;
  }
`;

export const FormField = styled.div`
  display: flex;
  margin-bottom: 2px;
`;

export const TextInput = styled.input`
  width: 342px;
  background: url(${props => props.icon}) no-repeat scroll 7px 7px;
  background-size: 24px;
  padding: 10px;
  padding-left: 36px;
  border-radius: 8px;
  border: none;
  transition: background-color 0.1s ease;

  &:hover {
    background-color: ${styles.BackgroundColor};
  }
  &:focus {
    background-color: ${styles.BackgroundColor};
  }
`;

export const TextAreaInput = styled(TextareaAutosize)`
  width: 100%;
  background: url(${props => props.icon}) no-repeat scroll 7px 7px;
  background-size: 24px;
  padding: 10px;
  padding-left: 36px;
  border-radius: 8px;
  border: none;
  transition: background-color 0.1s ease;

  &:hover {
    background-color: ${styles.BackgroundColor};
  }
  &:focus {
    background-color: ${styles.BackgroundColor};
  }
  resize: none;
`;
