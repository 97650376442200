import React from 'react';
import { Button } from './Button';

import './NewItemButton.scss';

const NewItemButton = ({ onClick }) => {
  return (
    <div className="NewItemButton">
      <Button onClick={onClick}>
        <box-icon name="plus" />
      </Button>
    </div>
  );
};

export default NewItemButton;
