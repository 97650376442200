import React from 'react';
import { Button } from '../../Home/components/Button';

const Complete = () => {
  return (
    <div className="Intro">
      <div className="title serif">
        <span role="img" aria-label="dancing man emoji">
          🕺🏾
        </span>{' '}
        Hurray!
      </div>
      <p>Beautiful. Your account is ready to go!</p>
      <p>Now let’s customize Milo for your family.</p>
      <Button
        variant="green large"
        onClick={() => (window.location = process.env.REACT_APP_URL)}
      >
        Let’s Go <box-icon name="right-arrow-alt" />
      </Button>
    </div>
  );
};

export default Complete;
