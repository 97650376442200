import React from 'react';
import { connect } from 'react-redux';
import { getFamilyColor, getUserColor } from '../../../utils/user-color';
import './Avatar.scss';

export const Avatar = connect(
  state => ({
    auth: state.auth,
  }),
  {},
)(props => {
  const { id, familyId, auth, useFamilyColor, className} = props;
  let name;
  let color;

  if (!id) {
    // If no id is passed in, try to find the family and if found, render with family name and color

    // If this family isn't in a group or familyId wasn't passed in, don't render
    if (!auth.group_families || !familyId) return null;

    const family = auth.group_families.find(family => family.id === familyId);
    if (!family) return null; // if family wasn't found in the group, don't render

    name = family.name.replace(/^the /gi, '').substring(0, 2);
    color = getFamilyColor(auth.group_families, familyId);
  } else {
    // If id is passed in, try to find it in this family
    let user = auth.users.find(user => user.id === id);
    let fId = auth.family_id;
    if (!user) {
      // If the user isn't found in this family, try to find it in the group

      // But if this family isn't in a group, don't render
      if (!auth.group_families) return null;

      for (const family of auth.group_families) {
        user = family.users && family.users.find(user => user.id === id);
        fId = family.id;
        if (user) break;
      }

      // If user wasn't found in the group either, don't render
      if (!user) return null;
    }

    // Render with:
    //  user name
    //  family color if useFamilyColor is passed in, otherwise user color
    name = user.name && user.name.substring(0, 2);
    color = useFamilyColor
      ? getFamilyColor(auth.group_families, fId)
      : getUserColor(auth.users, id);
  }

  return (
    <div
      className={`Avatar ${className}`}
      style={{ backgroundColor: color, ...props.style }}
    >
      {name}
    </div>
  );
});
