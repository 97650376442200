import 'boxicons';
import classNames from 'classnames';
import React from 'react';
import { connect } from 'react-redux';
import moment from 'moment';
import { openItemFormModal } from '../../../redux/itemFormModal';
import {
  open as setOpenModal,
  closeAll as closeAllModals,
} from '../../../redux/modal';
import { Level } from '../components/Level';
import { H1 } from '../components/Type';
import './Dash2.scss';
import Today from './Today';
import Tomorrow from './Tomorrow';
import TomorrowModal from './TomorrowModal';
import RecentHistory from './RecentHistory';
import MiloSuggests from './MiloSuggests';
import { Button } from '../components/Button';
import GetStarted from '../components/GetStarted';

const Dash2 = props => {
  const {
    auth,
    setWeekToday,
    items,
    refresh,
    saveItem,
    miloCalendar,
    cardMarkComplete,
    openItemFormModal,
    setOpenModal,
    openModal,
    closeAllModals,
  } = props;

  const memoSetWeekToday = React.useCallback(setWeekToday, []);

  React.useEffect(() => {
    memoSetWeekToday();
  }, [memoSetWeekToday]);

  const leftCol = [];
  const rightCol = [];

  const today = (
    <Today
      key="today"
      openItemFormModal={openItemFormModal}
      cardMarkComplete={cardMarkComplete}
      miloCalendar={miloCalendar}
      items={items}
    />
  );

  const tomorrow = (
    <Tomorrow
      key="tomorrow"
      openItemFormModal={openItemFormModal}
      cardMarkComplete={cardMarkComplete}
      miloCalendar={miloCalendar}
      items={items}
      saveItem={saveItem}
    />
  );

  const recentHistory = (
    <RecentHistory key="recent-history" refresh={refresh} />
  );
  const miloSuggests = <MiloSuggests key="milo-suggests" />;

  // Show Get started card only if the family was created less than two weeks ago
  const createdDate = moment(auth.created_at);
  const twoWeeksAgo = moment().subtract(14, 'days').startOf('day');
  const isPrimaryUser = auth.users.every(user => user.id >= auth.user.id);

  if (window.innerWidth < 768) {
    if (isPrimaryUser && createdDate.isAfter(twoWeeksAgo)) {
      leftCol.push(<GetStarted key="get-started" />);
    }
    leftCol.push(today, tomorrow, miloSuggests, recentHistory);
  } else {
    leftCol.push(today, tomorrow, miloSuggests);
    if (isPrimaryUser && createdDate.isAfter(twoWeeksAgo)) {
      rightCol.push(<GetStarted key="get-started" />);
    }
    rightCol.push(recentHistory);
  }

  return (
    <div
      className={classNames('Dash2', {
        dash_triage_button_only:
          auth.family_metadata && auth.family_metadata.dash_triage_button_only,
      })}
    >
      <Level variant="start">
        <H1>
          {auth.family_emoji ? (
            auth.family_emoji.native
          ) : (
            <span role="img" aria-label="hello">
              👋
            </span>
          )}{' '}
          Hello {auth.family}!{' '}
          <Button
            type="button"
            variant="clear"
            onClick={() => setOpenModal('family-settings')}
          >
            <box-icon name="edit" />
          </Button>
        </H1>
      </Level>

      <div className="summary-cols">
        <div className="left-col">{leftCol}</div>
        <div className="right-col">{rightCol}</div>
      </div>

      <TomorrowModal
        isOpen={openModal === 'tomorrow'}
        close={closeAllModals}
        openItemFormModal={openItemFormModal}
        cardMarkComplete={cardMarkComplete}
        miloCalendar={miloCalendar}
        items={items}
        saveItem={saveItem}
      />
    </div>
  );
};

export default connect(
  state => ({ auth: state.auth, openModal: state.modal }),
  {
    openItemFormModal,
    setOpenModal,
    closeAllModals,
  },
)(Dash2);
