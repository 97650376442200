import classNames from 'classnames';
import moment from 'moment';
import React, { forwardRef, useCallback, useState } from 'react';
import { useDispatch } from 'react-redux';
import { openItemFormModal } from '../../../redux/itemFormModal';
import { formatDate, formatTime } from '../../../utils/date-format';
import { COMPLETABLE, COMPLETE, TYPE_FILE } from '../constants';
import { Avatar } from './Avatar';
import { Checkbox } from './Checkbox';
import { ItemFieldWrapper } from '../components/ItemForm/ItemFields';
import './Item.scss';
import { H3 } from './Type';

export const Item = forwardRef(function Item(props, ref) {
  const {
    item,
    cardMarkComplete,
    variant,
    readOnly,
    button,
    hideCheckbox,
  } = props;

  let { showDate } = props;

  const {
    id,
    title,
    date,
    time,
    end_time,
    status,
    assigned_to_ids,
    description,
    group_id,
    emoji,
    type,
    tags,
    existing,
    linked_items,
    linked_from,
  } = item;

  const dispatch = useDispatch();

  const reduxOpenItemForm = useCallback(() => dispatch(openItemFormModal(id)), [
    dispatch,
    id,
  ]);
  const openItemForm = props.openItemForm || reduxOpenItemForm;

  const completable = [COMPLETABLE, COMPLETE].includes(status);
  const complete = completable && status === COMPLETE;

  const formattedDate = formatDate(date);
  const startTime = formatTime(date, time, end_time);
  const endTime = formatTime(date, end_time);
  const formattedTime =
    end_time && time === end_time
      ? endTime
      : `${startTime}${endTime ? `-${endTime}` : ''}`;

  const past = date && moment().startOf('day').isAfter(moment(date));

  if (completable && !complete && past) {
    showDate = true;
  }

  const isTopThree = tags && tags.includes('top-three');
  const isPriority = tags && tags.includes('priority');
  const isParent = linked_items && !!linked_items.length;
  const isChild = linked_from && !!linked_from.length;

  return (
    <div
      ref={ref}
      className={classNames('Item', {
        inactive:
          complete || (!completable && !showDate && past && type !== TYPE_FILE),
        'past-date': showDate && past && !complete,
        dragging: variant === 'dragging',
        'read-only': readOnly,
        'has-button': !!button,
        existing: existing,
      })}
      onClick={() => !readOnly && openItemForm()}
      {...props.draggableProps}
      {...props.dragHandleProps}
    >
      <div className="badges">
        {isTopThree && (completable || complete) && (
          <box-icon type="solid" name="star" />
        )}
        {isPriority && <box-icon name="calendar-exclamation" />}
        {isParent && <box-icon name="list-ul" />}
        {isChild && <box-icon name="subdirectory-right" />}
      </div>

      <div className="info">
        <H3>
          {emoji && `${emoji.native} `}
          {title}
        </H3>
        {(time || date) && (
          <div className="time">
            {showDate && formattedDate + ' '}
            {time && formattedTime}
          </div>
        )}
        {description && <div className="description">{description}</div>}
      </div>

      <div className="widgets">
        {!readOnly && !hideCheckbox && completable && (
          <Checkbox
            id={id}
            checked={complete}
            onChange={cardMarkComplete.bind(this, item)}
            animate={isTopThree}
          />
        )}
        {assigned_to_ids &&
          assigned_to_ids.map(assigned_to_id => (
            <Avatar
              key={assigned_to_id}
              id={assigned_to_id}
              useFamilyColor={!!group_id}
            />
          ))}
      </div>

      {!!button && <div className="button-container">{button}</div>}
    </div>
  );
});

export const BlankItem = ({ className, onClick }) => (
  <div
    className={`Item blank ${className ? className : ''}`}
    onClick={onClick ? onClick : () => {}}
  ></div>
);

export const InputItem = ({ className, isLast, addItem }) => {
  const [value, setValue] = useState('');

  function handleKeyDown(evt) {
    if ([13].includes(evt.keyCode)) {
      save();
    }
  }

  function save() {
    addItem(value);
    setValue('');
  }

  return (
    <div
      className={classNames('Item', 'edit', className, {
        short: isLast,
        filled: value.length,
      })}
    >
      <box-icon name="plus" />
      <ItemFieldWrapper>
        <input
          className="input"
          value={value}
          type="text"
          onChange={evt => setValue(evt.target.value)}
          onKeyDown={handleKeyDown}
          onBlur={() => {
            if (value.trim().length) save();
          }}
        />
      </ItemFieldWrapper>
    </div>
  );
};

export const PlaceholderItem = props => (
  <div
    onClick={props.onClick}
    className={`Item placeholder ${props.className}`}
  >
    {props.children}
  </div>
);
