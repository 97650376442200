import { callApi } from '../../utils/callApi';

export default async function uploadAttachments(files, onUploadProgress) {
  const newAttachments = await callApi(
    'POST',
    `api/attachments`,
    undefined,
    {},
    files,
    onUploadProgress,
  );

  // Flag these as new for special handling later
  return newAttachments.map(attach => ({ ...attach, new: true }));
}
