import 'boxicons';
import moment from 'moment';
import React from 'react';
import { connect } from 'react-redux';
import { openItemFormModal } from '../../../redux/itemFormModal';
import { Card, CardBody, CardItems } from '../components/Card';
import { Item } from '../components/Item';
import { Level } from '../components/Level';
import MiloCalendarCard from '../components/MiloCalendarCard';
import { MiniItem } from '../components/MiniItem';
import { H2 } from '../components/Type';
import { DATE_FORMAT } from '../constants';
import { sortItemsByTime } from '../utils';
import './Dash2.scss';
import { Dash2EmptyState } from './Dash2EmptyState';

const Today = ({ items, cardMarkComplete }) => {
  const todayItems = sortItemsByTime(
    items.filter(item => item.date === moment().format(DATE_FORMAT)),
  );

  return (
    <Card>
      <CardBody>
        <H2>Today’s rundown</H2>
      </CardBody>

      <CardItems>
        {todayItems.map(item => (
          <MiniItem
            key={item.id}
            item={item}
            cardMarkComplete={cardMarkComplete}
          />
        ))}
      </CardItems>
      {!todayItems.length && <Dash2EmptyState />}
    </Card>
  );
};

export default connect(
  state => ({ auth: state.auth, itemFormModal: state.itemFormModal }),
  { openItemFormModal },
)(Today);
