const SET = 'ONBOARDING_STEP_SET';
const INCREMENT = 'ONBOARDING_STEP_INCREMENT';
const DECREMENT = 'ONBOARDING_STEP_DECREMENT';
const initialState = 0;

function set(value) {
  return { type: SET, value };
}

function increment() {
  return { type: INCREMENT };
}

function decrement() {
  return { type: DECREMENT };
}

export function reducer(state = initialState, action) {
  let result = state;
  if (action.type === SET) {
    result = action.value;
  } else if (action.type === INCREMENT) {
    result = result + 1;
  } else if (action.type === DECREMENT) {
    result = result - 1;
  }
  return result;
}

export default { set, increment, decrement };
