import { loadStripe } from '@stripe/stripe-js';
import React from 'react';
import { Link } from 'react-router-dom';
import { MiloModal, MiloModalContent } from '../Home/components/MiloModal';
import './UnpaidModal.scss';

const UnpaidModal = () => {
  async function redirectToStripe() {
    const stripe = await loadStripe(process.env.REACT_APP_STRIPE_API_KEY);
    stripe.redirectToCheckout({
      lineItems: [
        { price: process.env.REACT_APP_STRIPE_SUBSCRIPTION_KEY, quantity: 1 },
      ],
      mode: 'subscription',
      successUrl: process.env.REACT_APP_URL,
      cancelUrl: process.env.REACT_APP_URL,
    });
  }

  return (
    <MiloModal isOpen={true} className="UnpaidModal" size="narrow">
      <MiloModalContent>
        <p>
          <span role="img" aria-label="Grinning Face with Sweat emoji">
            😅
          </span>{' '}
          Oh no! Your Milo membership needs to be reactivated. Restart your
          subscription{' '}
          <Link className="link" onClick={redirectToStripe}>
            here
          </Link>{' '}
          and we'll have you up and running in no time.
        </p>
        <p className="note">
          Don't think you should be seeing this page? Email{' '}
          <a href={`mailto:${process.env.REACT_APP_ONBOARDING_EMAIL}`}>
            {process.env.REACT_APP_ONBOARDING_EMAIL}
          </a>{' '}
          and we'll help get you sorted.
        </p>
      </MiloModalContent>
    </MiloModal>
  );
};

export default UnpaidModal;
