import idx from 'idx';
import moment from 'moment';
import React from 'react';
import { connect } from 'react-redux';
import { Route, Switch } from 'react-router-dom';
import socketIOClient from 'socket.io-client';
import {
  closeItemFormModal,
  openItemFormModal,
} from '../../redux/itemFormModal';
import itemsRedux from '../../redux/items';
import {
  closeAll as closeAllModals,
  open as setOpenModal,
} from '../../redux/modal';
import progressRedux from '../../redux/progress';
import * as analytics from '../../utils/analytics';
import { callApi } from '../../utils/callApi';
import NewItemButton from './components/NewItemButton';
import { UndoPlannerFlash } from './components/UndoPlannerFlash';
import {
  COMPLETABLE,
  COMPLETE,
  DATE_FORMAT,
  STORAGE_KEY_ONLY_ME,
  TYPE_FILE,
  TYPE_NEW,
} from './constants';
import Dash2 from './Dash2/Dash2';
import Files from './Files/Files';
import './Home.scss';
import Inbox from './Inbox/Inbox';
import ItemFormModal from './ItemFormModal/ItemFormModal';
import MiloLibrary from './MiloLibrary/MiloLibrary';
import ModalManager from './ModalManager';
import OnboardingModal from './OnboardingModal/OnboardingModal';
import Planner2 from './Planner2/Planner2';
import Summary from './Summary/Summary';
import Triage from './Triage/Triage';
import utils from './utils';
import './Week/Dashboard.scss';
import Week from './Week/Week';
import DirectTypeWeek from './Week/DirectTypeWeek';
import WeekExport from './Week/WeekExport';
import Widgets from './Widgets/Widgets';

export var mvSocket;

class Home extends React.Component {
  state = {
    loading: true,
    backlog: [],
    showCompleteInBacklog: false,
    mobileShowBacklog: true,
    weekStartDate: moment().startOf('isoWeek').format(DATE_FORMAT),
    showOnlyMe: localStorage.getItem(STORAGE_KEY_ONLY_ME) === 'true',
    thisWeeksSuggestions: [],
    miloCalendar: [],
    miloSeries: [],
  };

  componentDidMount() {
    analytics.page('Dashboard');
    const params = new URLSearchParams(this.props.location.search);
    const start = params.get('start') || undefined;
    const weekStartDate =
      start &&
      (start.toLocaleLowerCase() === 'next'
        ? moment().startOf('isoweek').add(1, 'week').format(DATE_FORMAT)
        : moment(params.get('start')).startOf('isoweek').format(DATE_FORMAT));
    if (weekStartDate) {
      this.setState({
        weekStartDate,
      });
    }
    this.refreshItems(weekStartDate);
    !mvSocket && this.connectSocket();
    this.getThisWeeksSuggestions();
    callApi('GET', `api/progress`).then(result =>
      this.props.setProgress(result),
    );
  }

  componentDidUpdate(prevProps) {
    const {
      itemFormModal: { itemId, isOpen },
      closeItemFormModal,
    } = this.props;

    // If the modal just opened or a different item is opened
    if (
      (!prevProps.itemFormModal.isOpen && isOpen && itemId) ||
      (prevProps.itemFormModal.itemId !== itemId && isOpen && itemId)
    ) {
      const item = this.props.items.find(item => item.id === itemId);

      // Get the latest from the server and gcal in case it's changed
      callApi('GET', `api/items/${itemId}`).then(refreshed => {
        if (!refreshed[0]) {
          // The item has been deleted from the calendar between
          // opening the dashboard and opening the item to edit
          closeItemFormModal();
          this.refreshItems();
          alert('Item was removed');
        } else if (
          !item ||
          JSON.stringify(refreshed).localeCompare(JSON.stringify(item)) !== 0
        ) {
          const filteredItems = this.props.items.filter(
            item => item.id !== itemId,
          );
          this.saveItems([...filteredItems, ...refreshed]);
        }
      });
    }
  }

  connectSocket() {
    const { auth } = this.props;
    const endpoint = `${window.location.origin}/${auth.family_id}`;
    mvSocket = socketIOClient(endpoint.replace('3000', '3001'));
    mvSocket.on(`new`, async id => {
      console.log('socket->newItem', id);
      this.refreshItem(+id);
    });
    mvSocket.on(`refresh`, id => {
      console.log('socket->refresh', id);
      this.refreshItem(+id);
    });
    mvSocket.on(`refresh-ids`, ids => {
      console.log('socket->refresh-ids', ids);
      this.refreshMultiple(JSON.parse(ids));
    });
    mvSocket.on(`delete`, async id => {
      console.log('socket->delete', id);
      this.deleteItemLocally(+id);
      this.setState({ refresh: moment().format() });
    });
    mvSocket.on(`delete-bulk`, async ids => {
      console.log('socket->delete-bulk', ids);
      JSON.parse(ids).map(id => this.deleteItemLocally(+id));
    });
    mvSocket.on(`edit_settings`, async () => {
      console.log('socket->edit_settings');

      // Update the events
      this.setState({ refresh: moment().format() });
    });
    mvSocket.on(`connect`, () => {
      console.log('connect');
    });
    mvSocket.on(`disconnect`, reason => {
      console.log('disconnected', reason);
    });
    mvSocket.on(`reconnect`, async number => {
      console.log('reconnect', number);
      await this.refreshItems();
    });
    mvSocket.on(`error`, async error => {
      console.log('error', error);
    });
  }

  saveItems(items) {
    this.setState({ loading: false });
    this.props.setItems(items);
  }

  getItems(weekStartDate, withEvents) {
    const endDate = moment(weekStartDate).add(7, 'days').format(DATE_FORMAT);
    callApi(
      'GET',
      'api/items',
      `start_date=${weekStartDate}&end_date=${endDate}${
        withEvents ? '&events=1' : ''
      }`,
    ).then(items => {
      this.saveItems(items);
    });
  }

  refreshItems(weekStartDate = this.state.weekStartDate) {
    this.setState({ loading: true });

    callApi('GET', `api/milo-calendar/${weekStartDate}`).then(result =>
      this.setState({ miloCalendar: result }),
    );

    callApi('GET', `api/milo-series/${weekStartDate}`).then(result => {
      const miloSeries = result.reduce((acc, calendar) => {
        calendar.events.forEach(({ title, date }) => {
          acc.push({
            title: `${calendar.name}: ${title}`,
            date,
          });
        });
        return acc;
      }, []);
      this.setState({ miloSeries });
    });

    this.getItems(weekStartDate, false);
    this.setState({ loading: false });
    this.getItems(weekStartDate, true);
    callApi('GET', 'api/items/tags').then(tags => {
      this.setState({ tags });
    });
  }

  async refreshItem(id) {
    const itemArray = await callApi('GET', `api/items/${id}`);
    const filteredItems = this.props.items.filter(item => item.id !== id);
    const items = [...filteredItems, ...itemArray];
    this.setState({ refresh: moment().format() });
    this.saveItems(items);
  }

  async refreshMultiple(ids) {
    if (!ids.length) {
      return;
    }
    const updated = await callApi('GET', `api/items/${ids.join(',')}`);
    const filteredItems = this.props.items.filter(
      item => !ids.includes(item.id),
    );
    const items = [...filteredItems, ...updated];
    this.saveItems(items);
  }

  async deleteItemLocally(id) {
    const { items } = this.props;
    const itemToDelete = items.find(item => item.id === id);

    if (!itemToDelete) {
      return;
    }

    // If this is a child then remove parents linked_items
    let updated = [];
    if (itemToDelete.linked_from) {
      const parentItems = items.filter(
        list =>
          list.linked_items &&
          list.linked_items.find(child => child.id === itemToDelete.id),
      );
      updated = parentItems.map(parent => ({
        ...parent,
        linked_items: parent.linked_items.filter(
          link => link.id !== itemToDelete.id,
        ),
      }));
    }

    // If this is a parent then the children are deleted too
    const deletedChildren =
      (itemToDelete.linked_items &&
        itemToDelete.linked_items.map(child => child.id)) ||
      [];

    // Gather all the deleted so we can filter them out
    const deleted = [
      itemToDelete.id,
      ...updated.map(item => item.id),
      ...deletedChildren,
    ];
    const newItems = [
      ...items.filter(item => !deleted.includes(item.id)),
      ...updated,
    ];
    return this.saveItems(newItems);
  }

  async deleteItem(itemToDelete, callback, skipConfirm) {
    const confirmString =
      itemToDelete.linked_items && itemToDelete.linked_items.length
        ? 'Are you sure you want to delete this item and all of its sub-tasks?'
        : 'Are you sure you want to delete this item?';

    if (!skipConfirm) {
      if (!window.confirm(confirmString)) {
        return;
      }
    }
    callApi('DELETE', `api/items/${itemToDelete.id}`);
    callback && (await callback());
    await this.deleteItemLocally(itemToDelete.id);
  }

  openPlanner2() {
    this.props.setOpenModal('planner');
    this.setState({ plannerSession: undefined });
  }

  closePlanner2() {
    this.props.closeAllModals();
  }

  itemsContainsTemplateId(templateId) {
    const { items } = this.props;
    const template = items.find(
      item =>
        item.data && +item.data.templateId === +templateId && !item.status,
    );
    return !!template;
  }

  async onNewPlannerItems(plannerSession, newItems) {
    const { items } = this.props;
    const created = await callApi('POST', 'api/items', undefined, newItems);
    if (Array.isArray(created)) {
      const errors = created.filter(item => item.error).map(item => item.error);
      if (errors.length) {
        alert(`Failed to created ${errors.length} events.`);
        return;
      }
      // add the items but check they aren't there already via socket.io
      const updated = created.map(
        item => !items.find(existing => existing.id === item.id) && item,
      );
      this.saveItems([...items, ...updated]);
      this.setState({ plannerSession });
      this.undoTimer = setTimeout(
        () => this.setState({ plannerSession: undefined }),
        10000,
      );
    }
  }

  async undoPlannerItems() {
    const { plannerSession } = this.state;
    const { items } = this.props;
    clearTimeout(this.undoTimer);
    const itemIds = items
      .filter(item => item.data && item.data.plannerSession === plannerSession)
      .map(item => item.id);

    callApi('DELETE', `api/items`, undefined, itemIds);

    // Remove the item and fill the gap
    const newItems = [...items.filter(item => !itemIds.includes(item.id))];
    this.saveItems(newItems);
    this.setState({ plannerSession: undefined });
  }

  async saveItem(newItem, preserveState) {
    if (newItem.id) {
      // Update existing item
      // If this is a child then we need to save
      // this change to the parent linked_items in
      // redux too
      if (!preserveState) {
        const parentItem = newItem.linked_from && newItem.linked_from[0];
        const updatedParentItem =
          parentItem &&
          this.props.items.find(item => +item.id === +parentItem.id);
        if (updatedParentItem && updatedParentItem.linked_items) {
          updatedParentItem.linked_items = [
            ...updatedParentItem.linked_items.filter(
              item => +item.id !== +newItem.id,
            ),
            { ...newItem },
          ];
        }
        const items = [
          ...this.props.items.filter(
            item =>
              +item.id !== +newItem.id &&
              +item.id !== (updatedParentItem && +updatedParentItem.id),
          ),
          { ...newItem },
        ];
        if (updatedParentItem) {
          items.push(updatedParentItem);
        }
        this.saveItems(items);
      }
      return callApi('PUT', `api/items/${newItem.id}`, undefined, {
        ...newItem,
        no_socket: preserveState,
      });
    } else {
      // Create a new item
      const id = await callApi('POST', 'api/items', undefined, newItem);

      // Check if this new item has a parent in which case we have to
      // update the parent too
      let items = [...this.props.items];
      if (!newItem.id && newItem.parent_id) {
        const parentItem = this.props.items.find(
          item => +item.id === +newItem.parent_id,
        );
        newItem.linked_from = [{ ...parentItem, linked_items: undefined }];
        if (!parentItem.linked_items) {
          parentItem.linked_items = [];
        }
        parentItem.linked_items.push({ ...newItem, id, tId: id });
        items = [
          ...items.filter(item => +item.id !== +parentItem.id),
          parentItem,
        ];
      }

      // Add the new item to redux
      items = [...items, { ...newItem, id }];

      // If this item has children then add the children
      if (newItem.linked_items && newItem.linked_items) {
        items = [
          ...items,
          ...newItem.linked_items.map(item => ({
            ...item,
            linked_from: [newItem],
          })),
        ];
      }

      // Save everything
      this.saveItems(items);
      return id;
    }
  }

  getThisWeeksSuggestions() {
    callApi('GET', `api/suggestions/scheduled-content`).then(
      thisWeeksSuggestions => this.setState({ thisWeeksSuggestions }),
    );
  }

  /* Checkbox on card changed - update item and save */
  cardMarkComplete(item, e) {
    const { items } = this.props;
    const status = e.target.checked ? COMPLETE : COMPLETABLE;
    callApi('PUT', `api/items/${item.id}`, undefined, { ...item, status });
    const newItems = [
      ...items.filter(found => found.id !== item.id),
      { ...item, status },
    ];
    this.saveItems(newItems);
    if (e.target.checked) {
      this.props.incrementStreak();
      this.setState({ refresh: moment().format() });
    }
  }

  setWeekStart(newDate) {
    this.refreshItems(newDate);
    this.setState({ weekStartDate: newDate });
  }

  weekForward() {
    const newDate = moment(this.state.weekStartDate)
      .add(7, 'days')
      .format(DATE_FORMAT);
    this.setWeekStart(newDate);
  }

  weekBack() {
    const newDate = moment(this.state.weekStartDate)
      .add(-7, 'days')
      .format(DATE_FORMAT);
    this.setWeekStart(newDate);
  }

  async weekToday() {
    const newDate = moment().startOf('isoWeek').format(DATE_FORMAT);
    await this.setWeekStart(newDate);
    this.setState({ scrollToToday: true });
  }

  async setShowCompleteInBacklog(show) {
    await this.setState({ showCompleteInBacklog: show });
  }

  userName(userId) {
    const { auth } = this.props;
    const user = auth.users.find(user => user.id === userId);
    return user && user.name;
  }

  isMobile() {
    return window.innerWidth <= 768;
  }

  convertDay(day) {
    return day === 0 ? 6 : day - 1;
  }

  alignItemsByDay() {
    const { weekStartDate, showOnlyMe } = this.state;
    const { items } = this.props;

    if (!items || !Array.isArray(items)) {
      return [];
    }

    const weekStart = moment(weekStartDate);
    const thisWeek = items.filter(
      item =>
        item &&
        item.date &&
        (!showOnlyMe ||
          (item.assigned_to_ids &&
            item.assigned_to_ids.includes(this.props.auth.id))) &&
        moment(item.date).isSameOrAfter(weekStart) &&
        moment(item.date).isBefore(moment(weekStart).add(7, 'days')),
    );

    const itemsByDay = [[], [], [], [], [], [], []];
    utils.sortItems(thisWeek).forEach(item => {
      const day = item.date && moment(item.date);
      if (day) {
        const index = this.convertDay(day.day());
        if (!itemsByDay[index]) {
          itemsByDay[index] = [];
        }
        itemsByDay[index].push(item);
      }
    });
    return itemsByDay;
  }

  backlogItems() {
    const { items } = this.props;
    return items
      .filter(
        item => (item.status || (!item.status && !item.date)) && !item.type,
      )
      .sort((a, b) => b.id - a.id);
  }

  toggleOnlyMe() {
    const showOnlyMe = !this.state.showOnlyMe;
    this.setState({ showOnlyMe });
    localStorage.setItem(STORAGE_KEY_ONLY_ME, showOnlyMe);
  }

  render() {
    const {
      weekStartDate,
      scrollToToday,
      showCompleteInBacklog,
      plannerSession,
      showOnlyMe,
      thisWeeksSuggestions,
      miloCalendar,
      miloSeries,
    } = this.state;
    const {
      items,
      auth,
      location,
      itemFormModal,
      openItemFormModal,
      openModal,
      closeAllModals,
    } = this.props;

    const missionCriticalItems = items.filter(
      item => item.tags && item.tags.includes('priority'),
    );
    const pathParts = idx(location, _ => _.pathname.split('/'));
    const activePage = pathParts && pathParts[2];

    const files = items.filter(item => item.type === TYPE_FILE);
    const triageItems = items
      .filter(item => item.type === TYPE_NEW)
      .sort((a, b) => b.id - a.id);

    const itemsByDay = this.alignItemsByDay();
    const backlog = this.backlogItems();

    const newButtonPrefill = {
      type: activePage === 'files' ? TYPE_FILE : undefined,
      status: activePage === 'todo' ? COMPLETABLE : undefined,
    };

    return (
      <div className="Home">
        <ModalManager />
        <Planner2
          open={openModal === 'planner'}
          onRequestClose={() => this.closePlanner2()}
          onNewPlannerItems={(newPlannerSession, newItems) =>
            this.onNewPlannerItems(newPlannerSession, newItems)
          }
          weekStartDate={weekStartDate}
          weekToday={() => this.weekToday()}
          weekBack={() => this.weekBack()}
          weekForward={() => this.weekForward()}
          users={auth.users}
          itemsContainsTemplateId={id => this.itemsContainsTemplateId(id)}
          existingItemsByDay={itemsByDay}
        />
        <UndoPlannerFlash
          open={!!plannerSession}
          undoPlannerItems={() => this.undoPlannerItems()}
        />

        <Triage
          open={openModal === 'triage'}
          items={triageItems}
          onRequestClose={closeAllModals}
          saveItem={item => this.saveItem(item)}
          deleteItem={(item, callback) => this.deleteItem(item, callback)}
        />
        <Widgets
          mode="edit"
          open={openModal}
          close={closeAllModals}
          preferences={{}}
          thisWeeksSuggestions={thisWeeksSuggestions}
          todoItems={backlog}
          cardMarkComplete={(item, e) => this.cardMarkComplete(item, e)}
          globalWeekStartDate={weekStartDate}
          globalWeekBack={() => this.weekBack()}
          globalWeekForward={() => this.weekForward()}
        />
        <ItemFormModal
          item={items.find(item => item.id === itemFormModal.itemId)}
          saveItem={item => this.saveItem(item)}
          openItemFormModal={openItemFormModal}
          deleteItem={(item, callback, skipConfirm) =>
            this.deleteItem(item, callback, skipConfirm)
          }
          hideSidePanel={itemFormModal.hideSidePanel}
        />

        {auth.family_metadata &&
          auth.family_metadata.signup_version === '1' &&
          !auth.family_metadata.onboarding_complete && <OnboardingModal />}

        <Switch>
          <Route
            path="/home/week/export/:startDate"
            render={() => <WeekExport />}
          />

          {auth.family_metadata &&
          auth.family_metadata.signup_version !== '2' ? (
            <Route
              path="/home/week"
              render={() => (
                <Week
                  itemsByDay={itemsByDay}
                  showOnlyMe={showOnlyMe}
                  toggleOnlyMe={() => this.toggleOnlyMe()}
                  miloCalendar={[...miloCalendar, ...miloSeries]}
                  miloMealSuggestions={this.state.thisWeeksSuggestions.filter(
                    sug => sug.suggestion_type === 'meal-dinner',
                  )}
                  weekStartDate={weekStartDate}
                  weekToday={() => this.weekToday()}
                  weekBack={() => this.weekBack()}
                  weekForward={() => this.weekForward()}
                  setWeekStart={start => this.setWeekStart(start)}
                  scrollToToday={scrollToToday}
                  saveItem={item => this.saveItem(item)}
                  users={auth && auth.users}
                  setScrollToToday={scrollToToday =>
                    this.setState({ scrollToToday })
                  }
                  cardMarkComplete={(item, e) => this.cardMarkComplete(item, e)}
                  isMobile={this.isMobile()}
                />
              )}
            />
          ) : (
            <Route
              path="/home/week"
              render={() => (
                <DirectTypeWeek
                  itemsByDay={itemsByDay}
                  showOnlyMe={showOnlyMe}
                  toggleOnlyMe={() => this.toggleOnlyMe()}
                  miloCalendar={[...miloCalendar, ...miloSeries]}
                  miloMealSuggestions={this.state.thisWeeksSuggestions.filter(
                    sug => sug.suggestion_type === 'meal-dinner',
                  )}
                  weekStartDate={weekStartDate}
                  weekToday={() => this.weekToday()}
                  weekBack={() => this.weekBack()}
                  weekForward={() => this.weekForward()}
                  setWeekStart={start => this.setWeekStart(start)}
                  scrollToToday={scrollToToday}
                  saveItem={item => this.saveItem(item)}
                  users={auth && auth.users}
                  setScrollToToday={scrollToToday =>
                    this.setState({ scrollToToday })
                  }
                  cardMarkComplete={(item, e) => this.cardMarkComplete(item, e)}
                  isMobile={this.isMobile()}
                />
              )}
            />
          )}
          <Route
            path="/home/todo"
            render={() => (
              <Inbox
                auth={auth}
                backlog={backlog}
                showCompleteInBacklog={showCompleteInBacklog}
                setShowCompleteInBacklog={show =>
                  this.setShowCompleteInBacklog(show)
                }
                saveItem={(item, preserveState) =>
                  this.saveItem(item, preserveState)
                }
                cardMarkComplete={(item, e) => this.cardMarkComplete(item, e)}
              />
            )}
          />
          <Route
            path="/home/files"
            render={() => (
              <Files
                auth={auth}
                files={files}
                userName={id => this.userName(id)}
                saveItem={(item, preserveState) =>
                  this.saveItem(item, preserveState)
                }
                cardMarkComplete={(item, e) => this.cardMarkComplete(item, e)}
              />
            )}
          />
          <Route path="/home/library" render={() => <MiloLibrary />} />
          {auth.family_metadata &&
          auth.family_metadata.signup_version === '2' ? (
            <Route
              render={() => (
                <Dash2
                  refresh={this.state.refresh}
                  backlog={backlog}
                  miloCalendar={[...miloCalendar, ...miloSeries].filter(
                    record => record.date === moment().format(DATE_FORMAT),
                  )}
                  thisWeeksSuggestions={thisWeeksSuggestions}
                  missionCriticalItems={missionCriticalItems}
                  items={items}
                  saveItem={item => this.saveItem(item)}
                  setWeekToday={() => this.weekToday()}
                  cardMarkComplete={(item, e) => this.cardMarkComplete(item, e)}
                />
              )}
            />
          ) : (
            <Route
              render={() => (
                <Summary
                  backlog={backlog}
                  miloCalendar={[...miloCalendar, ...miloSeries].filter(
                    record => record.date === moment().format(DATE_FORMAT),
                  )}
                  thisWeeksSuggestions={thisWeeksSuggestions}
                  missionCriticalItems={missionCriticalItems}
                  todayItems={
                    itemsByDay
                      ? itemsByDay[this.convertDay(moment().day())].filter(
                          ({ id }) => !!id,
                        )
                      : []
                  }
                  setWeekToday={() => this.weekToday()}
                  cardMarkComplete={(item, e) => this.cardMarkComplete(item, e)}
                />
              )}
            />
          )}
        </Switch>
        <NewItemButton onClick={() => openItemFormModal(newButtonPrefill)} />
      </div>
    );
  }
}

export default connect(
  state => ({
    auth: state.auth,
    itemFormModal: state.itemFormModal,
    openModal: state.modal,
    items: state.items,
  }),
  {
    openItemFormModal,
    closeItemFormModal,
    closeAllModals,
    setOpenModal,
    setItems: itemsRedux.set,
    setProgress: progressRedux.set,
    incrementStreak: progressRedux.incrementStreak,
  },
)(Home);
