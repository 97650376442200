import idx from 'idx';
import moment from 'moment';
import React from 'react';
import DatePicker from 'react-datepicker';
import ReactSelect, { components as selectComponents } from 'react-select';
import * as styles from '../../../styles';
import AssigneeIcon from '../../Icons/Assignee.png';
import DescriptionIcon from '../../Icons/Description.png';
import TitleIcon from '../../Icons/Title.png';
import { FormField } from '../Components';
import { Avatar } from '../components/Avatar';
import * as utils from '../utils';
import { Day, TextAreaInput } from './Components';
import './Planner2Item.scss';

export default class Planner2Item extends React.Component {
  onTitleChange(title) {
    const { plannerItemData, onPlannerItemDataChange } = this.props;
    onPlannerItemDataChange({ ...plannerItemData, title });
  }

  onTimeChange(newTime) {
    const { plannerItemData, onPlannerItemDataChange } = this.props;
    const formattedTime = newTime ? moment(newTime).format('HH:mm') : undefined;
    const endTime = plannerItemData.endTime;
    const newEndTime =
      formattedTime &&
      endTime &&
      utils.followTime(endTime, plannerItemData.time, formattedTime);
    onPlannerItemDataChange({
      ...plannerItemData,
      time: formattedTime,
      endTime: newEndTime ? newEndTime : endTime,
    });
  }

  onEndTimeChange(newTime) {
    const { plannerItemData, onPlannerItemDataChange } = this.props;
    const formattedTime = newTime ? moment(newTime).format('HH:mm') : undefined;
    onPlannerItemDataChange({ ...plannerItemData, endTime: formattedTime });
  }

  onActiveChange(dayIndex, checked) {
    const { plannerItemData, onPlannerItemDataChange } = this.props;

    const days = plannerItemData.days.map((item, index) =>
      index === dayIndex ? (checked ? { active: checked } : {}) : item,
    );

    onPlannerItemDataChange({ ...plannerItemData, days });
  }

  onDescriptionChange(dayIndex, event) {
    const { plannerItemData, onPlannerItemDataChange } = this.props;
    const days = plannerItemData.days.map((item, index) =>
      index === dayIndex
        ? {
            ...item,
            description: event.target.value,
          }
        : item,
    );
    onPlannerItemDataChange({ ...plannerItemData, days });
  }

  onAssignmentChange(dayIndex, event) {
    const { plannerItemData, onPlannerItemDataChange } = this.props;
    const days = plannerItemData.days.map((item, index) =>
      index === dayIndex
        ? {
            ...item,
            assigned_to_id: +event.value,
          }
        : item,
    );
    onPlannerItemDataChange({ ...plannerItemData, days });
  }

  onGeneralAssignmentChange(event) {
    const { plannerItemData, onPlannerItemDataChange } = this.props;
    onPlannerItemDataChange({
      ...plannerItemData,
      assigned_to_id: event.value,
    });
  }

  onGeneralDescriptionChange(event) {
    const { plannerItemData, onPlannerItemDataChange } = this.props;
    onPlannerItemDataChange({
      ...plannerItemData,
      description: event.target.value,
    });
  }

  onDailyChange(value) {
    const { plannerItemData, onPlannerItemDataChange } = this.props;
    onPlannerItemDataChange({
      ...plannerItemData,
      daily: value,
    });
  }

  renderDay(index, day) {
    const { weekStartDate, users } = this.props;
    const humans = users.filter(user => user.name !== 'bot');
    const humansAndGeneral = [{ id: -1, name: 'General' }, ...humans];
    const ValueContainer = ({ children, ...props }) => {
      const value = props.getValue();
      const assigned = value && value[0];
      return (
        <selectComponents.ValueContainer {...props}>
          <div
            style={{
              display: 'flex',
              background:
                (!assigned || assigned.value <= 0) &&
                `url(${AssigneeIcon}) no-repeat scroll 3px 0px`,
              backgroundSize: 24,
              width: 30,
              backgroundColor: styles.BackgroundColor,
            }}
          >
            &nbsp;
            {assigned && assigned.value > 0 && <Avatar id={assigned.value} />}
            {children[1]}
          </div>
        </selectComponents.ValueContainer>
      );
    };

    const assignedUser =
      day.assigned_to_id && users.find(user => user.id === day.assigned_to_id);
    const assignedValue = assignedUser
      ? {
          value: assignedUser.id,
          label: assignedUser.name,
        }
      : {
          value: -1,
        };
    return (
      <div
        key={index}
        style={{
          display: 'flex',
          justifyContent: 'space-between',
          flexGrow: 0,
          flexShrink: 0,
          paddingTop: 4,
        }}
      >
        <Day
          active={day.active}
          onClick={() => this.onActiveChange(index, day.active ? false : true)}
        >
          {moment(weekStartDate).add(index, 'days').format('dd')}
        </Day>
        <div style={{ paddingTop: 2, marginLeft: 6, marginRight: 8 }}>
          <ReactSelect
            isDisabled={!day.active}
            value={assignedValue}
            onChange={e => this.onAssignmentChange(index, e)}
            options={humansAndGeneral.map(user => ({
              value: user.id,
              label: user.name,
            }))}
            styles={{
              control: (base, state) => ({
                ...base,
                backgroundColor: styles.BackgroundColor,
                borderColor: styles.BackgroundColor,
                borderRadius: 8,
                boxShadow: state.isFocused ? 0 : 0,
                '&:hover': { borderColor: styles.BackgroundColor },
                height: 40,
              }),
              menu: base => ({
                ...base,
                width: 200,
              }),
            }}
            isSearchable={false}
            components={{
              ValueContainer,
              DropdownIndicator: null,
              IndicatorSeparator: null,
            }}
            autosize={false}
          />
        </div>
        <div>
          <TextAreaInput
            className="description-field"
            icon={DescriptionIcon}
            rows="3"
            maxRows={10}
            cols="35"
            placeholder="Description"
            value={day.description || ''}
            onChange={e => this.onDescriptionChange(index, e)}
            disabled={!day.active}
          />
        </div>
      </div>
    );
  }

  render() {
    const {
      users,
      plannerItemData,
      weekStartDate,
      showDeleteButton,
      onPlannerItemDelete,
    } = this.props;
    const daily = plannerItemData.daily;
    const humans = users.filter(user => user.name !== 'bot');
    const humansAndGeneral = [{ id: -1, name: 'General' }, ...humans];
    const assignedUser =
      plannerItemData.assigned_to_id &&
      users.find(user => user.id === plannerItemData.assigned_to_id);
    const assignedValue = assignedUser && {
      value: assignedUser.id,
      label: assignedUser.name,
    };
    const ValueContainer = ({ children, ...props }) => {
      const value = props.getValue();
      const assigned = value && value[0];
      return (
        <selectComponents.ValueContainer {...props}>
          <div
            style={{
              display: 'flex',
              background: `url(${AssigneeIcon}) no-repeat scroll 0px 0px`,
              backgroundSize: 24,
              width: '100%',
              backgroundColor: styles.BackgroundColor,
            }}
          >
            &nbsp;
            {assigned && assigned.value > 0 && (
              <React.Fragment>
                {assigned && assigned.value > 0 && (
                  <Avatar style={{ marginLeft: 30 }} id={assigned.value} />
                )}
                <div style={{ paddingLeft: 10, paddingTop: 2 }}>
                  {assigned.label}
                </div>
              </React.Fragment>
            )}
            {(!assigned || assigned.value <= 0) && (
              <div style={{ marginLeft: 24, paddingTop: 2, color: 'grey' }}>
                Assignee
              </div>
            )}
            {children[1]}
          </div>
        </selectComponents.ValueContainer>
      );
    };
    return (
      <div className="plannerItemContainer">
        <div
          className="plannerItemDeleteBtn"
          style={{ visibility: showDeleteButton ? '' : 'hidden' }}
          onClick={() => onPlannerItemDelete()}
        ></div>
        <FormField style={{ flex: 1 }}>
          <input
            type="text"
            name="title"
            value={plannerItemData.title || ''}
            onChange={e => this.onTitleChange(e.target.value)}
            placeholder="Title"
            autoComplete="off"
            style={{ backgroundImage: `url(${TitleIcon})` }}
          />
        </FormField>
        <FormField>
          <div style={{ width: 165 }}>
            <DatePicker
              placeholderText="Time"
              timeCaption="Time"
              selected={
                plannerItemData.time &&
                moment(weekStartDate + ' ' + plannerItemData.time).valueOf()
              }
              onChange={time => this.onTimeChange(time)}
              showTimeSelect
              showTimeSelectOnly
              timeIntervals={15}
              dateFormat="h:mm a"
              popperPlacement="top-start"
            />
          </div>
          {plannerItemData.time && (
            <div style={{ width: 165, marginLeft: 10 }}>
              <DatePicker
                placeholderText="End time"
                timeCaption="End time"
                selected={
                  plannerItemData.endTime &&
                  moment(
                    weekStartDate + ' ' + plannerItemData.endTime,
                  ).valueOf()
                }
                onChange={time => this.onEndTimeChange(time)}
                showTimeSelect
                showTimeSelectOnly
                timeIntervals={15}
                dateFormat="h:mm a"
                popperPlacement="top-start"
              />
            </div>
          )}
        </FormField>
        <div style={{ display: 'flex', justifyContent: 'space-between' }}>
          <div
            style={{
              paddingBottom: 4,
              borderBottom: daily ? '1px solid lightgrey' : '2px solid black',
              color: daily ? 'lightgrey' : 'black',
              width: '50%',
              cursor: 'pointer',
              textAlign: 'center',
            }}
            onClick={() => this.onDailyChange(false)}
          >
            Use General Details
          </div>
          <div
            style={{
              paddingBottom: 4,
              borderBottom: daily ? '2px solid black' : '1px solid lightgrey',
              color: daily ? 'black' : 'lightgrey',
              width: '50%',
              cursor: 'pointer',
              textAlign: 'center',
            }}
            onClick={() => this.onDailyChange(true)}
          >
            Use Daily Details
          </div>
        </div>
        {daily && (
          <div>
            {[0, 1, 2, 3, 4, 5, 6].map(i => {
              return this.renderDay(
                i,
                idx(plannerItemData, _ => _.days[i]) || {},
              );
            })}
          </div>
        )}
        {!daily && (
          <div>
            <div
              style={{
                marginBottom: 5,
                display: 'flex',
                justifyContent: 'space-between',
                paddingTop: 4,
                paddingBottom: 4,
              }}
            >
              {[0, 1, 2, 3, 4, 5, 6].map(index => (
                <Day
                  key={index}
                  active={idx(plannerItemData, _ => _.days[index].active)}
                  onClick={() =>
                    this.onActiveChange(
                      index,
                      idx(plannerItemData, _ => _.days[index].active)
                        ? false
                        : true,
                    )
                  }
                >
                  {moment(weekStartDate).add(index, 'days').format('dd')}
                </Day>
              ))}
            </div>
            <div style={{ marginBottom: 4, marginTop: 4, paddingBottom: 4 }}>
              <ReactSelect
                value={assignedValue}
                onChange={e => this.onGeneralAssignmentChange(e)}
                options={humansAndGeneral.map(user => ({
                  value: user.id,
                  label: user.name,
                }))}
                styles={{
                  control: (base, state) => ({
                    ...base,
                    backgroundColor: styles.BackgroundColor,
                    borderColor: styles.BackgroundColor,
                    borderRadius: 8,
                    boxShadow: state.isFocused ? 0 : 0,
                    '&:hover': { borderColor: styles.BackgroundColor },
                  }),
                }}
                isSearchable={false}
                components={{
                  ValueContainer,
                  DropdownIndicator: null,
                  IndicatorSeparator: null,
                }}
              />
            </div>
            <div>
              <TextAreaInput
                className="description-field"
                icon={DescriptionIcon}
                rows="3"
                maxRows={10}
                cols="33"
                placeholder="Description"
                value={plannerItemData.description || ''}
                onChange={e => this.onGeneralDescriptionChange(e)}
              />
            </div>
          </div>
        )}
        {plannerItemData.errors && (
          <div style={{ color: 'red' }}>
            {plannerItemData.errors.map(e => {
              return <div key={e}>{e}</div>;
            })}
          </div>
        )}
      </div>
    );
  }
}
