import classNames from 'classnames';
import moment from 'moment';
import React, { useEffect, useState, useRef } from 'react';
import Modal from 'react-modal';
import { connect } from 'react-redux';
import { track } from '../../../utils/analytics';
import progressRedux from '../../../redux/progress';
import { callApi } from '../../../utils/callApi';
import { Button } from '../components/Button';
import { Level } from '../components/Level';
import { H2 } from '../components/Type';
import { DATE_FORMAT } from '../constants';
import './Triage.scss';
import { TriageDetail } from './TriageDetail';
import TriageList from './TriageList';
import TriageListMenu from './TriageListMenu';
import validateForm from './validateForm';
import { recombineTags } from '../ItemFormModal/ItemFormModal';

const Triage = props => {
  const {
    open,
    items,
    onRequestClose,
    saveItem,
    deleteItem,
    auth,
    progress,
    setProgress,
  } = props;

  const [selected, setSelected] = useState(null);
  const [animating, setAnimating] = useState(null);
  const [detailOpen, setDetailOpen] = useState(false);
  const [type, setType] = useState(null);
  const [formData, setFormData] = useState({});
  const [formErrors, setFormErrors] = useState([]);
  const [listsOpen, setListsOpen] = useState(false);
  const listButtonRef = useRef();

  useEffect(() => {
    if (!open) return;
    callApi('GET', 'api/progress').then(result => setProgress(result));
    track('triage:modal:open', {
      u: auth.userId,
      f: auth.family_id,
    });
  }, [open, setProgress, auth.userId, auth.family_id]);

  useEffect(() => {
    if (selected === null && items.length) {
      setSelected(items[0].id);
    }
  }, [items, selected, setSelected]);

  function onItemClick(id) {
    if (selected === id) {
      setDetailOpen(true);
    } else {
      if (type) {
        if (window.confirm('Leaving will lose any changes. Are you sure?')) {
          setType(null);
          setSelected(id);
        }
      } else {
        setSelected(id);
      }
    }
  }

  function closeModal() {
    if (
      type &&
      !window.confirm('Leaving will lose any changes. Are you sure?')
    ) {
      return;
    }
    setSelected(items.length ? items[0].id : null);
    setDetailOpen(false);
    setType(null);
    setFormErrors([]);
    onRequestClose();
  }

  function save() {
    const { result, errors } = validateForm(formData, type);
    if (result) {
      selectNext();
      setDetailOpen(false);
      const saveType = type;
      setType(null);
      setFormErrors([]);
      setAnimating({ id: formData.id, type });
      window.setTimeout(() => {
        setAnimating(null);
        saveItem({
          ...formData,
          tags: recombineTags([formData.tags, formData.priority && 'priority']),
          priority: undefined,
          data: {
            ...formData.data,
            triaged_by: auth.id,
            triaged_at: moment().format(DATE_FORMAT),
            triaged_to: saveType,
          },
        });
        const key = `triaged_${formData.data.source}`;
        setProgress({ ...progress, [key]: (progress[key] || 0) + 1 });
      }, 1000);
      track('triage:item:save', {
        u: auth.userId,
        f: auth.family_id,
      });
    } else {
      setFormErrors(errors);
    }
  }

  function cancel() {
    setDetailOpen(false);
    setType(null);
    setFormData({ ...formData, parent_id: undefined, linked_from: undefined });
    track('triage:item:cancel', {
      u: auth.userId,
      f: auth.family_id,
    });
  }

  function currentIndex() {
    return items.findIndex(item => item.id === selected);
  }

  function selectNext() {
    const index = currentIndex();
    if (index >= 0) {
      if (index < items.length - 1) {
        setSelected(items[index + 1].id);
      } else {
        setSelected(items[0].id);
      }
    }
  }

  const ACTIONS = [
    {
      id: 'event',
      name: 'Event',
      icon: 'calendar-alt',
      onClick: () => {
        setDetailOpen(true);
        setType('event');
      },
    },
    {
      id: 'todo',
      name: 'To Do',
      icon: 'check-square',
      onClick: () => {
        setDetailOpen(true);
        setType('todo');
      },
    },
    {
      id: 'file',
      name: 'File',
      icon: 'file',
      onClick: () => {
        setDetailOpen(true);
        setType('file');
      },
    },
    {
      id: 'list',
      name: 'Add to…',
      icon: 'list-ul',
      onClick: () => {
        setListsOpen(true);
        track('triage:add-to-list:click');
      },
      menu: (
        <TriageListMenu
          wrapperRef={listButtonRef}
          isOpen={listsOpen}
          close={() => setListsOpen(false)}
          formData={formData}
          onSelect={list => {
            setListsOpen(false);
            setFormData({
              ...formData,
              parent_id: list.id,
              linked_from: [list],
            });
            setDetailOpen(true);
            setType('todo');
          }}
        />
      ),
      ref: listButtonRef,
    },
    {
      id: 'delete',
      name: 'Delete',
      icon: 'trash-alt',
      onClick: () => {
        deleteItem(
          items.find(item => item.id === selected),
          async () => {
            selectNext();
            setAnimating({ id: selected, type: 'delete' });
            await new Promise(resolve => setTimeout(resolve, 1000));
          },
        );
      },
    },
  ];

  const BottomBar = (
    <div className="bottom-bar">
      {type ? (
        <div className="desktop-form-controls">
          <Button onClick={cancel} variant="secondary">
            Cancel
          </Button>
          <Button
            variant={classNames({
              green: type === 'todo',
              yellow: type === 'file',
            })}
            onClick={save}
          >
            Save
          </Button>
        </div>
      ) : (
        <div className="triage-action-buttons">
          {!type &&
            ACTIONS.map(({ id, name, icon, onClick, menu, ref }) => (
              <div className="triage-button" key={id} ref={ref}>
                <div onClick={onClick}>
                  <div className={classNames('triage-icon', `action-${id}`)}>
                    <box-icon name={icon} />
                  </div>
                  <h3>{name}</h3>
                </div>
                {menu}
              </div>
            ))}
        </div>
      )}
    </div>
  );

  return (
    <Modal
      className={classNames('Triage', {
        'detail-open': detailOpen,
        'form-open': !!type,
      })}
      overlayClassName="Triage__overlay"
      isOpen={open}
      onRequestClose={closeModal}
    >
      <div className="top-bar">
        {/* Showing/hiding elements of the top bar is handled in CSS to be more easily
            responsive */}
        <Level className="modal-title" variant="start">
          <H2>
            <box-icon name="chevron-left" onClick={cancel} />
            Triage
            {!!items.length && <span className="badge">{items.length}</span>}
            <span className="index-count">{`${currentIndex() + 1} of ${
              items.length
            }`}</span>
          </H2>
          <box-icon onClick={closeModal} name="x" />
        </Level>
        <Level className="mobile-form-controls" variant="center">
          <box-icon onClick={cancel} name="x" />
          <Button
            variant={classNames('small', {
              green: type === 'todo',
              yellow: type === 'file',
            })}
            onClick={save}
          >
            Save
          </Button>
        </Level>
      </div>
      <TriageList
        items={items}
        selected={selected}
        onItemClick={onItemClick}
        animating={animating}
      />
      <TriageDetail
        item={items.find(item => item.id === selected)}
        type={type}
        setType={setType}
        formData={formData}
        setFormData={setFormData}
        formErrors={formErrors}
        BottomBar={BottomBar}
      />
    </Modal>
  );
};

export default connect(
  state => ({
    auth: state.auth,
    progress: state.progress,
  }),
  {
    setProgress: progressRedux.set,
  },
)(Triage);
