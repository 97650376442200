import React from 'react';
import { connect } from 'react-redux';
import { Button } from '../../Home/components/Button';
import { storeLogin } from '../../../redux/auth';
import { callApi } from '../../../utils/callApi';

const GroupComplete = ({ answers, handleStoreLogin }) => {
  async function login() {
    const userData = answers.find(answers => answers.key === 'user');
    const passwordData = answers.find(answers => answers.key === 'password');
    const body = { email: userData.value.email, password: passwordData.value };
    const auth = await callApi('POST', 'login', undefined, body);
    if (auth && auth.access_token) {
      await handleStoreLogin(auth);
      window.location = process.env.REACT_APP_URL;
    }
  }

  return (
    <div className="Intro">
      <div className="title serif">
        <span role="img" aria-label="dancing man emoji">
          🕺🏾
        </span>{' '}
        Hurray!
      </div>
      <p>
        Your account has been created! We’re gifting you a 30 day free trial to
        get started. After that, it will be $15/month to keep going -- less than
        the cost of one hour of childcare.
      </p>
      <Button variant="green large" onClick={login}>
        Head to your family dash
        <box-icon name="right-arrow-alt" />
      </Button>
    </div>
  );
};

export default connect(() => ({}), {
  handleStoreLogin: storeLogin,
})(GroupComplete);
