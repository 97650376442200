import 'boxicons';
import React, { Fragment, useEffect, useRef, useState } from 'react';
import { useParams } from 'react-router-dom';
import { track } from '../../utils/analytics';
import { callApi } from '../../utils/callApi';
import { GroupComplete, groupQuestions } from './questions';
import './SignupWizard.scss';

const GroupSignupWizard = () => {
  const { hash } = useParams();
  const [data, setData] = useState({
    waitlist: {},
    family: { metadata: {}, users: [] },
  });

  const [answers, setAnswers] = useState([]);
  const [error, setError] = useState(false);

  // Skip the first step if we're coming back after paying with Stripe
  const [stepIndex, setStepIndex] = useState(0);
  const [complete, setComplete] = useState();
  const scrollRef = useRef(null);
  const QUESTIONS = [...groupQuestions, GroupComplete];

  // Check the waitlist hash exists and isn't complete
  useEffect(() => {
    track('group-signup:page:open', { hash: hash });
    callApi('GET', `api/invite/${hash}`).then(result => {
      setData(data => ({ ...data, waitlist: result.waitlist }));
    });
  }, [hash]);

  async function createAccount() {
    try {
      const result = await callApi(
        'PUT',
        `api/invite/${hash}`,
        undefined,
        answers,
      );
      setComplete(true);
      console.log(result);
    } catch (error) {
      setError(true);
    }
  }

  useEffect(() => {
    if (scrollRef.current) {
      scrollRef.current.scrollIntoView({ behavior: 'smooth' });
    }
    if (!complete && stepIndex === QUESTIONS.length - 1) {
      createAccount();
    }
  }, [createAccount, stepIndex, QUESTIONS.length, complete]);

  function saveAnswer(answer, isCurrent) {
    const newAnswer = Array.isArray(answer) ? answer : [answer];
    setAnswers([...answers, ...newAnswer]);
    setStepIndex(stepIndex + 1);
  }

  return (
    <div className="SignupWizard">
      <div className="header centered">
        Milo: Built to tackle the invisible load of running a family
      </div>
      {data.waitlist.id && !error && (
        <div className="questions">
          {!complete &&
            QUESTIONS.slice(0, stepIndex + 1).map((Question, idx) => (
              <Fragment key={idx}>
                {stepIndex === idx + 1 && <div ref={scrollRef} />}

                <Question
                  data={data}
                  onAnswer={answer => saveAnswer(answer, idx === stepIndex)}
                  isCurrent={stepIndex === idx}
                  showComment={stepIndex === idx + 1}
                  hash={hash}
                  answers={answers}
                />
              </Fragment>
            ))}

          {complete && <GroupComplete answers={answers} />}

          <div className="page">{`${stepIndex + 1} of ${
            QUESTIONS.length
          }`}</div>
        </div>
      )}
      {!data.waitlist.id && (
        <div className="questions">😔 Campaign not found</div>
      )}
      {error && (
        <div className="error">
          😔 Failed to created account. Please contact{' '}
          <a href="mailto:onboarding@joinmilo.com">Milo</a>
        </div>
      )}
    </div>
  );
};

export default GroupSignupWizard;
