import { combineReducers } from 'redux';
import auth from './auth';
import channels from './channels';
import editItem from './edit-item';
import input from './input';
import itemFormModal from './itemFormModal';
import { reducer as items } from './items';
import loading from './loading';
import modal from './modal';
import scroll from './scroll';
import type from './type';
import { reducer as progress } from './progress';
import { reducer as onboardingStep } from './onboardingStep';

export default combineReducers({
  auth,
  items,
  editItem,
  input,
  scroll,
  channels,
  loading,
  type,
  modal,
  itemFormModal,
  progress,
  onboardingStep,
});
