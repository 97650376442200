import React, { useCallback, useEffect, useState } from 'react';
import { DragDropContext, Draggable, Droppable } from 'react-beautiful-dnd';
import { useDispatch } from 'react-redux';
import { openItemFormModal } from '../../../redux/itemFormModal';
import { Button } from '../components/Button';
import { Card, CardBody, CardFooterLink } from '../components/Card';
import {
  FilesEmptyState,
  MiloCategoryEmptyState,
} from '../components/EmptyState';
import { Item } from '../components/Item';
import { Level } from '../components/Level';
import ButtonDropdown from '../components/ButtonDropdown';
import {
  CATEGORIES,
  CATEGORY_EMOJI,
  DEFAULT_CATEGORIES,
  TYPE_FILE,
} from '../constants';
import { track } from '../../../utils/analytics';
import './Files.scss';

const Files = props => {
  const { files = [], cardMarkComplete, saveItem } = props;

  const dispatch = useDispatch();
  const openItemForm = useCallback(
    prefill => dispatch(openItemFormModal(prefill)),
    [dispatch],
  );

  const [filesByColumn, setFilesByColumn] = useState({});
  const [columnOrder, setColumnOrder] = useState([]);
  const [activeCol, setActiveCol] = useState('general');

  useEffect(() => {
    document.documentElement.classList.add('body-scroll');
    return () => {
      document.documentElement.classList.remove('body-scroll');
    };
  }, []);

  // Process incoming files into columns
  useEffect(() => {
    // Start off by creating columns for default categories
    const defaultColumns = DEFAULT_CATEGORIES.reduce(
      (acc, cat) => ({ ...acc, [cat]: [] }),
      {},
    );

    // Sort files by id, then add them to the appropriate columns, creating new ones as needed
    const columns = files
      .sort((a, b) => a.id - b.id)
      .reduce((acc, file) => {
        const tag = file.tags || 'general'; // Anything without a tag defaults to `general`
        if (!acc[tag]) {
          acc[tag] = [];
        }
        acc[tag].push(file);
        return acc;
      }, defaultColumns);

    // Save the hash into state
    setFilesByColumn(columns);

    // Save the order into state: start with categories, filter out anything without a column, then
    // add in any categories that aren't in our list (likely from an older category set)
    setColumnOrder(
      CATEGORIES.filter(cat => columns[cat]).concat(
        Object.keys(columns).filter(cat => !CATEGORIES.includes(cat)),
      ),
    );
  }, [files, setFilesByColumn, setColumnOrder]);

  useEffect(() => {
    track('files:page:load');
  }, []);

  const sortedColumns = columnOrder.map(id => ({
    id,
    items: filesByColumn[id] || [],
  }));

  return (
    <div className="Files">
      <div className="card-container">
        <DragDropContext
          onDragEnd={async result => {
            // Handle a drop. This will move the item in local state, then make the necessary API
            // calls for the backend.

            const { source, destination } = result;

            // Return early if a drop is cancelled
            if (!destination) return;

            // Only support drag from one column to another
            if (destination.droppableId !== source.droppableId) {
              // If destination and source aren't the same, we're moving from one column to another

              // Grab the columns and make copies of their item arrays
              const sourceColumn = filesByColumn[source.droppableId];
              const destinationColumn =
                filesByColumn[destination.droppableId] || [];
              const sourceItems = [...sourceColumn];
              const destinationItems = [...destinationColumn];

              // Splice the item from the source to the destination
              const [draggedItem] = sourceItems.splice(source.index, 1);
              destinationItems.splice(destination.index, 0, draggedItem);

              // Set the local column state with both modified columns
              setFilesByColumn({
                ...filesByColumn,
                [source.droppableId]: sourceItems,
                [destination.droppableId]: destinationItems,
              });

              // Make the API call to update the item on the server with its new category
              await saveItem({
                ...draggedItem,
                tags: destination.droppableId,
              });
            }
          }}
        >
          {sortedColumns.map(({ id, items }, idx) => (
            <Card key={id} className={id !== activeCol ? 'hide-mobile' : ''}>
              <CardBody>
                <Level>
                  <Button
                    variant="clear"
                    className="show-mobile"
                    onClick={() => setActiveCol(sortedColumns[idx - 1].id)}
                    disabled={idx === 0}
                  >
                    <box-icon name="chevron-left" />
                  </Button>

                  <ButtonDropdown
                    variant="clear"
                    side="center"
                    className="show-mobile column-picker"
                    top={30}
                    options={sortedColumns.map(({ id }) => {
                      return {
                        name: id,
                        onClick: () => setActiveCol(id),
                        disabled: id === activeCol,
                      };
                    })}
                  >
                    <h2>
                      {CATEGORY_EMOJI[id]} {id}
                    </h2>
                  </ButtonDropdown>
                  <h2 className="hide-mobile">
                    {CATEGORY_EMOJI[id]} {id}
                  </h2>

                  <Button
                    variant="clear"
                    className="show-mobile"
                    onClick={() => setActiveCol(sortedColumns[idx + 1].id)}
                    disabled={idx === sortedColumns.length - 1}
                  >
                    <box-icon name="chevron-right" />
                  </Button>

                  <Button
                    className="hide-mobile"
                    variant="clear"
                    onClick={() =>
                      openItemForm({
                        tags: id,
                        type: TYPE_FILE,
                      })
                    }
                  >
                    <box-icon name="plus" />
                  </Button>
                </Level>
              </CardBody>
              <Droppable droppableId={id}>
                {droppableProvided => (
                  <div
                    className="droppable-container"
                    ref={droppableProvided.innerRef}
                    {...droppableProvided.droppableProps}
                  >
                    {items.map((item, index) => (
                      <Draggable
                        index={index}
                        key={item.id}
                        draggableId={item.id.toString()}
                      >
                        {(draggableProvided, draggableSnapshot) => (
                          <Item
                            ref={draggableProvided.innerRef}
                            draggableProps={draggableProvided.draggableProps}
                            dragHandleProps={draggableProvided.dragHandleProps}
                            item={item}
                            cardMarkComplete={cardMarkComplete}
                            variant={draggableSnapshot.isDragging && 'dragging'}
                            showDate={true}
                          />
                        )}
                      </Draggable>
                    ))}
                    {droppableProvided.placeholder}
                    {!items.length &&
                      (id === 'milo' ? (
                        <MiloCategoryEmptyState />
                      ) : (
                        <FilesEmptyState />
                      ))}
                  </div>
                )}
              </Droppable>
              <CardFooterLink
                onClick={() =>
                  openItemForm({
                    tags: id,
                    type: TYPE_FILE,
                  })
                }
              >
                <box-icon name="plus" />
                Add Item
              </CardFooterLink>
            </Card>
          ))}
        </DragDropContext>
      </div>
    </div>
  );
};

export default Files;
