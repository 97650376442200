import React, { useState } from 'react';
import { connect } from 'react-redux';
import { open as openModal } from '../../../../redux/modal';
import './TriageHelp.scss';

const STORAGE_KEY = 'dash:triage-help:hide';

const TriageHelp = props => {
  const {
    auth: {
      user: { metadata },
    },
    progress,
  } = props;

  const [hidden, setHidden] = useState(
    !!window.localStorage.getItem(STORAGE_KEY),
  );

  const totalTriaged =
    progress && (progress.triaged_sms || 0) + (progress.triaged_email || 0);
  if (!progress || totalTriaged > 5) {
    return null;
  }

  const { milo_sent_sms, milo_sent_email } = metadata || {};

  if (milo_sent_sms && milo_sent_email) {
    if (hidden) return null;
    return (
      <div
        className="TriageHelp completed"
        onClick={() => props.openModal('triage')}
      >
        <box-icon
          name="x"
          onClick={evt => {
            setHidden(true);
            window.localStorage.setItem(STORAGE_KEY, 'hide');
            evt.stopPropagation();
          }}
        />
        <h2 className="sans">
          <span role="img" aria-label="Satellite Antenna emoji">
            📡
          </span>
          &nbsp; Triage is ready to receive! <box-icon name="check" />
        </h2>
        <p>
          Practice this week: each time a to-do pops into your head, send it to
          Milo. Forward us school emails, tax docs, or anything else you need to
          quickly add and organize later.
        </p>
      </div>
    );
  }

  if (milo_sent_sms || milo_sent_email) {
    return (
      <div className="TriageHelp" onClick={() => props.openModal('triage')}>
        <h2 className="sans">You&rsquo;ve almost set up Triage!</h2>
        <p>
          Complete a few more steps to magically send to-dos, reminders, and
          emails to Milo.
        </p>
      </div>
    );
  }

  return (
    <button
      className="TriageHelp"
      type="button"
      onClick={() => props.openModal('triage')}
    >
      <h2 className="sans">Set up Triage</h2>
      <p>
        Magically send to-dos,
        <br />
        reminders, and emails to Milo.
      </p>
    </button>
  );
};

export default connect(
  state => ({
    auth: state.auth,
    progress: state.progress,
    items: state.items,
  }),
  {
    openModal,
  },
)(TriageHelp);
