import classNames from 'classnames';
import React, {
  useCallback,
  useEffect,
  useRef,
  useState,
  useMemo,
} from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { withRouter } from 'react-router-dom';
import { closeItemFormModal } from '../../../redux/itemFormModal';
import { Button } from '../components/Button';
import ConfirmModal from '../components/ConfirmModal';
import { ItemForm } from '../components/ItemForm/ItemForm';
import { MiloModal, MiloModalButtons } from '../components/MiloModal';
import {
  TYPE_EVENT,
  TYPE_FILE,
  TYPE_TODO,
  TYPE_SERIES,
  CATEGORIES,
} from '../constants';
import validateForm from '../Triage/validateForm';
import './ItemFormModal.scss';

function inferType(item) {
  if (item.linked_items && item.linked_items.find(child => child.date)) {
    return TYPE_SERIES;
  } else if (item.type === TYPE_FILE) {
    return TYPE_FILE;
  } else if (item.status) {
    return TYPE_TODO;
  } else {
    return TYPE_EVENT;
  }
}

const confirmSaveOnOpen = 'Save this item before editing the next one?';

function separateTags(tags = '') {
  const userTags = [];
  const internalTags = [];
  tags
    .trim()
    .split(' ')
    .filter(tag => !!tag)
    .forEach(tag =>
      CATEGORIES.includes(tag) ? userTags.push(tag) : internalTags.push(tag),
    );
  return {
    userTags: userTags.join(' ') || undefined,
    internalTags: internalTags.join(' ') || undefined,
  };
}

export function recombineTags(tags) {
  return (
    tags
      .reduce((acc, tag) => {
        const trimmedTag = tag && tag.trim();
        if (trimmedTag) {
          return `${acc} ${trimmedTag}`;
        } else {
          return acc;
        }
      }, '')
      .trim() || undefined
  );
}

const ItemFormModal = props => {
  const [loading, setLoading] = useState(false);
  const [type, setType] = useState(TYPE_EVENT);
  const [parentItems, setParentItems] = useState([]);
  const [formData, setFormData] = useState({});
  const [formErrors, setFormErrors] = useState([]);
  const [showConfirmModal, setShowConfirmModal] = useState(false);
  const scrollLeft = useRef(null);
  const { open, requestClose, hideSidePanel } = props;
  const { family_metadata } = useSelector(state => state.auth);

  const { isOpen: reduxOpen, prefill } = useSelector(
    state => state.itemFormModal,
  );
  const isOpen = open || reduxOpen;
  const dispatch = useDispatch();
  const reduxClose = useCallback(
    () => dispatch(closeItemFormModal()),
    [dispatch],
  );
  const closeModal = requestClose || reduxClose;

  // Editing an existing item and prefilling fields for a new item work the same way. If either is
  // present, copy their properties, separate out any internal tags (saving for recombining later)
  // and memo-ize the result to prevent an infinite loop.
  const startingValues = useMemo(() => {
    const start = { ...(props.item || prefill) };

    const separatedTags = separateTags(start.tags);
    start.tags = separatedTags.userTags;

    start.priority =
      !!separatedTags.internalTags &&
      separatedTags.internalTags.includes('priority');

    start.internalTags =
      separatedTags.internalTags &&
      separatedTags.internalTags.replace('priority', '');

    return start;
  }, [props.item, prefill]);

  // Use starting values to infer a type, then copy into the form
  useEffect(() => {
    setType(inferType(startingValues));
    setFormData({ ...startingValues });
  }, [startingValues]);

  // - Reset errors when modal is opened or closed
  // - Save scrollLeft position on modal open and jump page back to it on modal close
  useEffect(() => {
    setFormErrors([]);

    if (isOpen) {
      scrollLeft.current = document.getElementsByTagName('body')[0].scrollLeft;
    } else if (scrollLeft.current) {
      document.getElementsByTagName('body')[0].scrollTo(scrollLeft.current, 0);
      scrollLeft.current = null;
    }
  }, [isOpen]);

  // When the user tries to open a child item, prompt them to save the current item first (they
  // can't proceed without doing so). If the item saves with no errors, push the current item's id
  // onto the parentItems stack and open the child.
  async function openChild(childItem) {
    setLoading(true);

    // If dirty and no confirm then do nothing
    if (formData.dirty && !window.confirm(confirmSaveOnOpen)) {
      setLoading(false);
      return;
    }

    if (formData.dirty) {
      // Ok, this item is dirty and the user want's to continue.

      // We need to save the parent and if the child is new (no id)
      // create the child before opening so it has an id
      let openId = childItem.id;

      // Special case: If we are opening a child that hasn't been
      // saved yet,  we need to exclude that from this save of
      // the parent and create the child item manually
      let parentId;
      if (!childItem.id) {
        parentId = await props.saveItem({
          ...{
            ...formData,
            linked_items: formData.linked_items.filter(
              child => child.tId !== childItem.tId,
            ),
          },
          tags: recombineTags([
            formData.tags,
            formData.internalTags,
            formData.priority && 'priority',
          ]),
        });
        const newChildId = await props.saveItem({
          ...childItem,
          parent_id: formData.id || parentId,
        });
        openId = newChildId;
      } else {
        parentId = await props.saveItem({
          ...formData,
          tags: recombineTags([
            formData.tags,
            formData.internalTags,
            formData.priority && 'priority',
          ]),
        });
      }
      setParentItems([...parentItems, formData.id || parentId]);
      props.openItemFormModal(openId);
    } else {
      // Not dirty, just open
      setParentItems([...parentItems, formData.id]);
      props.openItemFormModal(childItem.id);
    }
    setLoading(false);
  }

  // When the user tries to open the parent item, prompt them to save the current item first (they
  // can't proceed without doing so). If the item saves with no errors:
  // - if there's anything in the stack, call close (which will pop the stack)
  // - otherwise, use linked_from to find the parent id and open that item
  function openParent() {
    if (
      (formData.dirty && window.confirm(confirmSaveOnOpen) && saveItem()) ||
      !formData.dirty
    ) {
      if (parentItems && parentItems.length) {
        close();
      } else if (formData.linked_from && formData.linked_from.length) {
        props.openItemFormModal(formData.linked_from[0].id);
      }
    }
  }

  // Validate the form. If there are any errors, display them and abort. If not, reset errors, save
  // the item with internal/priority/user-facing tags recombined, and close this item.
  // Return a boolean representing if the form was valid.
  async function saveItem() {
    setLoading(true);
    const { result, errors } = validateForm(formData, type);
    if (result) {
      setFormErrors([]);

      // Save the item but don't save any children that don't have ids yet
      const id = await props.saveItem({
        ...{
          ...formData,
          linked_items:
            formData.linked_items &&
            formData.linked_items.filter(child => !!child.id),
        },
        tags: recombineTags([
          formData.tags,
          formData.internalTags,
          formData.priority && 'priority',
        ]),
      });

      // Now save the children and attach to the parent
      if (formData.linked_items) {
        await Promise.all(
          formData.linked_items.map(async child =>
            child.deleted
              ? props.deleteItem(
                  { ...child, parent_id: formData.id || id },
                  undefined,
                  true,
                )
              : child.dirty &&
                props.saveItem({ ...child, parent_id: formData.id || id }),
          ),
        );
      }

      close();
    } else {
      setFormErrors(errors);
    }
    setLoading(false);
    return !!result;
  }

  // Delete the item and close this item as a callback (after confirmation but before deletion)
  function deleteItem() {
    setLoading(true);
    props.deleteItem(formData, () => close());
    setLoading(false);
  }

  // If there's anything in the stack, pop it. Otherwise reset formData and close modal entirely.
  function close() {
    if (parentItems && parentItems.length) {
      const newParentItems = [...parentItems];
      const parent = newParentItems.pop();
      setParentItems(newParentItems);
      props.openItemFormModal(parent); // either id or prefill
    } else {
      setFormData({});
      closeModal();
    }
  }

  function confirmAndClose() {
    if (formData.dirty) {
      setShowConfirmModal(true);
    } else {
      close();
    }
  }

  return (
    <MiloModal
      isOpen={isOpen}
      onRequestClose={confirmAndClose}
      size={'narrow'}
      fullWidthButtons={true}
    >
      <div className={`ItemFormModal${loading ? ' loading' : ''}`}>
        <div className="columns">
          <div className="form-col">
            <ItemForm
              item={startingValues}
              type={type}
              setType={props.disableTypeSwitcher ? undefined : setType}
              formData={formData}
              setFormData={setFormData}
              openChild={openChild}
              openParent={openParent}
              formErrors={formErrors}
              showSearchDropdown={true}
              showAddMoreDates={(family_metadata || {}).signup_version === '1'}
            />
          </div>
        </div>
        <MiloModalButtons>
          <div>
            {props.item && (
              <Button onClick={deleteItem} variant="red">
                Delete
              </Button>
            )}
          </div>

          <div>
            <Button onClick={confirmAndClose} variant="secondary">
              Cancel
            </Button>
            <Button
              variant={classNames({
                green: type === 'todo',
                yellow: type === 'file',
              })}
              onClick={saveItem}
            >
              Save
            </Button>
          </div>
        </MiloModalButtons>
      </div>
      <ConfirmModal
        isOpen={showConfirmModal}
        setOpen={setShowConfirmModal}
        title="Save edits?"
        helptext="Your changes will be discarded if you don&rsquo;t save."
      >
        <Button
          variant="red"
          onClick={() => {
            close();
            setShowConfirmModal(false);
          }}
        >
          Discard
        </Button>
        <div>
          <Button
            variant="secondary"
            onClick={() => setShowConfirmModal(false)}
          >
            Cancel
          </Button>
          <Button
            onClick={() => {
              saveItem();
              setShowConfirmModal(false);
            }}
          >
            Save
          </Button>
        </div>
      </ConfirmModal>
    </MiloModal>
  );
};

export default withRouter(ItemFormModal);
