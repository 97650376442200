import React, { useState, useEffect } from 'react';
import { connect } from 'react-redux';
import classNames from 'classnames';
import moment from 'moment';
import { track } from '../../../utils/analytics';
import { open as openModal } from '../../../redux/modal';
import { Card, CardBody } from './Card';
import { Level } from './Level';
import { DATE_FORMAT } from '../constants';
import { callApi } from '../../../utils/callApi';

import './GetStarted.scss';

const STORAGE_KEY_COLLAPSED = 'get-started:dash-card:expanded';

const GetStarted = ({ auth, progress, items, openModal }) => {
  const [collapsed, setCollapsed] = useState(
    !!window.localStorage.getItem(STORAGE_KEY_COLLAPSED),
  );

  useEffect(() => {
    if (
      !(auth.family_metadata || {}).onboarding_complete &&
      step1Complete() &&
      step2Complete() &&
      step3Complete()
    ) {
      callApi('PUT', 'api/onboarding');
    }
  }, [auth.family_emoji, auth.family_metadata, auth.users, items]);

  if ((auth.family_metadata || {}).onboarding_complete) {
    return null;
  }

  function step1Complete() {
    return (
      auth.family_emoji ||
      (auth.family_metadata && auth.family_metadata.background_color)
    );
  }

  function step2Complete() {
    const numCollabs =
      auth.users &&
      auth.users.filter(user => user.name !== 'bot' && user.name !== auth.name)
        .length;
    return !!numCollabs;
  }

  function step3Complete() {
    const createdItems = !!(progress && progress.in_app && progress.in_app > 0);
    const tomorrowItem =
      items &&
      !!items.find(
        item => item.date === moment().add(1, 'day').format(DATE_FORMAT),
      );
    return createdItems || tomorrowItem;
  }

  function toggleCollapsed() {
    if (collapsed) {
      window.localStorage.removeItem(STORAGE_KEY_COLLAPSED);
    } else {
      window.localStorage.setItem(STORAGE_KEY_COLLAPSED, 'collapsed');
    }

    setCollapsed(!collapsed);
  }

  function handleClick(step) {
    openModal(step);
    track('get-started:dash-card:click', { step });
  }

  return (
    <Card className="GetStarted">
      <CardBody>
        <Level variant="center">
          <h2>Get started</h2>
          <div className="collapse" onClick={toggleCollapsed}>
            {collapsed ? <box-icon name="window" /> : <box-icon name="minus" />}
          </div>
        </Level>

        {!collapsed && (
          <>
            <div className={classNames('step', { completed: true })}>
              <div className="number">
                <box-icon name="check" />
              </div>
              <span>Create family account</span>
            </div>
            <div
              className={classNames('step', { completed: step1Complete() })}
              onClick={() => handleClick('family-settings')}
            >
              <div className="number">
                <box-icon name="check" />
              </div>
              <span>Customize family dash</span>
              <box-icon name="chevron-right" />
            </div>
            <div
              className={classNames('step', { completed: step2Complete() })}
              onClick={() => handleClick('family')}
            >
              <div className="number">
                <box-icon name="check" />
              </div>
              <span>Add your team</span>
              <box-icon name="chevron-right" />
            </div>
            <div
              className={classNames('step', { completed: step3Complete() })}
              onClick={() => handleClick('tomorrow')}
            >
              <div className="number">
                <box-icon name="check" />
              </div>
              <span>Plan for tomorrow</span>
              <box-icon name="chevron-right" />
            </div>
          </>
        )}
      </CardBody>
    </Card>
  );
};

export default connect(
  state => ({
    auth: state.auth,
    progress: state.progress,
    items: state.items,
  }),
  {
    openModal,
  },
)(GetStarted);
