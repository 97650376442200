import 'boxicons';
import React from 'react';
import { connect } from 'react-redux';
import moment from 'moment';
import { openItemFormModal } from '../../../redux/itemFormModal';
import { Card, CardFooterLink } from '../components/Card';
import './MissionCriticalWidget.scss';
import MissionCriticalList from './MissionCriticalList';
import { COMPLETE } from '../constants';
import { sortItems } from '../utils';

const MissionCriticalWidget = props => {
  const { missionCriticalItems, cardMarkComplete, openItemFormModal } = props;
  const tags = 'priority';
  const sinceYesterday = moment().subtract(1, 'day');
  const nextWeek = moment().add(1, 'week');
  const thisWeekMissionCritical = missionCriticalItems
    .filter(item => moment(item.date) < nextWeek)
    .filter(item => {
      if (item.status === undefined && moment(item.date) < sinceYesterday) {
        return null;
      }
      return item;
    })
    .filter(item => {
      if (item.status === undefined && moment(item.date) < sinceYesterday) {
        return null;
      }
      if (item.status === undefined && moment(item.date) < sinceYesterday) {
        return null;
      }
      if (
        item.status === COMPLETE &&
        moment(item.data.completed_at) < sinceYesterday
      ) {
        return null;
      }
      return item;
    });
  const datedItems = sortItems(
    thisWeekMissionCritical.filter(item => item.date),
  );

  let bottomBorderFirst = '';
  if (datedItems.length === 0 || datedItems.length > 3) {
    bottomBorderFirst = 'Border';
  }
  return (
    <Card>
      <MissionCriticalList
        data={datedItems}
        border={bottomBorderFirst}
        cardMarkComplete={cardMarkComplete}
        timestamp="This Week"
        subtitle={`😅 We’ll give you an extra 💬 reminder the Sunday and night before!`}
        title="Mission Critical"
      />
      <CardFooterLink onClick={() => openItemFormModal({ tags })}>
        <box-icon name="plus" />
        Add to Mission Critical
      </CardFooterLink>
    </Card>
  );
};

export default connect(
  state => ({ auth: state.auth, itemFormModal: state.itemFormModal }),
  { openItemFormModal },
)(MissionCriticalWidget);
