import React from 'react';
import Widget from '../Widget';
import PlannerButton from './PlannerButton';

const WIDGET_NAME = 'planner';

const modes = {
  button: PlannerButton,
};

const PlannerWidget = props => {
  const { mode, open, close } = props;
  return modes[mode] ? (
    <Widget
      key={WIDGET_NAME}
      name={WIDGET_NAME}
      active={modes[mode]}
      open={open}
      onRequestClose={close}
    />
  ) : null;
};

export default { name: WIDGET_NAME, widget: PlannerWidget };
