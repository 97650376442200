import moment from 'moment';
import React from 'react';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import uuid from 'uuid/v4';
import { track } from '../../../utils/analytics';
import { callApi } from '../../../utils/callApi';
import { Button } from '../../Home/components/Button';
import ConfirmModal from '../../Home/components/ConfirmModal';
import {
  MiloModal,
  MiloModalButtons,
  MiloModalContent,
  MiloModalControls,
} from '../../Home/components/MiloModal';
import { DATE_FORMAT } from '../constants';
import utils, {
  getDateRangeString,
  sortPlannerItemsByDateThenTime,
} from '../utils';
import { alignDays } from '../Week/align-days';
import WeekSelect from '../Week/WeekSelect';
import { itemsToPlannerItems } from './itemsToPlannerItems';
import './Planner2.scss';
import Planner2Item from './Planner2Item.js';
import { plannerItemsToTemplate } from './plannerItemsToTemplate';
import Preview from './Preview';
import { templateToPlannerItems } from './templateToPlannerItems';
import { storeLogin } from '../../../redux/auth';
import { saveWeekCollapsed } from '../Week/Week';

const defaultTemplateName = 'Family Weekly Schedule';

class Planner2 extends React.Component {
  //#region logic
  state = {
    errors: undefined,
    lastWeek: undefined,
    loading: false,
    plannerData: this.emptyPlannerData(),
    lastWeekItems: undefined,
    templates: undefined,
    open: false,
    showPreview: false,
    showApplyConfirm: false,
    showCancelConfirm: false,
    startingPoint: undefined,
    dirty: true,
    showTemplateWarning: false,
    newTemplate: false,
  };

  componentDidMount() {
    this.getLastWeek();
    this.getTemplates();
  }

  async componentDidUpdate(prevProps) {
    const { lastWeek, templateId, showPreview } = this.state;
    const {
      weekStartDate,
      auth,
      itemsContainsTemplateId,
      existingItemsByDay,
    } = this.props;

    if (lastWeek !== weekStartDate) {
      this.getLastWeek();
      this.setState({ lastWeek: weekStartDate });
    }

    if (this.props.open && !prevProps.open) {
      this.resetState();
      await this.getTemplates();

      track(`schedule-builder:edit:open`, {
        u: auth.userId,
        f: auth.family_id,
      });
    }

    if (
      showPreview &&
      (weekStartDate !== prevProps.weekStartDate ||
        this.itemsChanged(prevProps.existingItemsByDay, existingItemsByDay))
    ) {
      this.setState({
        showTemplateWarning: itemsContainsTemplateId(templateId),
        previewItems: this.assemblePreviewItems(),
      });
    }
  }

  // Figure out if we need to refresh the existing items
  // Find the first event in each list and compare the dates
  itemsChanged(prevItems, newItems) {
    const prevEvent = prevItems.flat().find(item => !!item.date);
    const newEvent = newItems.flat().find(item => !!item.date);
    return (
      (prevEvent && !newEvent) ||
      (!prevEvent && newEvent) ||
      ((prevEvent && prevEvent.date) || '') !==
        ((newEvent && newEvent.date) || '')
    );
  }

  resetState() {
    this.getLastWeek();
    this.setState({
      open: this.props.open,
      templateName: undefined,
      templateId: undefined,
      newTemplate: false,
      plannerData: this.emptyPlannerData(),
      startingPoint: undefined,
      dirty: false,
      errors: undefined,
    });
  }

  emptyPlannerData() {
    return [{ days: [{}, {}, {}, {}, {}, {}, {}], description: '' }];
  }

  addPlannerItem() {
    const { plannerData } = this.state;
    this.setState({
      plannerData: [...plannerData, { days: this.emptyPlannerData()[0].days }],
      dirty: true,
    });
  }

  onPlannerItemDataChange(itemIndex, newData) {
    const { plannerData } = this.state;
    const updated = plannerData.map((item, index) =>
      index === itemIndex ? newData : item,
    );
    this.setState({ plannerData: updated, dirty: true });
  }

  onPlannerItemDelete(itemIndex) {
    const { plannerData } = this.state;
    const updated = plannerData.filter((_, index) => index !== itemIndex);
    this.setState({ plannerData: updated, dirty: true });
  }

  async getTemplates() {
    const templates = await callApi('GET', 'api/templates');
    this.setState({ templates });
  }

  empty() {
    const { plannerData } = this.state;
    return (
      plannerData.length === 1 &&
      JSON.stringify(plannerData) === JSON.stringify(this.emptyPlannerData())
    );
  }

  addPlannerItems(newPlannerItems) {
    const { plannerData } = this.state;
    const updatedPlannerData = this.empty()
      ? newPlannerItems
      : [...newPlannerItems, ...plannerData];
    const sortedPlannerData = sortPlannerItemsByDateThenTime(
      updatedPlannerData,
    );
    this.setState({ plannerData: sortedPlannerData });
  }

  async useTemplate(id) {
    if (
      !this.empty() &&
      !window.confirm('Are you sure you want to overrite the existing plan?')
    ) {
      return;
    }
    const { templates } = this.state;
    this.setState({
      loading: true,
      plannerData: this.emptyPlannerData(),
    });
    const template = templates.find(template => +template.id === +id);
    const templateBlocks = await callApi('GET', `api/templates/${id}`);
    const newPlannerItems = templateToPlannerItems(id, templateBlocks);
    this.addPlannerItems(newPlannerItems);
    await this.setState({
      loading: false,
      templateName: template.name,
      templateId: id,
      errors: undefined,
      startingPoint: 'template',
    });
    this.openPreview();
  }

  getUniqueTemplateName(name) {
    if (name === null) return false;
    if (!name) {
      return this.getUniqueTemplateName(
        window.prompt('Enter a name for your new template.'),
      );
    }
    if (this.state.templates.some(template => template.name === name)) {
      return this.getUniqueTemplateName(
        window.prompt(
          'That name is already taken. Try another name for your new template.',
        ),
      );
    } else {
      return name;
    }
  }

  createDefaultTemplate() {
    this.setState({
      templateId: undefined,
      templateName: defaultTemplateName,
      plannerData: this.emptyPlannerData(),
      errors: undefined,
      startingPoint: 'template',
      newTemplate: true,
    });
  }

  async saveTemplate(createNewPlan) {
    const { plannerData, templates, templateName } = this.state;
    const { auth } = this.props;
    const formData = this.validateAndGetFormData();
    if (!formData) return;
    const template_items = plannerItemsToTemplate(plannerData);
    if (createNewPlan) {
      // "Save As..."
      const name = templateName || this.getUniqueTemplateName();
      if (!name) return;
      const templateId = await callApi('POST', `api/templates`, undefined, {
        template_items,
        name,
      });
      this.setState({
        templateId,
        templates: [...templates, { id: templateId, name }],
        errors: undefined,
      });
    } else {
      // "Save"
      const { templateId, templateName: name } = this.state;
      const result = await callApi(
        'PUT',
        `api/templates/${templateId}`,
        undefined,
        { template_items, name },
      );
      if (result === 204) {
        this.setState({ errors: undefined });
      }
    }
    this.props.storeLogin({ ...auth, has_templates: true });
    track(`schedule-builder:edit:template-save`, {
      u: auth.userId,
      f: auth.family_id,
    });
  }

  async deleteTemplate(templateId) {
    const { templates } = this.state;
    if (!templateId || templateId < 100000) {
      alert('Cannot delete');
      return;
    }
    const template = templates.find(template => template.id === templateId);
    const templateName = template && template.name;
    if (window.confirm(`Are you sure you want to delete "${templateName}"?`)) {
      const result = await callApi('DELETE', `api/templates/${templateId}`);
      if (result === 204) {
        this.setState({
          templateId: undefined,
          templateName: undefined,
          templates: templates.filter(template => +template.id !== +templateId),
          plannerData: this.emptyPlannerData(),
        });
      }
    }
  }

  getLastWeek() {
    // call the api with last weeks data range
    const { weekStartDate, groupId } = this.props;
    const start = moment(weekStartDate).add(-7, 'days').format(DATE_FORMAT);
    const end = moment(start).add(6, 'days').format(DATE_FORMAT);
    let url = `start_date=${start}&end_date=${end}}&source=planner`;
    url += groupId ? `&group_id=${groupId}` : '';
    callApi('GET', 'api/items', url).then(items => {
      const withDate =
        items &&
        items.filter(
          item =>
            item.date &&
            moment(item.date).isSameOrAfter(moment(start)) &&
            moment(item.date).isSameOrBefore(moment(end)),
        );
      this.setState({ lastWeekItems: withDate });
    });
  }

  copyFromLastWeek() {
    const { lastWeekItems } = this.state;
    const { auth } = this.props;
    const newPlannerItems = itemsToPlannerItems(lastWeekItems);
    this.addPlannerItems(newPlannerItems);
    this.setState({ lastWeekItems: undefined, startingPoint: 'copy' });
    track(`schedule-builder:edit:copy-last-week`, {
      u: auth.userId,
      f: auth.family_id,
    });
  }

  validateAndGetFormData(plannerSession) {
    const { weekStartDate } = this.props;
    const { plannerData } = this.state;
    let errorsFound = false;

    const formData = [];
    for (let plannerItemData of plannerData) {
      plannerItemData.errors = [];
      const source = 'planner';
      const plannerType = plannerItemData.daily ? 'daily' : 'general';
      const plannerGroup = uuid();

      if (!plannerItemData.title) {
        errorsFound = true;
        plannerItemData.errors.push('Please enter a title.');
      }

      if (plannerItemData.time > plannerItemData.endTime) {
        errorsFound = true;
        plannerItemData.errors.push('Start time must be before end time.');
      }

      const itemFormData = [];
      if (plannerItemData.days) {
        for (let dayIndex in plannerItemData.days) {
          if (
            plannerItemData.days[dayIndex] &&
            plannerItemData.days[dayIndex].active
          ) {
            const day = plannerItemData.days[dayIndex];
            const assigned_to_id =
              !plannerItemData.daily && plannerItemData.assigned_to_id > 0
                ? plannerItemData.assigned_to_id
                : day.assigned_to_id > 0
                ? day.assigned_to_id
                : undefined;
            itemFormData.push({
              data: {
                source,
                plannerType,
                plannerGroup,
                templateId: plannerItemData.templateId,
                plannerSession,
              },
              title: plannerItemData.title,
              description: !plannerItemData.daily
                ? plannerItemData.description
                : day.description,
              assigned_to_ids: assigned_to_id && [assigned_to_id],
              date: moment(weekStartDate)
                .add(dayIndex, 'days')
                .format(DATE_FORMAT),
              time: plannerItemData.time,
              end_time: plannerItemData.endTime,
              send_invite: true,
            });
          }
        }
      }

      if (itemFormData.length) {
        formData.push(...itemFormData);
      } else {
        errorsFound = true;
        plannerItemData.errors.push('Please select at least one day.');
      }
    }

    if (errorsFound) {
      this.setState({
        plannerData,
        errors: 'Errors found, scroll up to fix them.',
      });
      return null;
    } else {
      this.setState({ errors: undefined });
      return formData;
    }
  }

  async saveChanges() {
    const {
      onNewPlannerItems,
      onRequestClose,
      redirectToOnClose,
      auth,
    } = this.props;
    const plannerSession = uuid();
    const formData = this.validateAndGetFormData(plannerSession);
    if (!formData) {
      return;
    }

    this.setState({ loading: true });
    saveWeekCollapsed('today', false);
    saveWeekCollapsed('morning', false);
    saveWeekCollapsed('afternoon', false);
    saveWeekCollapsed('evening', false);
    await onNewPlannerItems(plannerSession, formData);
    this.setState({
      loading: false,
      showPreview: false,
      showApplyConfirm: false,
      showCancelConfirm: false,
    });
    this.props.history.push(redirectToOnClose || '/home/week');
    track(`schedule-builder:edit:apply`, {
      u: auth.userId,
      f: auth.family_id,
    });

    onRequestClose();
  }

  closeWithoutSaving() {
    const { onRequestClose, auth } = this.props;
    track(`schedule-builder:edit:close`, {
      u: auth.userId,
      f: auth.family_id,
    });

    this.setState({ showCancelConfirm: false });
    onRequestClose();
  }

  isMobile() {
    return window.innerWidth <= 768;
  }

  convertDay(day) {
    return day === 0 ? 6 : day - 1;
  }

  assemblePreviewItems() {
    const formData = this.validateAndGetFormData();
    if (!formData) {
      return;
    }
    const { existingItemsByDay } = this.props;

    // Merge in the existing items with a flag
    const allItems = [
      ...formData,
      ...existingItemsByDay.flat().map(item => ({ ...item, existing: true })),
    ];

    let itemsByDay;
    if (!this.isMobile()) {
      itemsByDay = alignDays(allItems);
    } else {
      itemsByDay = [[], [], [], [], [], [], []];

      utils.sortItems(allItems).forEach(item => {
        const day = item.date && moment(item.date);
        if (day) {
          const index = this.convertDay(day.day());
          if (!itemsByDay[index]) {
            itemsByDay[index] = [];
          }
          itemsByDay[index].push(item);
        }
      });
    }

    return itemsByDay;
  }

  openPreview(confirmSave) {
    const formData = this.validateAndGetFormData();
    if (!formData) {
      return;
    }

    const { templateId } = this.state;
    const { itemsContainsTemplateId } = this.props;

    this.setState({
      showTemplateWarning: itemsContainsTemplateId(templateId),
      previewItems: this.assemblePreviewItems(),
      showPreview: true,
      confirmSave,
    });
  }

  closePreview() {
    this.setState({ showPreview: false });
  }

  renderTemplateStartingPoints(templates, loading) {
    if (templates && templates.length) {
      // Sort default template first, then alphabetically
      const sortedTemplates = [...templates].sort((a, b) => {
        if (a.name === defaultTemplateName) return -1;
        if (b.name === defaultTemplateName) return 1;
        return a.name.toUpperCase() < b.name.toUpperCase() ? -1 : 1;
      });

      return sortedTemplates.map(template => (
        <div className="starting-point" key={template.id}>
          <Button
            variant={`secondary ${
              template.name !== defaultTemplateName ? 'large' : ''
            }`}
            disabled={loading}
            onClick={() => this.useTemplate(template.id, template.name)}
          >
            {template.name === defaultTemplateName ? (
              <>
                <span role="img" aria-label="House with Garden emoji">
                  🏡
                </span>{' '}
                Use {template.name}
                <p>Start from your main schedule template.</p>
              </>
            ) : (
              <>Use {template.name}</>
            )}
          </Button>
          <box-icon onClick={() => this.deleteTemplate(template.id)} name="x" />
        </div>
      ));
    } else {
      return (
        <div className="starting-point">
          <Button
            variant="secondary"
            disabled={loading}
            onClick={() => this.createDefaultTemplate()}
          >
            <span role="img" aria-label="House with Garden emoji">
              🏡
            </span>{' '}
            Create {defaultTemplateName}
            <p>Map out the base blocks of your family&rsquo;s week.</p>
          </Button>
        </div>
      );
    }
  }

  //#endregion logic
  //#region render
  render() {
    const {
      users,
      weekStartDate,
      weekToday,
      weekBack,
      weekForward,
      open,
    } = this.props;
    const {
      plannerData,
      loading,
      lastWeekItems,
      templates,
      templateId,
      errors,
      showPreview,
      previewItems,
      confirmSave,
      startingPoint,
      dirty,
      showTemplateWarning,
      newTemplate,
    } = this.state;
    return (
      <React.Fragment>
        <MiloModal
          isOpen={open}
          onRequestClose={() =>
            dirty
              ? this.setState({ showCancelConfirm: true })
              : this.closeWithoutSaving()
          }
          onRequestBack={startingPoint && (() => this.resetState())}
          title={'Schedule Builder'}
          size="narrow"
          overlayClassName={'ScheduleBuilderOverlay'}
        >
          <MiloModalControls>
            <WeekSelect
              weekStartDate={weekStartDate}
              weekToday={weekToday}
              weekBack={weekBack}
              weekForward={weekForward}
              hideTodayButton={true}
              showDay={true}
              showBothMonths={true}
            />
          </MiloModalControls>
          <MiloModalContent>
            <div className="Planner2">
              {/* Step 1 */}
              {!startingPoint && (
                <div className="help-text">
                  <span role="img" aria-label="Hammer emoji">
                    🔨
                  </span>{' '}
                  Schedule builder helps you map out the major logistics in your
                  family&rsquo;s week.
                  <h2>Choose your starting point</h2>
                  {this.renderTemplateStartingPoints(
                    templates &&
                      templates.filter(template => template.id >= 100000),
                    loading,
                  )}
                  <div className="starting-point">
                    <Button
                      variant="secondary"
                      disabled={
                        loading || !lastWeekItems || !lastWeekItems.length
                      }
                      onClick={() => this.copyFromLastWeek()}
                    >
                      <span
                        role="img"
                        aria-label="Counterclockwise Arrows Button emoji"
                      >
                        🔄
                      </span>{' '}
                      Copy from last week
                      <p>Copy all the templates from last week. Easy-peasy!</p>
                    </Button>
                  </div>
                  {templates && !!templates.length && (
                    <div className="starting-point">
                      <Button
                        variant="secondary"
                        disabled={loading}
                        onClick={() => {
                          this.setState({
                            startingPoint: 'blank',
                            newTemplate: 'true',
                          });
                        }}
                      >
                        <span role="img" aria-label="Pencil emoji">
                          ✏️
                        </span>{' '}
                        Start from scratch
                        <p>Build a new schedule template.</p>
                      </Button>
                    </div>
                  )}
                </div>
              )}

              {/* Step 2 */}
              {startingPoint && (
                <>
                  {newTemplate && (
                    <div className="help-text">
                      <>
                        <span role="img" aria-label="Nerd Face emoji">
                          🤓
                        </span>{' '}
                        Pro-tip: Start with the biggies that make the week work:
                        pickups/dropoffs, weekly chores, kids’ activities. We
                        can get fancy later.
                      </>
                    </div>
                  )}
                  {plannerData.length > 1 && (
                    <div className="help-text">
                      Remove a block for this week with the &ldquo;x&rdquo;
                      button.
                    </div>
                  )}
                  {plannerData.map((plannerItemData, i) => {
                    return (
                      <React.Fragment key={i}>
                        <Planner2Item
                          key={i}
                          plannerItemData={plannerItemData}
                          onPlannerItemDataChange={newData =>
                            this.onPlannerItemDataChange(i, newData)
                          }
                          onPlannerItemDelete={() =>
                            this.onPlannerItemDelete(i)
                          }
                          users={users}
                          weekStartDate={weekStartDate}
                          showDeleteButton={plannerData.length > 1}
                        />
                      </React.Fragment>
                    );
                  })}
                  <Button
                    variant="secondary large"
                    disabled={loading}
                    onClick={() => this.addPlannerItem()}
                  >
                    <box-icon name="plus" />
                    Add New Item
                  </Button>
                </>
              )}
            </div>
            {errors && <div style={{ color: 'red' }}>{errors}</div>}
          </MiloModalContent>

          {startingPoint && (
            <MiloModalButtons>
              <Button disabled={loading} onClick={() => this.openPreview(true)}>
                Next
              </Button>
            </MiloModalButtons>
          )}
        </MiloModal>
        {loading && (
          <div
            style={{
              position: 'absolute',
              top: 0,
              left: 0,
              width: '100vw',
              height: '100vh',
              backgroundColor: 'rgba(0, 0, 0, 0.5)',
              zIndex: 100,
              textAlign: 'center',
            }}
          />
        )}
        <MiloModal
          isOpen={showPreview}
          onRequestClose={() => {
            if (dirty) {
              this.setState({ showCancelConfirm: true });
            } else {
              this.closeWithoutSaving();
              this.closePreview();
            }
          }}
          title="Preview"
          size="wide"
          overlayClassName={'ScheduleBuilderOverlay'}
        >
          <MiloModalControls>
            <WeekSelect
              weekStartDate={weekStartDate}
              weekToday={weekToday}
              weekBack={weekBack}
              weekForward={weekForward}
              hideTodayButton={true}
              showDay={true}
              showBothMonths={true}
            />
          </MiloModalControls>
          <MiloModalContent>
            <Preview
              itemsByDay={previewItems}
              weekStartDate={weekStartDate}
              confirmSave={confirmSave}
              getDateRangeString={date => getDateRangeString(date)}
              dirty={dirty}
              showTemplateWarning={showTemplateWarning}
            />
          </MiloModalContent>
          <MiloModalButtons>
            <Button variant="secondary" onClick={() => this.closePreview()}>
              Make Changes
            </Button>
            {dirty && startingPoint !== 'copy' && (
              <Button
                variant="secondary"
                onClick={() => {
                  this.saveTemplate(!templateId);
                  this.closePreview();
                  this.closeWithoutSaving();
                }}
              >
                Save Template
              </Button>
            )}
            <Button
              onClick={() => {
                if (dirty && startingPoint !== 'copy') {
                  if (newTemplate) {
                    this.saveTemplate(!templateId);
                    this.saveChanges();
                  } else {
                    this.setState({ showApplyConfirm: true });
                  }
                } else {
                  this.saveChanges();
                }
              }}
            >
              Apply
            </Button>
          </MiloModalButtons>
        </MiloModal>
        <ConfirmModal
          isOpen={this.state.showApplyConfirm}
          setOpen={showApplyConfirm => this.setState({ showApplyConfirm })}
          title={
            templateId ? "You've changed a template" : 'Create a new template?'
          }
          helptext={`Do you want to save these ${
            templateId ? 'changes to your' : 'events to a new'
          } template, or just apply them to your calendar?`}
        >
          <Button
            variant="secondary"
            onClick={() => {
              this.setState({ showApplyConfirm: false });
            }}
          >
            Cancel
          </Button>
          <div>
            <Button variant="secondary" onClick={() => this.saveChanges()}>
              Apply
            </Button>
            <Button
              onClick={() => {
                this.saveTemplate(!templateId);
                this.saveChanges();
              }}
            >
              Apply & Save
            </Button>
          </div>
        </ConfirmModal>
        <ConfirmModal
          isOpen={this.state.showCancelConfirm}
          setOpen={showCancelConfirm => this.setState({ showCancelConfirm })}
          title="Discard changes?"
          helptext="Your changes will be discarded if you leave now."
        >
          <Button
            variant="secondary"
            onClick={() => this.setState({ showCancelConfirm: false })}
          >
            Don&rsquo;t Leave
          </Button>
          <Button
            variant="red"
            onClick={() => {
              this.closeWithoutSaving();
              this.closePreview();
            }}
          >
            Discard
          </Button>
        </ConfirmModal>
      </React.Fragment>
    );
  }
  //#endregion render
}

export default connect(
  state => ({
    auth: state.auth,
  }),
  {
    storeLogin,
  },
)(withRouter(Planner2));
