import React, { useRef, useState } from 'react';
import { Dropdown, Option } from '../Dropdown';
import { FIELD_ICONS, FIELD_TYPE_PRESETS } from './ItemFields';
import './ItemFieldTray.scss';

const NAME_OVERRIDES = {
  tags: 'category',
  assigned_to_ids: 'assignees',
};

export const ItemFieldTray = ({
  type,
  visibleFields,
  setVisibleFields,
  formData,
  setFormData,
  cacheData,
}) => {
  const [moreOptionsOpen, setMoreOptionsOpen] = useState(false);

  // Detect click outside of the "more" menu to close it
  const wrapperRef = useRef(null);

  const hasRelatedEvents =
    formData.linked_items && formData.linked_items.find(item => item.date);
  const fields = FIELD_TYPE_PRESETS[type].order.filter(
    field =>
      !visibleFields[field] &&
      (field !== 'time' || formData.date || hasRelatedEvents),
  );

  if (!fields.length) return null;

  function onOptionClick(name) {
    setVisibleFields({ ...visibleFields, [name]: true });
    setFormData({ ...formData, [name]: cacheData[name] });
  }

  return (
    <div className="ItemFieldTray">
      {fields.slice(0, 3).map(name => (
        <div className="option" onClick={() => onOptionClick(name)} key={name}>
          <box-icon name={FIELD_ICONS[name]} />
          {NAME_OVERRIDES[name] || name}
        </div>
      ))}

      {/* "More" menu */}
      {fields.length > 3 && (
        <div className="more-wrapper" ref={wrapperRef}>
          <div
            className="more"
            onClick={() => setMoreOptionsOpen(!moreOptionsOpen)}
          >
            more
            <box-icon name="chevron-down" />
          </div>
          <Dropdown
            isOpen={moreOptionsOpen}
            side={'right'}
            close={() => setMoreOptionsOpen(false)}
            wrapperRef={wrapperRef}
          >
            {fields.slice(3).map(name => (
              <Option
                onClick={() => onOptionClick(name)}
                key={name}
                icon={<box-icon name={FIELD_ICONS[name]} />}
              >
                {NAME_OVERRIDES[name] || name}
              </Option>
            ))}
          </Dropdown>
        </div>
      )}
    </div>
  );
};
