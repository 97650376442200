import React, { useEffect, useState, useCallback } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import moment from 'moment';
import ReactSelect, { components as selectComponents } from 'react-select';
import { closeAll, open } from '../../../redux/modal';
import { Card, CardBody, CardFooterLink } from './Card';
import { callApi } from '../../../utils/callApi';
import { DATE_FORMAT } from '../constants';
import { MiloModal, MiloModalContent } from './MiloModal';
import { ItemFieldWrapper, ItemFieldDate } from './ItemForm/ItemFields';
import { Level } from './Level';
import { Button } from './Button';

import './Anticipator.scss';

const Anticipator = () => {
  const [calendars, setCalendars] = useState([]);
  const [showMore, setShowMore] = useState(false);

  const dispatch = useDispatch();
  const openModal = useCallback(name => dispatch(open(name)), [dispatch]);

  function fetchCalendars() {
    callApi('GET', `api/milo-series/${moment().format(DATE_FORMAT)}`).then(
      setCalendars,
    );
  }

  useEffect(() => {
    fetchCalendars();
  }, []);

  const allEvents = calendars
    .reduce((acc, calendar, idx) => {
      calendar.events.forEach(({ title, date }) => {
        acc.push({
          title,
          date: moment(date),
          calendar: calendar.name,
          color: idx,
        });
      });
      return acc;
    }, [])
    .sort((a, b) => a.date - b.date);

  const events = allEvents.slice(0, showMore ? undefined : 5);

  if (!calendars.length) return null;

  const eventsByWeek = splitEventsByWeek(events);

  return (
    <Card className="Anticipator">
      <CardBody>
        <h2>Milo Anticipator</h2>
      </CardBody>
      <CardBody border="both">
        {eventsByWeek.map(
          week =>
            !!week.events.length && (
              <React.Fragment key={week.name}>
                <h4>{week.name}</h4>
                <Events events={week.events} />
              </React.Fragment>
            ),
        )}
        {allEvents.length > 5 && (
          <Button variant="clear small" onClick={() => setShowMore(!showMore)}>
            Show {showMore ? 'Less' : 'More'}{' '}
            {showMore ? (
              <box-icon name="chevron-up" />
            ) : (
              <box-icon name="chevron-down" />
            )}
          </Button>
        )}
        {!allEvents.length && (
          <p>
            <span role="img" aria-label="Smiling Face with Sunglasses emoji">
              😎
            </span>{' '}
            Looks like nothing’s coming up in the next week! If you have an
            event to add (like a spirit day or bring x day) for everyone to see,
            add it{' '}
            <span
              role="img"
              aria-label="Backhand Index Pointing Down: Medium Skin Tone emoji"
            >
              👇🏽
            </span>
          </p>
        )}
      </CardBody>
      <CardFooterLink onClick={() => openModal('anticipator')}>
        <box-icon name="plus" />
        Add event to School Calendar
      </CardFooterLink>
      <AnticipatorModal calendars={calendars} onSuccess={fetchCalendars} />
    </Card>
  );
};

const Events = ({ events }) =>
  events.map(event => (
    <div key={event.title} className={`anticipator-event color-${event.color}`}>
      <div className="anticipator-name">
        <span className="black-override">
          <strong>{event.date.format('ddd,')}</strong>{' '}
          {event.date.format('MMM D')},
        </span>{' '}
        <strong>{event.title}</strong>
      </div>
      {event.calendar.length > 1 && (
        <div className="anticipator-calendar">
          <strong>{event.calendar}</strong>
        </div>
      )}
    </div>
  ));

const AnticipatorModal = ({ calendars, onSuccess }) => {
  const dispatch = useDispatch();

  const closeAllModals = useCallback(() => dispatch(closeAll()), [dispatch]);
  const modal = useSelector(state => state.modal);

  const [form, setForm] = useState({});
  const [errors, setErrors] = useState([]);

  // Clear form when opened/closed, and set calendar if there's only one
  useEffect(() => {
    setForm(
      calendars.length === 1
        ? {
            calendar: {
              value: calendars[0].id,
              label: calendars[0].name,
            },
          }
        : {},
    );
    setErrors([]);
  }, [modal, calendars]);

  async function add() {
    try {
      await callApi('POST', 'api/milo-series', undefined, {
        series_id: form.calendar.value,
        date: form.date,
        title: form.title,
      });
      onSuccess();
      closeAllModals();
    } catch (error) {
      setErrors([error]);
    }
  }

  return (
    <MiloModal
      className="AnticipatorModal"
      isOpen={modal === 'anticipator'}
      onRequestClose={closeAllModals}
      size="narrow"
      title="Add to School Calendar"
    >
      <MiloModalContent>
        <Card>
          <CardBody>
            <ItemFieldWrapper icon="calendar">
              <ReactSelect
                placeholder="Calendar"
                value={form.calendar}
                onChange={calendar => {
                  setForm({ ...form, calendar: calendar });
                }}
                options={calendars.map(calendar => ({
                  value: calendar.id,
                  label: calendar.name,
                }))}
                isSearchable={false}
                autosize={false}
                components={{
                  Control,
                  ValueContainer,
                  DropdownIndicator: null,
                  IndicatorSeparator: null,
                }}
                openMenuOnFocus
              />
            </ItemFieldWrapper>
            <ItemFieldWrapper icon="pencil">
              <input
                value={form.title || ''}
                onChange={evt => setForm({ ...form, title: evt.target.value })}
                type="text"
                placeholder="Title"
              />
            </ItemFieldWrapper>
            <ItemFieldDate
              value={form.date}
              onChange={date => setForm({ ...form, date })}
            />
          </CardBody>

          <CardBody border="top">
            <p>
              <span role="img" aria-label="smiling face with glasses emoji">
                🤓
              </span>{' '}
              FYI—all calendar subscribers will be able to see this entry.
            </p>
          </CardBody>

          {!!errors.length && (
            <CardBody border="top">
              {errors.map(error => (
                <div key={error} className="error">
                  {error}
                </div>
              ))}
            </CardBody>
          )}

          <CardBody border="top">
            <Level>
              <div>{/* empty div to push button to the right */}</div>
              <Button
                onClick={add}
                disabled={!(form.calendar && form.title && form.date)}
              >
                <box-icon name="plus" />
                Add
              </Button>
            </Level>
          </CardBody>
        </Card>
      </MiloModalContent>
    </MiloModal>
  );
};

const Control = ({ children, ...props }) => (
  <selectComponents.Control className="Control" {...props}>
    {children}
  </selectComponents.Control>
);

const ValueContainer = ({ children, ...props }) => {
  return (
    <selectComponents.ValueContainer className="ValueContainer" {...props}>
      {children}
    </selectComponents.ValueContainer>
  );
};

function splitEventsByWeek(events) {
  const nextWeekStart = moment().add(7, 'days').startOf('isoWeek');
  const nextWeekEnd = moment().add(7, 'days').endOf('isoWeek');

  const thisWeekEvents = [];
  const nextWeekEvents = [];
  const laterEvents = [];

  events.forEach(event => {
    if (event.date.isBefore(nextWeekStart)) {
      thisWeekEvents.push(event);
    } else if (
      event.date.isSameOrAfter(nextWeekStart) &&
      event.date.isSameOrBefore(nextWeekEnd)
    ) {
      nextWeekEvents.push(event);
    } else {
      laterEvents.push(event);
    }
  });

  return [
    { name: 'This Week', events: thisWeekEvents },
    { name: 'Next Week', events: nextWeekEvents },
    { name: 'Later', events: laterEvents },
  ];
}

export default Anticipator;
