import React, { useEffect, useState } from 'react';
import { callApi } from '../../../utils/callApi';
import { escapeURI } from '../utils';
import { Button } from './Button';
import { Dropdown } from './Dropdown.js';
import { H2, H3 } from './Type';
import './UniversalSearch.scss';

const UniversalSearch = props => {
  const {
    query,
    queryFilter,
    isOpen,
    onSelectHistorical,
    onSelectSuggested,
    onSelectCreateNew,
    hideSuggested,
    hideCreateNew,
    createNewString,
    params,
    itemHeading,
  } = props;

  const [historicalContent, setHistoricalContent] = useState(null);
  const [suggestedContent, setSuggestedContent] = useState(null);
  const [contentExpanded, setContentExpanded] = useState(false);

  useEffect(() => {
    if (query && (query === '*' || query.length > 1)) {
      const paramsString = params
        ? `?${Object.keys(params)
            .map(key => `${key}=${params[key]}&`)
            .join('')}`
        : '';
      const cleanQuery = query.replace(/\?/, ' ');
      const historySearchTimer = window.setTimeout(() => {
        callApi(
          'GET',
          `api/items/q/${escapeURI(cleanQuery)}${paramsString}`,
        ).then(items => {
          setHistoricalContent(queryFilter ? items.filter(queryFilter) : items);
        });
      }, 200);

      const suggestSearchTimer = window.setTimeout(() => {
        if (!hideSuggested) {
          callApi('GET', `api/suggestions/q/${escapeURI(query)}`).then(
            items => {
              setSuggestedContent(
                queryFilter ? items.filter(queryFilter) : items,
              );
            },
          );
        }
      }, 200);

      // Return a teardown function that will be run directly before this effect is called again
      return () => {
        window.clearTimeout(historySearchTimer);
        window.clearTimeout(suggestSearchTimer);
      };
    } else {
      setHistoricalContent(null);
      setSuggestedContent(null);
    }
  }, [query]);

  useEffect(() => {
    if (!isOpen) {
      setContentExpanded(false);
    }
  }, [isOpen]);

  return (
    <Dropdown
      {...props}
      className="UniversalSearch"
      isOpen={isOpen && (query === '*' || query.length > 1)}
      top={'unset'}
    >
      {!hideCreateNew && query !== '*' && (
        <div
          className="search-result"
          onClick={() => onSelectCreateNew(createNewString || query)}
        >
          <H3>Create new: “{createNewString || query}”</H3>
        </div>
      )}
      <H2>{itemHeading || 'Previous Items'}</H2>
      {!historicalContent && <p className="ui-message">Loading...</p>}
      {historicalContent &&
        historicalContent
          .slice(
            0,
            contentExpanded ? undefined : historicalContent.length > 3 ? 2 : 3,
          )
          .map(item => (
            <div
              className="search-result"
              key={item.id}
              onClick={() => onSelectHistorical(item)}
            >
              <H3>
                {item.emoji && item.emoji.native + ' '}
                {item.title}
              </H3>
              <p>{item.description}</p>
            </div>
          ))}
      {historicalContent && !historicalContent.length && (
        <p className="ui-message">No items found.</p>
      )}
      {historicalContent && historicalContent.length > 3 && !contentExpanded && (
        <Button variant="clear" onClick={() => setContentExpanded(true)}>
          Show {historicalContent.length - 2} more&hellip;
        </Button>
      )}
      {!hideSuggested && (
        <>
          <hr />
          <H2>Milo Suggestions</H2>
          {!suggestedContent && <p className="ui-message">Loading...</p>}
          {suggestedContent &&
            suggestedContent.map(item => (
              <div
                className="search-result"
                key={item.id}
                onClick={() => onSelectSuggested(item)}
              >
                <H3>
                  {item.emoji}&nbsp;{item.title}
                </H3>
                <p>{item.description}</p>
              </div>
            ))}
          {suggestedContent && !suggestedContent.length && (
            <p className="ui-message">No suggestions.</p>
          )}
        </>
      )}
    </Dropdown>
  );
};

export default UniversalSearch;
