import React from 'react';
import { Button } from './Button';

import './UndoPlannerFlash.scss';

export const UndoPlannerFlash = props => {
  const { undoPlannerItems, open } = props;

  const undo = () => {
    undoPlannerItems();
  };

  if (open) {
    return (
      <div className="UndoPlannerFlash">
        <div className="copy">Planner items added.</div>
        <Button onClick={() => undo()}>Undo</Button>
      </div>
    );
  }
  return null;
};
