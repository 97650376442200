import React from 'react';
import { connect } from 'react-redux';
import { reset, storeLogin } from '../../redux/auth';
import * as channelsAction from '../../redux/channels';
import { callApi } from '../../utils/callApi';

class Login extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      loading: false,
    };
  }

  componentDidMount() {
    this.props.handleLogout();
    this.callAuth();
  }

  async callAuth() {
    const {
      match: {
        params: { magicToken },
      },
    } = this.props;
    const body = { token: magicToken };
    this.setState({ loading: true });
    const auth = await callApi('POST', 'login', undefined, body);
    if (auth.access_token) {
      this.props.handleStoreLogin(auth);
      const channels = await callApi('GET', 'api/channels');
      this.props.storeChannels(channels);
    } else {
      this.setState({ error: auth.error });
    }
    this.setState({ loading: false });
    auth.name === 'bot' && this.props.history.push('/');
  }

  render() {
    const { loggedIn } = this.props;
    const { loading, error } = this.state;
    return (
      <div>
        {loading && <h1>Loading...</h1>}
        {!loading && !loggedIn && <div>{error || 'Failed to login'}</div>}
      </div>
    );
  }
}

export default connect(
  state => ({
    loggedIn: state.auth && state.auth.loggedIn,
  }),
  {
    handleStoreLogin: storeLogin,
    handleLogout: reset,
    storeChannels: channelsAction.set,
  },
)(Login);
