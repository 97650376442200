import moment from 'moment';
import * as utils from '../utils';

/**
 * Take an array of items and split out in to an array of seven separate
 * arrays. An array for each day of the week.
 *
 * The processing of each day looks at the items on the given day and
 * compares to the other days. If there is a 9am item on Monday and a 10am
 * item on Tuesday then a blank item will be inserted in place of the 9am
 * on Tuesday. If there are two 9am items on Monday then two blanks will
 * be inserted for Tuesday and so on.
 *
 * Monday    Tuesday   Wednesday
 * ------------------------------
 * No time   blank     blank
 * blank     0900      blank
 * 1000      blank     blank
 * 1000      blank     blank
 * 1100      1100      blank
 * blank     blank     blank
 * blank     1400      blank
 *
 * Lines where all days have blanks will then be removed and days that do
 * not have items to the end of the latest item in the week will be padded
 * with blank entries.
 *
 * Note, there is a difference between having no time and being a blank. Items
 * with no time will be sorted to the start of the day such that timed events
 * still align on times.
 *
 * @param items []
 */

export const BLANK_TIME = '----';

export function alignDays(items) {
  // Once we have determined which day of the week
  // a date is, cache it so we don't have to call
  // the very expensive moment.day function.
  const dayIndexCache = {};

  // For each day, go and pull out the items for that day
  // padding with blanks to match other days
  const days = [[], [], [], [], [], [], []];
  for (let i = 0; i < 7; i++) {
    days[i] = process(items, i, dayIndexCache);
  }

  // Add padding at the end of the days
  let maxBlocks = 0;
  for (let i = 0; i < 7; i++) {
    if (days[i].length > maxBlocks) {
      maxBlocks = days[i].length;
    }
  }

  for (let i = 0; i < 7; i++) {
    days[i] = [
      ...days[i],
      ...new Array(maxBlocks - days[i].length)
        .fill(undefined)
        .map(() => ({ time: BLANK_TIME })),
    ];
  }

  // Remove any padding that aligns for all days
  const cleanedDays = [[], [], [], [], [], [], []];
  let nullCount = 0;
  for (let index = 0; nullCount < 7; index++) {
    nullCount = days.filter(day => !day[index]).length;
    const blankCount = days.filter(
      day => !day[index] || day[index].time === BLANK_TIME,
    ).length;
    if (blankCount !== 7) {
      for (let i = 0; i < 7; i++) {
        cleanedDays[i].push(days[i][index]);
      }
    }
  }
  return cleanedDays;
}

function dayIndex(item, dayIndexCache) {
  if (dayIndexCache[item.date]) {
    return dayIndexCache[item.date];
  }
  const dayMoment = item.date && moment(item.date);
  const result = dayMoment
    ? dayMoment.day() === 0
      ? 6
      : dayMoment.day() - 1
    : undefined;
  dayIndexCache[item.date] = result;
  return result;
}

function process(items, day, dayIndexCache) {
  const unsortedDaysItems = items.filter(
    item => dayIndex(item, dayIndexCache) === day,
  );
  const daysItems = utils.sortItems(unsortedDaysItems);
  const daysCards = [];
  daysItems.forEach((item, index) => {
    // See if there is a time on another day that is earlier than this
    const previousTimeToday = daysItems[index - 1] && daysItems[index - 1].time;
    const others = items.filter(other => {
      return (
        dayIndex(other, dayIndexCache) !== dayIndex(item, dayIndexCache) &&
        (other.time || '') < item.time &&
        (!previousTimeToday || previousTimeToday <= (other.time || ''))
      );
    });

    others.forEach(() => daysCards.push({ time: BLANK_TIME }));
    daysCards.push(item);
  });
  return daysCards;
}
