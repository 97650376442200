export function validateEmail(email) {
  const re = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
  return re.test(String(email).toLowerCase());
}

export function formatPhone(phone) {
  const numbers = phone && phone.replace(/[^0-9.]/g, '');
  return numbers && `+${numbers.length === 10 ? '1' : ''}${numbers}`;
}

export function validatePhone(phone) {
  const length = phone && formatPhone(phone).length;
  return phone[0] === '+' && length >= 12;
}

// Only allow US Zip codes
export function validateZip(zip) {
  const re = /^[0-9]{5}(?:-[0-9]{4})?$/;
  return re.test(zip);
}
