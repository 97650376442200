import React from 'react';
import classNames from 'classnames';

import './Panel.scss';

const Panel = props => {
  return (
    <div className={classNames('Panel', props.variant)}>{props.children}</div>
  );
};

export default Panel;
