import idx from 'idx';
import moment from 'moment';
import React, { useEffect, useState } from 'react';
import { connect } from 'react-redux';
import { open as openModal } from '../../../../redux/modal';
import { Button } from '../../components/Button';
import {
  Card,
  CardBody,
  CardFooterLink,
  CardItems,
} from '../../components/Card';
import { MiniItem, PlaceholderItem } from '../../components/MiniItem';
import { Level } from '../../components/Level';
import { WIDGET_NAME } from './';

const TopThreeView = props => {
  const { todoItems, progress, cardMarkComplete, openModal } = props;

  const [topThreeItems, setTopThreeItems] = useState([]);

  useEffect(() => {
    const taggedTodoItems = todoItems.filter(
      item => item.tags && item.tags.includes(WIDGET_NAME),
    );

    setTopThreeItems(
      [1, 2, 3].map(pri =>
        taggedTodoItems.find(
          item => item.data && item.data.top_three_priority === pri,
        ),
      ),
    );
  }, [todoItems, setTopThreeItems]);

  const isSunday = moment().day() === 0;

  const thisWeekStreak = idx(
    progress,
    _ => _.achievements.top_three_streak.this_week,
  );

  const lastWeekStreak = idx(
    progress,
    _ => _.achievements.top_three_streak.last_week,
  );

  return (
    <Card>
      <CardBody>
        <Level variant="start">
          <Level variant="center">
            <box-icon name="star" />
            <h2 style={{ marginLeft: 4, marginRight: 8 }}>Top 3 priorities</h2>
            {((lastWeekStreak && lastWeekStreak > 1) ||
              (thisWeekStreak && thisWeekStreak > 1)) && (
              <h4 className="hide-mobile">
                {thisWeekStreak && (
                  <span role="img" aria-label="fire emoji">
                    🔥{' '}
                  </span>
                )}
                {thisWeekStreak || lastWeekStreak} week streak
              </h4>
            )}
          </Level>

          <Button variant="clear" onClick={() => openModal(WIDGET_NAME)}>
            <box-icon name="pencil" />
          </Button>
        </Level>
        {(thisWeekStreak || lastWeekStreak) && (
          <h4 style={{ marginTop: 8 }} className="show-mobile">
            {thisWeekStreak && (
              <span role="img" aria-label="fire emoji">
                🔥{' '}
              </span>
            )}
            {thisWeekStreak || lastWeekStreak} week streak
          </h4>
        )}
        <p style={{ marginTop: 8 }}>
          Focus is the friend of the time-pressed. Add the three things to get
          done this week, with a who and a when.
        </p>

        {isSunday && (
          <Button onClick={() => openModal(WIDGET_NAME)}>
            It’s a new week! Pick your 3 tasks{' '}
            <box-icon name="right-arrow-alt" />
          </Button>
        )}
      </CardBody>

      <CardItems>
        {topThreeItems.map((item, idx) =>
          item ? (
            <MiniItem
              key={item.id}
              item={item}
              cardMarkComplete={cardMarkComplete}
              showDate={true}
            />
          ) : (
            <PlaceholderItem key={idx} onClick={() => openModal(WIDGET_NAME)}>
              <box-icon name="plus" />
              <div style={{ marginLeft: 16 }}>Add item&hellip;</div>
            </PlaceholderItem>
          ),
        )}
      </CardItems>

      <CardFooterLink onClick={() => openModal(WIDGET_NAME)}>
        Set your Top 3
        <box-icon name="right-arrow-alt" />
      </CardFooterLink>
    </Card>
  );
};

export default connect(state => ({ progress: state.progress }), { openModal })(
  TopThreeView,
);
