import React from 'react';
import './MiloCalendarCard.scss';

const MiloCalendarCard = props => {
  const { record } = props;

  return (
    <div className="MiloCalendarCard" key={record.title}>
      <a href={record.url} target="_blank" rel="noopener noreferrer">
        {record.title}
      </a>
    </div>
  );
};

export default MiloCalendarCard;
