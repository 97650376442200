import React, { useState, useRef } from 'react';
import { track } from '../../../../utils/analytics';
import { Dropdown, Option } from '../Dropdown';
import ItemMenuLists from './ItemMenuLists';

import './ItemMenu.scss';

const ItemMenu = ({ onChange, formData }) => {
  const ref = useRef();
  const [dropdownOpen, setDropdownOpen] = useState(false);
  const [showLists, setShowLists] = useState(false);

  // Don't allow parenting this item if it's not a to do or it already has children
  if (
    !formData.status ||
    (formData.linked_items && formData.linked_items.length)
  )
    return null;

  return (
    <div className="ItemMenu" ref={ref}>
      <box-icon
        name="dots-horizontal-rounded"
        onClick={() => setDropdownOpen(true)}
      />
      <Dropdown
        isOpen={dropdownOpen}
        close={() => setDropdownOpen(false)}
        wrapperRef={ref}
        side={'right'}
      >
        {!showLists && (
          <Option
            onClick={() => {
              setShowLists(true);
              track('item-form:add-to-list:click');
            }}
          >
            Add to list…
          </Option>
        )}
        {showLists && (
          <>
            <Option onClick={() => setShowLists(false)}>
              <box-icon name="chevron-left" /> Back
            </Option>
            <ItemMenuLists
              item={formData}
              onSelect={list => {
                setDropdownOpen(false);
                setShowLists(false);
                onChange(list);
              }}
            />
          </>
        )}
      </Dropdown>
    </div>
  );
};

export default ItemMenu;
