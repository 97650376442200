import React, { useEffect, useState } from 'react';
import { ItemFieldWrapper } from '../../Home/components/ItemForm/ItemFields';
import SendButton from '../SendButton';

const QUESTION_TEXT = 'Let’s make your space unique to your family.';

const FamilyQuestion = ({
  onAnswer,
  data,
  isCurrent,
  showComment,
  answers,
}) => {
  const [revisiting, setRevisiting] = useState(false);

  // Use saved family name, otherwise
  // Use family name from waitlist, otherwise
  // Create family name from user's last name if it exists, otherwise
  // Use empty string
  const familyAnswer = answers && answers.find(answer => answer.key === 'user');
  const [familyName, setFamilyName] = useState(
    (data.family && data.family.name) ||
      data.waitlist.family_name ||
      (data.family.users[0] &&
        data.family.users[0].last_name &&
        `${data.family.users[0].last_name} Family`) ||
      (familyAnswer &&
        familyAnswer.value.last_name &&
        `${familyAnswer.value.last_name} Family`) ||
      '',
  );

  useEffect(() => {
    if (data.family.name && isCurrent) {
      onAnswer();
    }
  }, [data.family.name, isCurrent, onAnswer]);

  return (
    <div className="EmailPhoneQuestion">
      {!(isCurrent || revisiting) && (
        <>
          <div className="answered-question">{QUESTION_TEXT}</div>
          <div className="response-wrapper">
            <div className="response" onClick={() => setRevisiting(true)}>
              {data.family.name}
              <box-icon name="check" />
            </div>
          </div>
          {showComment && <div className="milo-comment"> You got it!</div>}
        </>
      )}

      {(isCurrent || revisiting) && (
        <>
          <div className="title">{QUESTION_TEXT}</div>
          <p>
            What do you call your family? Make it fun like “The 4 Thompsons” or
            simple like “The Thompson Family”.
          </p>
          <ItemFieldWrapper>
            <input
              value={familyName}
              onChange={evt => setFamilyName(evt.target.value)}
              placeholder="Family name"
            />
          </ItemFieldWrapper>

          <SendButton
            disabled={!familyName}
            onClick={() => {
              setRevisiting(false);
              onAnswer({ key: 'family-name', value: familyName });
            }}
          />
        </>
      )}
    </div>
  );
};

export default FamilyQuestion;
