import React, { useMemo, useState } from 'react';
import uuid from 'uuid/v4';
import './Checkbox.scss';

export const Checkbox = ({ id, onChange, checked, animate }) => {
  const rand = useMemo(() => uuid(), []);
  const [animating, setAnimating] = useState(false);

  function starPower() {
    const stars = [];

    for (let i = 0; i < 10; i++) {
      const style = {
        transform: `translate3d(${Math.random() * 200 - 100}px, ${
          Math.random() * 200 - 100
        }px, 0) rotate(${Math.random() * 360}deg)`,
      };
      stars.push(<box-icon style={style} key={i} name="star" type="solid" />);
    }
    return stars;
  }

  return (
    <div className="Checkbox">
      <input
        type="checkbox"
        id={`checkbox_${rand}_${id}`}
        onChange={evt => {
          if (!checked && animate) {
            setAnimating(true);
            window.setTimeout(() => {
              setAnimating(false);
            }, 1000);
          }
          onChange(evt);
        }}
        onClick={evt => evt.stopPropagation()}
        name="status"
        checked={checked}
      />
      <label
        onClick={evt => evt.stopPropagation()}
        htmlFor={`checkbox_${rand}_${id}`}
      />
      {animating && <div className="stars">{starPower()}</div>}
    </div>
  );
};
