import React, { useState, useRef } from 'react';
import { Dropdown, MultiLineOption } from '../../components/Dropdown';
import { Button } from '../../components/Button';

import './MiloPicksButton.scss';

const MiloPicksButton = ({ options }) => {
  const [dropdownOpen, setDropdownOpen] = useState(false);
  const ref = useRef(null);

  return (
    <div className="MiloPicksButton" ref={ref}>
      <Button onClick={() => setDropdownOpen(!dropdownOpen)}>
        Get Inspiration&nbsp;&nbsp;
        <box-icon type="solid" name="magic-wand" />
      </Button>
      <Dropdown
        isOpen={dropdownOpen}
        top={42}
        fullWidth={true}
        close={() => setDropdownOpen(false)}
        wrapperRef={ref}
      >
        {options.map(option => (
          <MultiLineOption
            key={option.title}
            disabled={!option.onClick}
            onClick={() => {
              if (option.onClick) {
                option.onClick();
                setDropdownOpen(false);
              }
            }}
          >
            <h3>{option.title}</h3>
            <p>{option.description}</p>
          </MultiLineOption>
        ))}
      </Dropdown>
    </div>
  );
};

export default MiloPicksButton;
