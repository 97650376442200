import React from 'react';
import { connect } from 'react-redux';
import { Link } from 'react-router-dom';
import * as authAction from '../../redux/auth';
import {
  closeAll as closeAllModals,
  open as setOpenModal,
} from '../../redux/modal';
import { getUserColor } from '../../utils/user-color';
import './Account.scss';
import ChangePassword from './ChangePassword';
import FamilyModal from './FamilyModal';
import ReferralsModal from './ReferralsModal';
import FamilySettingsModal from './FamilySettingsModal';

class Account extends React.Component {
  state = { showPasswordForm: false };
  constructor(props) {
    super(props);
    this.handleClick = this.handleClick.bind(this);
  }

  componentDidMount() {
    document.addEventListener('mousedown', this.handleClick);
  }

  componentWillUnmount() {
    document.removeEventListener('mousedown', this.handleClick);
  }

  handleClick(e) {
    if (this.node && this.node.contains(e.target)) {
      return;
    }
    this.props.setShowMenu(false);
  }

  render() {
    const {
      auth,
      auth: { password_required: authPasswordRequired },
      product,
      openModal,
      closeAllModals,
      setOpenModal,
      showMenu,
    } = this.props;
    const { showPasswordForm } = this.state;
    const showSetPasswordForm = ['password', 'signup'].includes(product);
    const passwordRequired =
      (authPasswordRequired || showSetPasswordForm) && auth.name !== 'bot';
    const name = auth && auth.name === 'bot' ? 'Milo' : auth.name;
    const header = (
      <div
        className="disk"
        style={{
          backgroundColor: getUserColor(auth.users, auth.id),
        }}
      >
        {name && name.substring(0, 2)}
      </div>
    );

    return (
      <div className="Account">
        <div
          className="heading"
          onClick={() => this.props.setShowMenu(!showMenu)}
        >
          {header}
        </div>
        {!showPasswordForm && (
          <div
            className="modal"
            style={{
              display: showMenu ? undefined : 'none',
            }}
          >
            <div className="modal-menu" ref={node => (this.node = node)}>
              <div className="header">
                {header}
                <div className="x">
                  <box-icon
                    name="x"
                    onClick={() => this.props.setShowMenu(false)}
                  />
                </div>
              </div>

              <button
                className="button__password"
                onClick={() => {
                  this.setState({ showPasswordForm: true });
                  this.props.setShowMenu(!showMenu);
                }}
              >
                <box-icon name="key" />
                <div className="link-title">Change Password</div>
              </button>

              <button
                className="button__password"
                onClick={() => {
                  setOpenModal('family');
                  this.props.setShowMenu(!showMenu);
                }}
              >
                <box-icon type="solid" name="user-detail" />
                <div className="link-title">Manage Family</div>
              </button>

              <button
                className="button__password"
                onClick={() => {
                  setOpenModal('referrals');
                  this.props.setShowMenu(!showMenu);
                }}
              >
                <box-icon name="mail-send" />
                <div className="link-title">Invite a Friend</div>
              </button>

              <Link
                to="/calendar"
                onClick={() => this.props.setShowMenu(!showMenu)}
              >
                <button className="button__calendar">
                  <box-icon name="calendar-alt" />
                  <div className="link-title">Settings</div>
                </button>
              </Link>

              <button
                className="button__logout"
                onClick={this.props.handleLogout}
              >
                <box-icon name="log-out" />
                <div className="link-title">Sign Out</div>
              </button>
            </div>
          </div>
        )}
        {(passwordRequired || showPasswordForm) && (
          <ChangePassword
            passwordRequired={passwordRequired}
            closeForm={() => this.setState({ showPasswordForm: false })}
          />
        )}
        <FamilyModal isOpen={openModal === 'family'} close={closeAllModals} />
        <ReferralsModal
          isOpen={openModal === 'referrals'}
          close={closeAllModals}
        />
        <FamilySettingsModal
          isOpen={openModal === 'family-settings'}
          close={closeAllModals}
        />
      </div>
    );
  }
}

export default connect(
  state => ({
    auth: state.auth,
    openModal: state.modal,
  }),
  {
    handleLogout: authAction.reset,
    setOpenModal,
    closeAllModals,
  },
)(Account);
