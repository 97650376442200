const userColors = [
  '#d5615a', // warm red
  '#417884', // warm blue
  '#499187', // warm turquoise
  '#e5a847', // warm yellow
  '#74466a', // warm purple
  '#ce855c', // light brown
  '#2d4e55', // dark blue
  '#ad474c', // dark red
];

export function getUserColor(users, userId) {
  for (var i = 0; i < users.length && i < userColors.length; i++) {
    if (users[i].id === userId) {
      return userColors[i];
    }
  }
  return 'grey';
}

const familyColors = [...userColors].reverse();

export function getFamilyColor(families, familyId) {
  const sorted = [...families].sort((a, b) => a.id - b.id);
  for (var i = 0; i < sorted.length && i < familyColors.length; i++) {
    if (sorted[i].id === familyId) {
      return familyColors[i];
    }
  }
  return 'grey';
}
