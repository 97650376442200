import classNames from 'classnames';
import {
  Emoji,
  getEmojiDataFromNative,
  Picker as EmojiPicker,
} from 'emoji-mart';
import 'emoji-mart/css/emoji-mart.css';
import emojiData from 'emoji-mart/data/all.json';
import moment from 'moment';
import React, { forwardRef, useEffect, useRef, useState } from 'react';
import DatePicker from 'react-datepicker';
import 'react-datepicker/dist/react-datepicker.css';
import { connect } from 'react-redux';
import ReactSelect, { components as selectComponents } from 'react-select';
import TextareaAutosize from 'react-textarea-autosize';
import getLinks from '../../../../shared_modules/links';
import {
  BOT,
  CATEGORIES,
  COMPLETABLE,
  COMPLETE,
  DATE_FORMAT,
  TYPE_EVENT,
  TYPE_FILE,
  TYPE_TODO,
  TYPE_SERIES,
} from '../../constants';
import { getEmailUrl } from '../../openAttachment';
import '../../react-datepicker.css';
import { Avatar } from '../Avatar';
import { Checkbox } from '../Checkbox';
import { Dropdown } from '../Dropdown';
import UniversalSearch from '../UniversalSearch';
import HelpButton from '../HelpButton';
import { EmailIframe } from './EmailIframe';
import {
  ItemFieldAttachments,
  ItemFieldAttachmentPills,
} from './ItemFieldAttachments';
import ItemFieldRelated from './ItemFieldRelated';
import './ItemFields.scss';

export const ItemFieldWrapper = forwardRef(
  ({ className, children, icon, onClose }, ref) => (
    <div
      className={classNames('ItemFieldWrapper', className, {
        'has-icon': icon,
      })}
      ref={ref}
    >
      {children}
      {icon && <box-icon name={icon} />}
      {onClose && <box-icon onClick={onClose} name="x" />}
    </div>
  ),
);
ItemFieldWrapper.displayName = 'ItemFieldWrapper';

export const ItemFieldEmoji = ({ value, formData, onChange }) => {
  const [pickerOpen, setPickerOpen] = useState(false);
  const ref = useRef(null);
  const hasParent =
    formData.linked_from &&
    formData.linked_from[0] &&
    formData.linked_from[0].title;
  return (
    <div className="ItemFieldEmoji" ref={ref}>
      {hasParent && <box-icon name="subdirectory-right" />}
      {value ? (
        value.colons ? (
          <Emoji
            emoji={value.colons}
            size={24}
            native={true}
            onClick={() => setPickerOpen(!pickerOpen)}
          />
        ) : (
          <div onClick={() => setPickerOpen(!pickerOpen)}>{value.native}</div>
        )
      ) : (
        <box-icon
          name={FIELD_ICONS.emoji}
          onClick={() => setPickerOpen(!pickerOpen)}
        />
      )}
      <Dropdown
        isOpen={pickerOpen}
        close={() => setPickerOpen(false)}
        wrapperRef={ref}
        unstyled={true}
        top={32}
      >
        <EmojiPicker
          title=""
          emoji=""
          skinEmoji=":+1:"
          native={true}
          onSelect={emoji => {
            onChange(emoji);
            setPickerOpen(false);
          }}
        />
      </Dropdown>
    </div>
  );
};

const ItemFieldTitle = ({
  value,
  formData,
  onChange,
  setFormData,
  showSearchDropdown,
}) => {
  const [showResults, setShowResults] = useState(false);
  const ref = useRef(null);
  const hasParent =
    formData.linked_from &&
    formData.linked_from[0] &&
    formData.linked_from[0].title;

  return (
    <ItemFieldWrapper
      className={`ItemFieldTitle ${hasParent && 'show-child-icon'}`}
      ref={ref}
    >
      <TextareaAutosize
        placeholder="Title&hellip;"
        value={value || ''}
        onChange={event => {
          onChange(event.target.value);
          if (event.target.value.length > 2) {
            setShowResults(true);
          }
        }}
        autoFocus={!value && !formData.id}
      />

      <UniversalSearch
        isOpen={showSearchDropdown && showResults}
        close={() => setShowResults(false)}
        query={value || ''}
        hideCreateNew={true}
        onSelectHistorical={({
          title,
          description,
          emoji,
          location,
          tags,
          id,
        }) => {
          setShowResults(false);
          setFormData(prevFormData => ({
            ...prevFormData,
            title,
            description,
            emoji,
            location,
            tags,
            data: { ...prevFormData.data, historicalId: id },
          }));
        }}
        hideSuggested={false}
        onSelectSuggested={({ title, description, emoji, id }) => {
          setShowResults(false);
          setFormData(prevFormData => ({
            ...prevFormData,
            title,
            description,
            emoji: emoji && getEmojiDataFromNative(emoji, 'apple', emojiData),
            data: { ...prevFormData.data, suggestionId: id },
          }));
        }}
        wrapperRef={ref}
      />
    </ItemFieldWrapper>
  );
};

const ItemFieldStatus = ({ value, onChange }) =>
  value ? (
    <ItemFieldWrapper className="ItemFieldStatus">
      <Checkbox
        checked={value === COMPLETE}
        onChange={event =>
          onChange(event.target.checked ? COMPLETE : COMPLETABLE)
        }
        id={'itemForm'}
      />
    </ItemFieldWrapper>
  ) : null;

export const ItemFieldDate = ({
  value,
  onChange,
  onClose,
  autoFocus,
  onMoreDatesClick,
  formData,
}) => (
  <ItemFieldWrapper
    className="ItemFieldDate"
    icon={FIELD_ICONS.date}
    onClose={onClose}
  >
    <DatePicker
      placeholderText="Date"
      selected={value && moment(value).valueOf()}
      onChange={date =>
        onChange(date ? moment(date).format(DATE_FORMAT) : null)
      }
      dateFormat="eee, MMM dd, yyyy"
      autoFocus={autoFocus}
    />
    {value && !formData.linked_from && !formData.triage && onMoreDatesClick && (
      <div className="add-more-dates" onClick={onMoreDatesClick}>
        <box-icon name="plus-circle" /> Add more dates
      </div>
    )}
  </ItemFieldWrapper>
);

export const ItemFieldTime = ({ value, onChange, formData }) => (
  <ItemFieldWrapper className="ItemFieldTime" icon={FIELD_ICONS.time}>
    <DatePicker
      placeholderText="Start Time"
      timeCaption="Start Time"
      selected={
        value &&
        moment(
          (formData.date || moment().format(DATE_FORMAT)) + ' ' + value,
        ).valueOf()
      }
      onChange={time => onChange(time ? moment(time).format('HH:mm') : null)}
      showTimeSelect
      showTimeSelectOnly
      timeIntervals={15}
      dateFormat="h:mm a"
      autoFocus={!value}
    />
  </ItemFieldWrapper>
);

const ItemFieldEndTime = forwardRef(
  ({ value, onChange, formData, onClose }, ref) => (
    <ItemFieldWrapper
      className="ItemFieldEndTime"
      icon={FIELD_ICONS.end_time}
      onClose={onClose}
    >
      <DatePicker
        placeholderText="End Time"
        timeCaption="End Time"
        selected={
          value &&
          moment(
            (formData.date || moment().format(DATE_FORMAT)) + ' ' + value,
          ).valueOf()
        }
        onChange={time => onChange(time ? moment(time).format('HH:mm') : null)}
        showTimeSelect
        showTimeSelectOnly
        timeIntervals={15}
        dateFormat="h:mm a"
        minTime={
          formData.time && moment(formData.date + ' ' + formData.time).valueOf()
        }
        maxTime={formData.time && moment(formData.date + ' 23:59').valueOf()}
        ref={ref}
      />
    </ItemFieldWrapper>
  ),
);
ItemFieldEndTime.displayName = 'ItemFieldEndTime';

const ItemFieldLocation = ({ value, onChange, onClose }) => (
  <ItemFieldWrapper
    className="ItemFieldLocation"
    icon={FIELD_ICONS.location}
    onClose={onClose}
  >
    <input
      placeholder="Location"
      value={value || ''}
      onChange={event => onChange(event.target.value)}
      autoFocus={!value}
    />
  </ItemFieldWrapper>
);

const Control = ({ children, ...props }) => (
  <selectComponents.Control className="Control" {...props}>
    {children}
  </selectComponents.Control>
);

const ValueContainer = ({ children, ...props }) => {
  const value = props.getValue();
  return (
    <selectComponents.ValueContainer className="ValueContainer" {...props}>
      <>
        {value && value[0] && value[0].value > 0 && (
          <Avatar id={value[0].value} />
        )}
        {children}
      </>
    </selectComponents.ValueContainer>
  );
};

const MultiValueLabel = ({ children, ...props }) => (
  <>
    <Avatar className="multi-avatar" id={props.data.value} />
    {children}
  </>
);

const ItemFieldTags = ({ value, onChange, onClose }) => (
  <ItemFieldWrapper
    className="ItemFieldTags"
    icon={FIELD_ICONS.tags}
    onClose={onClose}
  >
    <ReactSelect
      placeholder="Category"
      value={
        value && {
          value,
          label: value,
        }
      }
      onChange={event => onChange(event.value)}
      options={[...CATEGORIES].sort().map(category => ({
        value: category,
        label: category,
      }))}
      isSearchable={false}
      autosize={false}
      components={{
        Control,
        ValueContainer,
        DropdownIndicator: null,
        IndicatorSeparator: null,
      }}
      autoFocus={!value}
      openMenuOnFocus
    />
  </ItemFieldWrapper>
);

const ItemFieldAssignees = connect(
  state => ({ auth: state.auth }),
  {},
)(({ formData, value, onChange, auth, onClose }) => {
  const isTodo = !!formData.status;
  const assignee =
    isTodo && auth.users.find(user => user.id === (value && value[0]));
  const currentValue = isTodo
    ? assignee && [{ value: assignee.id, label: assignee.name }]
    : value &&
      value
        .filter(id => auth.users.find(user => user && user.id === id))
        .map(id => auth.users.find(user => user && user.id === id))
        .map(user => user && { value: user.id, label: user.name });
  return (
    <ItemFieldWrapper
      className="ItemFieldAssignees"
      icon={!assignee && FIELD_ICONS.assigned_to_id}
      onClose={onClose}
    >
      <ReactSelect
        isMulti={!isTodo}
        placeholder="Assignees"
        value={currentValue}
        onChange={event =>
          isTodo
            ? onChange([event.value])
            : onChange(event ? event.map(user => user.value) : [])
        }
        options={auth.users
          .filter(user => user.name !== BOT)
          .map(user => ({
            value: user.id,
            label: user.name,
          }))}
        isSearchable={false}
        components={
          isTodo
            ? {
                Control,
                ValueContainer,
                DropdownIndicator: null,
                IndicatorSeparator: null,
              }
            : {
                Control,
                MultiValueLabel,
                DropdownIndicator: null,
                IndicatorSeparator: null,
              }
        }
        autosize={false}
        autoFocus={!value}
        openMenuOnFocus
      />
    </ItemFieldWrapper>
  );
});

const ItemFieldDescription = ({ value, onChange, formData }) => {
  const [showMoreLinks, setShowMoreLinks] = useState(false);
  const [emailUrl, setEmailUrl] = useState();
  useEffect(() => {
    if (formData.attachments && formData.attachments.length > 0) {
      getEmailUrl(formData).then(setEmailUrl);
    }
  }, [formData]);
  const links = getLinks(value);
  let displayedlinks = links;
  if (!showMoreLinks && links.length > 4) {
    displayedlinks = displayedlinks.slice(0, 3);
  }

  return (
    <ItemFieldWrapper className="ItemFieldDescription">
      <EmailIframe emailUrl={emailUrl} />
      <TextareaAutosize
        placeholder="Description&hellip;"
        value={value || ''}
        onChange={event => onChange(event.target.value)}
      />
      {!!links.length && (
        <div className="description-links">
          {displayedlinks.map((link, idx) => (
            <a
              className="description-link"
              key={idx}
              target="_blank"
              rel="noopener noreferrer"
              href={link.includes('@') ? `mailto:${link}` : link}
            >
              <box-icon name="link" />
              <span>{link}</span>
            </a>
          ))}
          {links.length > 4 && !showMoreLinks && (
            <div
              className="description-link"
              onClick={() => setShowMoreLinks(true)}
            >
              <box-icon name="chevron-down" type="solid" />
              Show {links.length - 3} more links
            </div>
          )}
        </div>
      )}
    </ItemFieldWrapper>
  );
};

const ItemFieldPriority = ({ value, onChange }) => {
  return (
    <ItemFieldWrapper className="ItemFieldPriority">
      <Checkbox
        checked={value || ''}
        onChange={event => onChange(event.target.checked)}
        id={'itemForm'}
      />
      <div style={{ marginLeft: 8, marginRight: 8 }}>
        Mission Critical Notification
      </div>
      <HelpButton id={4} text="What’s this?" />
    </ItemFieldWrapper>
  );
};

export default {
  title: ItemFieldTitle,
  description: ItemFieldDescription,
  date: ItemFieldDate,
  time: ItemFieldTime,
  end_time: ItemFieldEndTime,
  location: ItemFieldLocation,
  tags: ItemFieldTags,
  assigned_to_ids: ItemFieldAssignees,
  status: ItemFieldStatus,
  attachments: ItemFieldAttachments,
  attachmentPills: ItemFieldAttachmentPills,
  emoji: ItemFieldEmoji,
  priority: ItemFieldPriority,
  related: ItemFieldRelated,
};

// Display order for the fields (consistent across types)
export const FIELD_DISPLAY_ORDER = [
  'status',
  'tags',
  'date',
  'time',
  'end_time',
  'assigned_to_ids',
  'location',
  'attachments',
];

// Box-icon names for each field
export const FIELD_ICONS = {
  tags: 'hash',
  date: 'calendar-event',
  time: 'time',
  end_time: 'time-five',
  assigned_to_ids: 'user-circle',
  location: 'map',
  attachments: 'paperclip',
  emoji: 'smile',
};

// Per-type presets for initial field visibility, tray order, and starting values
export const FIELD_TYPE_PRESETS = {
  [TYPE_EVENT]: {
    name: 'Event',
    visible: { date: true },
    prefill: { status: undefined, type: undefined, send_invite: true },
    order: [
      'date',
      'time',
      'assigned_to_ids',
      'location',
      'tags',
      'attachments',
    ],
  },
  [TYPE_TODO]: {
    name: 'To Do',
    visible: { status: true },
    prefill: { type: undefined, send_invite: true },
    order: [
      'assigned_to_ids',
      'date',
      'time',
      'attachments',
      'location',
      'tags',
    ],
  },
  [TYPE_FILE]: {
    name: 'File',
    visible: {},
    prefill: { status: undefined, type: TYPE_FILE, send_invite: true },
    order: [
      'tags',
      'attachments',
      'assigned_to_ids',
      'date',
      'time',
      'location',
    ],
  },
  [TYPE_SERIES]: {
    name: 'Event Series',
    visible: {},
    prefill: { status: undefined, type: undefined, send_invite: true },
    order: ['time', 'assigned_to_ids', 'location', 'tags', 'attachments'],
  },
};
