import React from 'react';
import classNames from 'classnames';
import { Avatar } from '../components/Avatar';
import './TriageItem.scss';

const ANIMATING_TEXT = {
  event: 'Event scheduled',
  todo: 'To do filed',
  file: 'File saved',
  delete: 'Item deleted',
};

export const TriageItem = props => {
  const { selected, onClick, item, animating } = props;

  if (animating) {
    return (
      <div className={`TriageItem animating animating-${animating}`}>
        <box-icon name="check" />
        {ANIMATING_TEXT[animating]}
      </div>
    );
  }

  const { title, description, created_by_id, data } = item;

  const icon = data.source === 'email' ? 'envelope' : 'message-rounded-detail';

  return (
    <div className={classNames('TriageItem', { selected })} onClick={onClick}>
      <div className="icon">
        {/* These need to be two separate codepaths for selected and not selected because
          box-icons has a weird bug about changing the type parameter on the fly */}
        {selected && <box-icon size="md" type="solid" name={icon} />}
        {!selected && <box-icon size="md" type="normal" name={icon} />}
        {created_by_id && <Avatar id={created_by_id} />}
      </div>
      <div className="info">
        <div className="title">{title}</div>
        <div className="snippet">{description}</div>
      </div>
      {selected && <box-icon name="chevron-right" />}
    </div>
  );
};
