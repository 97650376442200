import React, { useState, useEffect } from 'react';
import marked from 'marked';
import DOMPurify from 'dompurify';
import ConfirmModal from './ConfirmModal';
import { Button } from './Button';
import { callApi } from '../../../utils/callApi';

const HelpButton = props => {
  const [show, setShow] = useState(false);
  const [text, setText] = useState('');

  useEffect(() => {
    callApi('GET', `api/milo-copy/${props.id}`).then(setText);
  }, [props.id]);

  return (
    <div className="HelpButton">
      <Button variant="clear" onClick={() => setShow(true)} type="button">
        <box-icon name="help-circle" />{' '}
        <span style={{ textDecoration: 'underline' }}>{props.text}</span>
      </Button>
      <ConfirmModal
        isOpen={show}
        setOpen={setShow}
        helptext={
          <div
            dangerouslySetInnerHTML={{
              __html: DOMPurify.sanitize(marked(text)),
            }}
          />
        }
      >
        <div />
        <Button onClick={() => setShow(false)}>Got It!</Button>
      </ConfirmModal>
    </div>
  );
};

export default HelpButton;
