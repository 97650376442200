import React, { useState, useEffect } from 'react';
import { ItemFieldWrapper } from '../../Home/components/ItemForm/ItemFields';
import SendButton from '../SendButton';

const QUESTION_TEXT = 'Time to pick a password';

const PasswordQuestion = ({ onAnswer, isCurrent, data, showComment }) => {
  const [password, setPassword] = useState('');
  const [revisiting, setRevisiting] = useState(false);

  const savedData = data.family.users[0] && data.family.users[0].password_set;

  useEffect(() => {
    if (savedData && isCurrent) {
      onAnswer();
    }
  }, [savedData, isCurrent, onAnswer]);

  return (
    <div className="PasswordQuestion">
      {!(isCurrent || revisiting) && (
        <>
          <div className="answered-question">{QUESTION_TEXT}</div>
          <div className="response-wrapper">
            <div className="response" onClick={() => setRevisiting(true)}>
              ········
              <box-icon name="check" />
            </div>
          </div>
          {showComment && (
            <div className="milo-comment">
              <span role="img" aria-label="Locked with Key emoji">
                🔐
              </span>{' '}
              Locked in!
            </div>
          )}
        </>
      )}

      {(isCurrent || revisiting) && (
        <>
          <div className="title">{QUESTION_TEXT}</div>
          <p>
            <span role="img" aria-label="Nerd Face emoji">
              🤓
            </span>{' '}
            Keeping your data secure starts with a good password.
          </p>

          <ul>
            <li>8 or more characters</li>
            <li>Capital letter</li>
            <li>Number</li>
          </ul>
          <ItemFieldWrapper>
            <input
              type="password"
              value={password}
              onChange={evt => setPassword(evt.target.value)}
              placeholder="Password..."
            />
          </ItemFieldWrapper>
          <SendButton
            disabled={!password.length}
            onClick={() => {
              setRevisiting(false);
              onAnswer({ key: 'password', value: password });
            }}
          />
        </>
      )}
    </div>
  );
};

export default PasswordQuestion;
