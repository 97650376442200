import 'boxicons';
import { getEmojiDataFromNative } from 'emoji-mart';
import emojiData from 'emoji-mart/data/all.json';
import moment from 'moment';
import React, { useEffect, useState } from 'react';
import { connect } from 'react-redux';
import { openItemFormModal } from '../../../redux/itemFormModal';
import { track } from '../../../utils/analytics';
import { callApi } from '../../../utils/callApi';
import { H3 } from '../components/Type';
import { DATE_FORMAT } from '../constants';
import './MiloSuggests.scss';

const ThisWeek = props => {
  const { openItemFormModal } = props;

  const [mealSuggestion, setMealSuggestion] = useState();
  const [funSuggestion, setFunSuggestion] = useState();

  useEffect(() => {
    const mealUrl = encodeURI('api/suggestions/r/dash2 meal dinner');
    const funUrl = encodeURI('api/suggestions/r/dash2 fun');
    callApi('GET', funUrl).then(result => setFunSuggestion(result));
    callApi('GET', mealUrl).then(result => setMealSuggestion(result));
  }, []);

  const suggestions = [funSuggestion, mealSuggestion].filter(exists => exists);
  if (!suggestions || !suggestions.length) {
    return null;
  }
  return (
    <div className="MiloSuggests">
      Milo suggests…
      <div>
        {suggestions.map((suggestion, index) => (
          <div key={index} className="suggestion">
            <div
              onClick={() => {
                track('milo-suggests:dash-card:click', { id: suggestion.id });
                openItemFormModal(
                  {
                    date: moment().add(1, 'day').format(DATE_FORMAT),
                    title: suggestion.title,
                    tags: suggestion.tags,
                    time:
                      suggestion.tags && suggestion.tags.includes('meal')
                        ? '18:00'
                        : undefined,
                    description: suggestion.description,
                    emoji:
                      suggestion.emoji &&
                      getEmojiDataFromNative(
                        suggestion.emoji,
                        'apple',
                        emojiData,
                      ),
                    data: { suggestionId: suggestion.id },
                  },
                  { hideSidePanel: true },
                );
              }}
            >
              <H3>
                {suggestion.emoji}{' '}
                <span className="suggestion-text">{suggestion.title}</span>
              </H3>
            </div>
          </div>
        ))}
      </div>
    </div>
  );
};

export default connect(
  state => ({ auth: state.auth, itemFormModal: state.itemFormModal }),
  { openItemFormModal },
)(ThisWeek);
