import React from 'react';
import { getEmojiDataFromNative } from 'emoji-mart';
import emojiData from 'emoji-mart/data/all.json';
import { connect } from 'react-redux';
import { openItemFormModal } from '../../../redux/itemFormModal';
import { track } from '../../../utils/analytics';
import { H3 } from '../components/Type';
import './SuggestionLink.scss';

export const SuggestionBlank = () => <div className="SuggestionBlank" />;

const SuggestionLink = props => {
  const { suggestion, date, tags, openItemFormModal } = props;
  if (!suggestion) {
    return null;
  }
  return (
    <div
      className="SuggestionLink"
      onClick={() => {
        track('week:milo-meal-suggestion:click', { id: suggestion.id });
        openItemFormModal(
          {
            date,
            title: suggestion.title,
            tags: tags || suggestion.tags,
            time:
              suggestion.tags && suggestion.tags.includes('meal')
                ? '18:00'
                : undefined,
            description: suggestion.description,
            emoji:
              suggestion.emoji &&
              getEmojiDataFromNative(suggestion.emoji, 'apple', emojiData),
            data: { suggestionId: suggestion.id },
          },
          { hideSidePanel: true },
        );
      }}
    >
      <H3>
        {suggestion.emoji}{' '}
        <span className="suggestion-text">{suggestion.title}</span>
      </H3>
    </div>
  );
};

export default connect(
  state => ({ auth: state.auth, itemFormModal: state.itemFormModal }),
  { openItemFormModal },
)(SuggestionLink);
