import React from 'react';
import { CardBody } from './Card';
import { H3 } from './Type';
import './EmptyState.scss';

export const TodayEmptyState = () => (
  <div className="EmptyState TodayEmptyState">
    <CardBody border="both">
      <H3>
        <span role="img" aria-label="dancer">
          💃🏽
        </span>{' '}
        Nothing scheduled!
      </H3>
      <p>
        This is where everyone will see what's going on today. Start by going to
        Week and adding Meals, To dos or Events.
      </p>
      <p>
        <span role="img" aria-label="love letter emoji">
          💌
        </span>{' '}
        Everyone in your household will also get a simple rundown email so they
        don't need to login to the app for everyone to be on the same page!
      </p>
    </CardBody>
  </div>
);

export const UnassignedEmptyState = () => (
  <div className="EmptyState">
    <CardBody border="both">
      <H3>
        <span role="img" aria-label="white heavy check mark emoji">
          ✅
        </span>{' '}
        I’m empty
      </H3>
      <p>Get things out of your head and into a shared space</p>
    </CardBody>
  </div>
);

export const AssignedEmptyState = () => (
  <div className="EmptyState">
    <CardBody border="both">
      <H3>
        <span role="img" aria-label="bust in silhouette emoji">
          👤
        </span>{' '}
        Nothing assigned
      </H3>
      <p>
        Add items by assigning them to me or dragging items into this column
      </p>
    </CardBody>
  </div>
);

export const FilesEmptyState = () => (
  <div className="EmptyState">
    <CardBody border="both">
      <H3>
        <span role="img" aria-label="open file folder emoji">
          📂
        </span>{' '}
        I’m empty
      </H3>
      <p>
        Start adding all things family so everyone can access important
        information when they need it.
      </p>
    </CardBody>
  </div>
);

export const MiloCategoryEmptyState = () => (
  <div className="EmptyState">
    <CardBody border="both">
      <H3>
        <span role="img" aria-label="sparkles emoji">
          ✨
        </span>{' '}
        Ideas incoming!
      </H3>
      <p>
        When you save activities and tips that Milo sends by SMS, you’ll find
        them here!
      </p>
    </CardBody>
  </div>
);
